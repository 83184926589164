import React from 'react';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { useStyles } from './styled';

interface UploadButtonProps {
  inputId: string;
  setImage: (image: File) => void;
  setThumbnailUrl: (url: string) => void;
}

export const UploadButton: React.FC<UploadButtonProps> = ({
  inputId,
  setImage,
  setThumbnailUrl,
}) => {
  const classes = useStyles();

  const readImageUrl = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const url = e.target ? String(e.target.result) : '';

      setThumbnailUrl(url);
    };
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) return;

    const image = event.target.files[0];

    readImageUrl(image);
    setImage(image);
  };

  return (
    <>
      <input
        accept="image/jpeg, image/png"
        className={classes.uploadInput}
        id={inputId}
        name="image"
        type="file"
        onChange={handleImageChange}
      />
      <label htmlFor={inputId}>
        <Button
          variant="contained"
          component="span"
          size="small"
          startIcon={<CloudUploadIcon />}
          className={classes.uploadButton}
        >
          Dodaj zdjęcie
        </Button>
      </label>
    </>
  );
};
