import React from 'react';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import { makeStyles, Theme, createStyles, IconButton } from '@material-ui/core';

interface ToolbarProps {
  numSelected: number;
  withEdit?: boolean;
  editMultiple?: boolean;
  handleEdit?: () => void;
  handleDelete: () => void;
  title: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight: {
      color: theme.palette.light.main,
      backgroundColor: theme.palette.blue.main,
    },
    title: {
      flex: '1 1 100%',
    },
    icon: {
      color: theme.palette.light.main,
    },
  }),
);

export const CustomToolbar = (props: ToolbarProps) => {
  const classes = useStyles();
  const {
    numSelected,
    handleDelete,
    title,
    withEdit,
    editMultiple,
    handleEdit,
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, { [classes.highlight]: numSelected > 0 })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} wybrano
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}

      {(numSelected === 1 || editMultiple) && withEdit && (
        <Tooltip title="Edytuj">
          <IconButton aria-label="edit" onClick={handleEdit}>
            <EditIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      )}
      {numSelected > 0 && (
        <Tooltip title="Usuń">
          <IconButton aria-label="delete" onClick={handleDelete}>
            <DeleteIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};
