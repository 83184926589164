import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`    
    html {
        font-size: 62.5%;
    }
    body {
        margin: 0;
        font-family: 'Poppins', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;        
    }
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    a {
        text-decoration: none;
        outline: none;
        color: inherit;
    }

    /* Pagination */
    .pagination > *{
        display: inline;
        font-size: 1.6rem;
        color: #1a1a1a;        
    }

    .pagination a {
        padding: 1rem 1.5rem;
        margin: 0 0.25rem;
        color: #1a1a1a;
        cursor: pointer;
        transition: all .2s ease-in-out;
        border-radius: 0.5rem;

        &:not(.pagination__link):hover {
            background: #1a1a1a;
            color: #DABC60;            
        }

        @media only screen and (max-width: 850px) {
            padding: 0.5rem 1rem;
        }
    }    

    .pagination__link {
        margin: 0 1rem;        
    }

    .pagination__link--active a {
      color: #DABC60;
      background: #1a1a1a;      
    }
    
    .pagination__link--disabled a {
        cursor: not-allowed;
        color: #ccc;        
    }
`;
