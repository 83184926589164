import { configureStore } from '@reduxjs/toolkit';
import cartReducer from 'containers/Cart/cartSlice';
import aboutReducer from 'containers/About/aboutSlice';
import productsReducer from 'containers/Products/productsSlice';
import configuratorReducer from 'containers/Configurator/configuratorSlice';
import authReducer from 'containers/Auth/authSlice';
import checkoutReducer from 'containers/Checkout/checkoutSlice';
import paymentReducer from 'containers/Payment/paymentSlice';
import userAccountReducer from 'containers/UserAccount/userAccountSlice';
import landingReducer from 'containers/Landing/redux/slice';
import guidesReducer from 'containers/Guides/redux/slice';
import outletReducer from 'containers/Outlet/redux/slice';

import adminProductsReducer from 'admin/containers/ProductsContainer/redux/productsSlice';
import sizesReducer from 'admin/containers/SizesContainer/redux/sizesSlice';
import couponsReducer from 'admin/containers/CouponsContainer/redux/couponsSlice';
import ordersReducer from 'admin/containers/OrdersContainer/redux/ordersSlice';
import galleryReducer from 'admin/containers/GalleryContainer/redux/gallerySlice';
import voucherTypesReducer from 'admin/containers/VoucherTypesContainer/redux/slice';
import creatorReducer from 'admin/containers/CreatorContainer/redux/creatorSlice';

import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';

const reducers = combineReducers({
  cart: cartReducer,
  landing: landingReducer,
  about: aboutReducer,
  products: productsReducer,
  configurator: configuratorReducer,
  auth: authReducer,
  checkout: checkoutReducer,
  payment: paymentReducer,
  userAccount: userAccountReducer,
  adminProducts: adminProductsReducer,
  sizes: sizesReducer,
  coupons: couponsReducer,
  orders: ordersReducer,
  gallery: galleryReducer,
  voucherTypes: voucherTypesReducer,
  adminCreator: creatorReducer,
  guides: guidesReducer,
  outlet: outletReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cart', 'checkout'],
  middleware: [],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
