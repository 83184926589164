import React from 'react';
import { useAppSelector } from 'hooks/redux';
import { selectGuides, selectIsLoading } from './redux/selectors';
import { Guide as GuideModel } from 'api/models/Guide';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  GuidesContainer,
  Title,
  GuidesGrid,
  Guide,
  GuideTitle,
  GuideThumbnail,
  LoaderContainer,
} from './styled';
import ClipLoader from 'react-spinners/ClipLoader';

function Guides() {
  const guides = useAppSelector(selectGuides);
  const isLoading = useAppSelector(selectIsLoading);

  const { t } = useTranslation();

  return (
    <GuidesContainer topMargin>
      {isLoading ? (
        <LoaderContainer>
          <ClipLoader loading size={32} color="#DABC60" />
        </LoaderContainer>
      ) : (
        <>
          <Title>{t('landing.guideTitle')}</Title>

          <GuidesGrid>
            {guides.map((guide: GuideModel) => (
              <Link key={guide.id} to={`/guides/${guide.data.handle}`}>
                <Guide>
                  <GuideThumbnail
                    src={guide.data.thumbnail}
                    alt={guide.data.title}
                  />
                  <GuideTitle>{guide.data.title}</GuideTitle>
                </Guide>
              </Link>
            ))}
          </GuidesGrid>
        </>
      )}
    </GuidesContainer>
  );
}

export default Guides;
