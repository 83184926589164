import React from 'react';
import {
  createStyles,
  Theme,
  makeStyles,
  lighten,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

interface CustomButtonProps {
  handleClick?: () => void;
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  topMargin?: string;
  leftMargin?: string;
}

const useStyles = makeStyles<Theme, { topMargin: string; leftMargin: string }>(
  (theme) =>
    createStyles({
      root: {
        backgroundColor: theme.palette.blue.main,
        color: theme.palette.light.main,
        maxHeight: '4rem',
        marginTop: (props) => props.topMargin,
        marginLeft: (props) => props.leftMargin,

        '&:hover': {
          backgroundColor: lighten(theme.palette.blue.main, 0.2),
        },
      },
    }),
);

export const CustomButton: React.FC<CustomButtonProps> = ({
  handleClick,
  children,
  type,
  topMargin = '',
  leftMargin = '',
}) => {
  const classes = useStyles({ topMargin, leftMargin });

  return (
    <Button
      type={type}
      variant="contained"
      className={classes.root}
      onClick={handleClick}
    >
      {children}
    </Button>
  );
};
