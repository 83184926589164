import React from 'react';

import p24Logo from 'assets/payments/p24_logo.svg';
import paypalLogo from 'assets/payments/paypal_logo.svg';
import blikLogo from 'assets/payments/blik_logo.svg';

import { Container, PaymentLogo } from './styled';

function PaymentLogos() {
  return (
    <Container>
      <PaymentLogo src={p24Logo} alt="Przelewy24" />
      <PaymentLogo src={paypalLogo} alt="PayPal" />
      <PaymentLogo src={blikLogo} alt="Blik" />
    </Container>
  );
}

export default PaymentLogos;
