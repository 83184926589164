import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { axiosInstance } from 'axiosConfig';
import { GalleryImage } from 'api/models/GalleryImage';

interface LandingState {
  isLoading: boolean;
  error: string | null | undefined;
  galleryImages: GalleryImage[];
  totalImages: number;
  fetchImages: boolean;
}

const initialState: LandingState = {
  isLoading: true,
  error: null,
  galleryImages: [],
  totalImages: 0,
  fetchImages: true,
};

export const fetchGalleryImages = createAsyncThunk<
  {
    images: GalleryImage[];
    total: number;
  },
  { page: number }
>('landing/fetchGalleryImages', async ({ page }) => {
  const response = await axiosInstance.get<{
    images: GalleryImage[];
    total: number;
  }>(`content/gallery?page=${page}&pageSize=8`);

  return { images: response.data.images, total: response.data.total };
});

export const landingSlice = createSlice({
  name: 'landing',
  initialState,
  reducers: {
    setFetchImages: (state) => {
      state.fetchImages = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGalleryImages.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(fetchGalleryImages.fulfilled, (state, { payload }) => {
      state.galleryImages = state.galleryImages.concat(payload.images);
      state.totalImages = payload.total;
      state.isLoading = false;
      state.fetchImages = false;
    });
    builder.addCase(fetchGalleryImages.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.fetchImages = false;
    });
  },
});

export const { setFetchImages } = landingSlice.actions;

export const selectIsLoading = (state: RootState) => state.about.isLoading;
export const selectGalleryImages = (state: RootState) =>
  state.about.galleryImages;
export const selectTotalImages = (state: RootState) => state.about.totalImages;
export const selectFetchImages = (state: RootState) => state.about.fetchImages;

export default landingSlice.reducer;
