import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { Order } from 'api/models/Order';
import { axiosInstance } from 'axiosConfig';

interface userAccountState {
  isLoading: boolean;
  orders: Order[];
  error: string | null | undefined;
}

const initialState: userAccountState = {
  isLoading: false,
  orders: [],
  error: null,
};

export const fetchOrders = createAsyncThunk(
  'userAccount/fetchOrders',
  async () => {
    const response = await axiosInstance.get<Order[]>('user/orders');

    return response.data;
  },
);

const userAccountSlice = createSlice({
  name: 'userAccount',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOrders.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchOrders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.orders = action.payload;
    });
    builder.addCase(fetchOrders.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const selectIsLoading = (state: RootState) =>
  state.userAccount.isLoading;
export const selectOrders = (state: RootState) => state.userAccount.orders;
export const selectError = (state: RootState) => state.userAccount.error;

export default userAccountSlice.reducer;
