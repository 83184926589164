import React from 'react';
import { useTranslation } from 'react-i18next';

import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import AssignmentReturnOutlinedIcon from '@material-ui/icons/AssignmentReturnOutlined';

import {
  AdditionalInfoContainer,
  AdditionalInfo,
  AdditionalInfoIcon,
  AdditionalInfoText,
} from './styled';

function AdditionalInfoSection() {
  const { t } = useTranslation();

  return (
    <AdditionalInfoContainer>
      <AdditionalInfo>
        <AdditionalInfoIcon>
          <VerifiedUserOutlinedIcon />
        </AdditionalInfoIcon>

        <AdditionalInfoText>{t('product.safeShopping')}</AdditionalInfoText>
      </AdditionalInfo>

      <AdditionalInfo>
        <AdditionalInfoIcon>
          <LocalOfferOutlinedIcon />
        </AdditionalInfoIcon>

        <AdditionalInfoText>
          {t('product.manufacturedInPoland')}
        </AdditionalInfoText>
      </AdditionalInfo>

      <AdditionalInfo>
        <AdditionalInfoIcon>
          <LocalShippingOutlinedIcon />
        </AdditionalInfoIcon>

        <AdditionalInfoText>{t('product.freeShipping')}</AdditionalInfoText>
      </AdditionalInfo>

      <AdditionalInfo>
        <AdditionalInfoIcon>
          <AssignmentReturnOutlinedIcon />
        </AdditionalInfoIcon>

        <AdditionalInfoText>{t('product.returns')}</AdditionalInfoText>
      </AdditionalInfo>
    </AdditionalInfoContainer>
  );
}

export default AdditionalInfoSection;
