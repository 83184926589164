import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from 'axiosConfig';
import { Product } from 'api/models/Product';

export const fetchBestsellers = createAsyncThunk<
  Product[],
  void,
  { rejectValue: string }
>('landing/fetchBestsellers', async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get<Product[]>('products/bestsellers');

    if (response.status !== 200) throw new Error('Unexpected error');

    return response.data;
  } catch (error: any) {
    if (!error.message) throw error;

    return rejectWithValue(error.message);
  }
});

export const fetchOutletProducts = createAsyncThunk<
  Product[],
  void,
  { rejectValue: string }
>('landing/fetchOutletProducts', async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get<Product[]>(
      'products/outlet/recent',
    );

    if (response.status !== 200) throw new Error('Unexpected error');

    return response.data;
  } catch (error: any) {
    if (!error.message) throw error;

    return rejectWithValue(error.message);
  }
});
