import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedOrder } from 'api/models/Order';
import {
  fetchOrders,
  deleteOrders,
  updateOrders,
  fetchOrderById,
} from './thunk';
import { RootState } from 'store';

interface OrdersState {
  isLoading: boolean;
  error: string | undefined;
  orders: FetchedOrder[];
  currentOrder: FetchedOrder | undefined;
  selectedOrders: number[];
  snackbarOpen: boolean;
  snackbarText: string;
}

const initialState: OrdersState = {
  isLoading: false,
  error: undefined,
  orders: [],
  currentOrder: undefined,
  selectedOrders: [],
  snackbarOpen: false,
  snackbarText: '',
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    closeSnackbar: (state) => {
      state.snackbarOpen = false;
    },
    setSelected: (state, action: PayloadAction<number[]>) => {
      state.selectedOrders = action.payload;
    },
    toggleSelected: (state, action: PayloadAction<number>) => {
      if (state.selectedOrders.includes(action.payload)) {
        state.selectedOrders = state.selectedOrders.filter(
          (selected) => selected !== action.payload,
        );
      } else {
        state.selectedOrders.push(action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrders.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(fetchOrders.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.orders = payload;
    });
    builder.addCase(fetchOrders.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchOrderById.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(fetchOrderById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.currentOrder = payload;
    });
    builder.addCase(fetchOrderById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteOrders.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(deleteOrders.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.orders = state.orders.filter(
        (order) => !payload.includes(order.id),
      );
      state.selectedOrders = state.selectedOrders.filter(
        (selectedOrder) => !payload.includes(selectedOrder),
      );

      state.snackbarOpen = true;
      state.snackbarText = 'Pomyślnie usunięto zamówienia';
    });
    builder.addCase(deleteOrders.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    builder.addCase(updateOrders.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(updateOrders.fulfilled, (state, { payload }) => {
      state.isLoading = false;

      payload.updatedOrders.forEach((updatedOrder) => {
        state.orders = state.orders.map((order) =>
          order.id === updatedOrder.id ? updatedOrder : order,
        );
      });
    });
    builder.addCase(updateOrders.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });
  },
});

export const { closeSnackbar, setSelected, toggleSelected } =
  ordersSlice.actions;

export const selectOrdersState = (state: RootState) => state.orders;

export default ordersSlice.reducer;
