import styled from 'styled-components';
import { Button } from 'theme/global';
import { device } from 'theme/theme';

export const Form = styled.form`
  display: flex;
  align-items: center;

  @media ${device.mobile} {
    display: block;
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;

interface StyledInputProps {
  error?: boolean;
}

export const StyledInput = styled.input<StyledInputProps>`
  border: 1px solid
    ${(props) =>
      props.error ? props.theme.colors.danger : props.theme.colors.primary};
  color: ${(props) =>
    props.error ? props.theme.colors.danger : props.theme.colors.primary};
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  height: 4rem;
  outline: none;
  font-family: inherit;
  font-size: ${(props) => props.theme.fontSizes.s};
  width: 15%;
  min-width: 25rem;

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const SubmitButton = styled(Button)`
  margin: 0 1rem;

  @media ${device.mobile} {
    margin: 1rem 0;
    display: block;
  }
`;

export const HelperText = styled.p`
  font-size: 1.1rem;
  color: ${(props) => props.theme.colors.danger};
  margin-top: 0.2rem;
  left: 0.2rem;
  position: absolute;
`;
