import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

import { CarouselContainer, Indicator, CarouselImage } from './styled';

interface ImageCarouselProps {
  images: string[];
  fullWidth?: boolean;
}

const ImageCarousel = ({ images, fullWidth }: ImageCarouselProps) => {
  const renderIndicator = (
    clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
    isSelected: boolean,
  ) => <Indicator isActive={isSelected} />;

  return (
    <CarouselContainer fullWidth={fullWidth}>
      <Carousel
        autoPlay
        infiniteLoop
        showStatus={false}
        showArrows={fullWidth}
        showThumbs={false}
        stopOnHover={false}
        renderIndicator={renderIndicator}
      >
        {images.map((image, idx) => (
          <CarouselImage
            src={image}
            alt="About us"
            key={idx}
            fullWidth={fullWidth}
          />
        ))}
      </Carousel>
    </CarouselContainer>
  );
};

export default ImageCarousel;
