import styled from 'styled-components';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const Title = styled.p`
  font-size: ${(props) => props.theme.fontSizes.m};
  color: ${(props) => props.theme.colors.primary};
  margin-top: 4rem;

  &:nth-of-type(2) {
    margin-bottom: 2rem;
  }

  &:last-of-type {
    margin-bottom: 2rem;
  }
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    textField: {
      margin: '1rem 0',

      '&.hidden': {
        display: 'none',
      },
    },
    halfTextField: {
      width: '50%',

      '&:first-of-type': {
        paddingRight: '1rem',
      },
    },
    checkbox: {
      '&.Mui-checked': {
        color: theme.palette.blue.main,
      },
    },
    spinner: {
      color: theme.palette.blue.main,
      margin: '2rem 0',
    },
  }),
);
