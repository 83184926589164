import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en/translation.json';
import pl from './pl/translation.json';

const resources = {
  en: {
    translation: en,
  },
  pl: {
    translation: pl,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'pl',
  debug: false,
  interpolation: { escapeValue: false },
});

export default i18n;
