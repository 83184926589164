import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      '&.Mui-checked': {
        color: theme.palette.blue.main,
      },
    },
  }),
);

export const Thumbnail = styled.img`
  width: auto;
  height: 6.4rem;
`;

interface StyledPaperProps {
  margintop?: boolean;
}

export const StyledPaper = styled(Paper)<StyledPaperProps>`
  margin-top: ${(props) => (props.margintop ? '4rem' : '0')};
`;
