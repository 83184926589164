import styled from 'styled-components';

export const Container = styled.section`
  margin: 4rem 0;
`;

export const Title = styled.h1`
  font-size: ${(props) => props.theme.fontSizes.m};
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

export const PaymentMethodsGrid = styled.div`
  display: grid;
  column-gap: 5rem;
  row-gap: 3rem;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  grid-template-rows: repeat(auto, 5rem);
  align-items: center;
  justify-content: space-between;
`;

interface PaymentMethodProps {
  isActive: boolean;
}

export const PaymentMethod = styled.img<PaymentMethodProps>`
  max-width: 13rem;
  cursor: pointer;
  padding: 1rem 2rem;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;

  ${(props) =>
    props.isActive &&
    `  
    border: 1px solid ${props.theme.colors.primary};
    border-radius: 0.5rem;
  `}
`;
