import React from 'react';
import { FaFacebookSquare, FaInstagram, FaPinterest } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import {
  FooterContainer,
  Content,
  Divider,
  FooterText,
  FooterLink,
  SocialIcon,
  BottomText,
  MailLink,
} from './styled';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer>
      <Content>
        <address>
          <MailLink href="mailto:contact@scudetto.store">
            contact@scudetto.store
          </MailLink>
          <FooterText>+48 788 863 434</FooterText>
          <FooterText>+48 600 372 555</FooterText>
        </address>

        <div>
          <SocialIcon
            target="_blank"
            href="https://www.facebook.com/Scudetto-1127607537444039/"
          >
            <FaFacebookSquare />
          </SocialIcon>
          <SocialIcon
            target="_blank"
            href="https://www.instagram.com/scudetto.store/"
          >
            <FaInstagram />
          </SocialIcon>
          <SocialIcon
            target="_blank"
            href="https://www.pinterest.com/scudettovests"
          >
            <FaPinterest />
          </SocialIcon>
        </div>
      </Content>

      <div>
        <Divider />

        <FooterLink
          href="/files/terms-of-service.pdf"
          target="_blank"
          rel="noreferrer noopener"
        >
          {t('app.terms')}
        </FooterLink>
        <FooterLink
          href="/files/privacy-policy.pdf"
          target="_blank"
          rel="noreferrer noopener"
        >
          {t('app.privacyPolicy')}
        </FooterLink>
        <FooterLink
          href="/files/cookies-policy.pdf"
          target="_blank"
          rel="noreferrer noopener"
        >
          {t('app.cookiesPolicy')}
        </FooterLink>

        <BottomText>{`Scudetto \u00ae ${currentYear}`}</BottomText>
      </div>
    </FooterContainer>
  );
};

export default Footer;
