import styled from 'styled-components';
import { device } from 'theme/theme';
import { Text } from 'theme/typography';
import { Link, Element } from 'react-scroll';

export const VideoContainer = styled.section`
  width: 100vw;
  height: 100vh;
`;

export const Video = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const DarkOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.primary};
  opacity: 0.4;
  width: 100vw;
  height: 100vh;
`;

export const AboutUsSection = styled(Element)`
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;

  @media ${device.mobile} {
    display: block;
    margin-top: 10rem;
  }
`;

export const AboutUsContent = styled.div`
  margin-left: 5rem;

  @media ${device.mobile} {
    margin-left: 0;
  }
`;

export const AboutUs = styled(Text)`
  font-size: 1.8rem;
  line-height: 1.9;
  text-align: justify;
  color: ${(props) => props.theme.colors.text};

  @media ${device.mobile} {
    font-size: 1.8rem;
  }
`;

export const IconWrapper = styled.div`
  margin-top: 4rem;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Icon = styled(Link)`
  font-size: 5rem;
  color: ${(props) => props.theme.colors.accent};
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.2rem;
  background-color: ${(props) => props.theme.colors.accent};
`;

export const Gallery = styled(Element)`
  width: 100%;
  min-height: 100vh;
  margin-top: 10rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  justify-items: center;

  @media ${device.mobile} {
    display: block;
  }
`;

export const Image = styled.img`
  max-width: 30rem;
  height: auto;

  @media ${device.mobile} {
    max-width: 100%;
  }
`;
