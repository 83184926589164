import React from 'react';

import { CustomToolbar } from 'admin/components/CustomToolbar';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import TableBody from '@material-ui/core/TableBody';
import {
  Cut,
  Material,
  Fleece,
  Button,
  isCut,
  isMaterial,
  isFleece,
} from 'api/models/CreatorModels';
import { useStyles, Thumbnail, StyledPaper } from './styled';

interface CreatorTableProps {
  title: string;
  type: 'cut' | 'material' | 'fleece' | 'button';
  withMargin?: boolean;
  elements: (Cut | Material | Fleece | Button)[];
  selectedElemensts: number[];
  editable?: boolean;
  setSelected: (selected: number[]) => void;
  toggleSelected: (selected: number) => void;
  handleDelete: () => void;
  handleEdit?: () => void;
}

export const CreatorTable: React.FC<CreatorTableProps> = ({
  title,
  type,
  withMargin,
  elements,
  selectedElemensts,
  editable,
  setSelected,
  toggleSelected,
  handleDelete,
  handleEdit,
}) => {
  const classes = useStyles();

  const handleAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = elements.map((element) => element.id!);
      setSelected(newSelected);
      return;
    }

    setSelected([]);
  };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    id: number | undefined,
  ) => {
    if (!id) return;

    toggleSelected(id);
  };

  const isSelected = (id: number | undefined) => {
    if (!id) return false;

    return selectedElemensts.includes(id);
  };

  const getImageUrl = (element: Cut | Material | Fleece | Button): string => {
    switch (type) {
      case 'cut': {
        const cut = element as Cut;
        return cut.female === 1
          ? `/images/creator/female/${cut.name}.png`
          : `/images/creator/${cut.name}.png`;
      }
      case 'material': {
        const material = element as Material;
        return material.female === 1
          ? `/images/creator/female/${material.name}.jpg`
          : `/images/creator/${material.name}.jpg`;
      }
      case 'fleece': {
        const fleece = element as Fleece;
        return `/images/creator/${fleece.name}.jpg`;
      }
      case 'button': {
        const button = element as Button;
        const isOversize = button.oversize === 'true' ? 'ov' : '';
        return button.female === 1
          ? `/images/creator/female/${button.name}${isOversize}.jpg`
          : `/images/creator/${button.name}${isOversize}.jpg`;
      }

      default:
        return '';
    }
  };

  const additionalHeaders = (): React.ReactNode => {
    switch (type) {
      case 'cut':
        return (
          <>
            <TableCell>Z kołnierzem</TableCell>
            <TableCell>Rodzaj</TableCell>
          </>
        );

      case 'material':
        return (
          <>
            <TableCell>Rodzaj</TableCell>
          </>
        );

      case 'button':
        return <TableCell>Rodzaj</TableCell>;

      default:
        return <></>;
    }
  };

  const getCutType = (cut: Cut) => {
    if (cut.female && cut.oversize === 'true') {
      return 'Krój damski oversize';
    }

    if (cut.female && cut.oversize === 'false') {
      return 'Krój damski';
    }

    return 'Krój męski';
  };

  const getButtonType = (button: Button) => {
    if (button.female && button.oversize === 'true') {
      return 'Guziki damskie oversize';
    }

    if (button.female && button.oversize === 'false') {
      return 'Guziki damskie';
    }

    return 'Guziki męskie';
  };

  return (
    <StyledPaper margintop={withMargin}>
      <CustomToolbar
        handleDelete={handleDelete}
        numSelected={selectedElemensts.length}
        title={title}
        withEdit={editable}
        handleEdit={handleEdit}
      />
      <TableContainer>
        <Table aria-labelledby="productsTable" size="medium">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedElemensts.length > 0 &&
                    selectedElemensts.length < elements.length
                  }
                  checked={
                    elements.length > 0 &&
                    selectedElemensts.length === elements.length
                  }
                  className={classes.checkbox}
                  onChange={handleAllClick}
                />
              </TableCell>
              <TableCell>Zdjęcie</TableCell>
              <TableCell>Nazwa</TableCell>
              {additionalHeaders()}
            </TableRow>
          </TableHead>

          <TableBody>
            {elements.map((element) => (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={element.id}
                onClick={(event) => handleClick(event, element.id)}
                selected={isSelected(element.id)}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isSelected(element.id)}
                    className={classes.checkbox}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Thumbnail
                    src={getImageUrl(element)}
                    alt={`${element.name}`}
                  />
                </TableCell>
                <TableCell>{element.name}</TableCell>
                {isCut(element) && (
                  <>
                    <TableCell>
                      {element.has_collar === 'true' ? 'Tak' : 'Nie'}
                    </TableCell>
                    <TableCell>{getCutType(element)}</TableCell>
                  </>
                )}
                {isMaterial(element) && (
                  <>
                    <TableCell>
                      {element.female === 1
                        ? 'Tkanina damska'
                        : 'Tkanina męska'}
                    </TableCell>
                  </>
                )}
                {!isCut(element) &&
                  !isMaterial(element) &&
                  !isFleece(element) && (
                    <TableCell>{getButtonType(element)}</TableCell>
                  )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledPaper>
  );
};
