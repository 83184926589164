import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { Cut, Material, Fleece, Button } from 'api/models/CreatorModels';
import { Size } from 'api/models/Size';
import { axiosInstance } from 'axiosConfig';

export enum Status {
  NULL,
  PENDING,
  DONE,
}

interface ConfiguratorState {
  status: Status;
  cuts: Cut[];
  materials: Material[];
  fleeces: Fleece[];
  buttons: Button[];
  sizes: Size[];
  error: string | null | undefined;
}

const initialState: ConfiguratorState = {
  status: Status.NULL,
  cuts: [],
  materials: [],
  fleeces: [],
  buttons: [],
  sizes: [],
  error: null,
};

export const fetchContent = createAsyncThunk(
  'configurator/fetchContent',
  async (gender: string, { rejectWithValue }) => {
    try {
      const [
        cutsResults,
        materialsResults,
        fleecesResults,
        buttonsResults,
        sizesResults,
      ] = await Promise.all([
        axiosInstance.get<Cut[]>(`configurator/cuts?gender=${gender}`),
        axiosInstance.get<Material[]>(
          `configurator/materials?gender=${gender}`,
        ),
        axiosInstance.get<Fleece[]>(`configurator/fleeces`),
        axiosInstance.get<Button[]>(`configurator/buttons?gender=${gender}`),
        axiosInstance.get<Size[]>(`configurator/sizes?gender=${gender}`),
      ]);

      const cuts = cutsResults.data;
      const materials = materialsResults.data;
      const fleeces = fleecesResults.data;
      const buttons = buttonsResults.data;
      const sizes = sizesResults.data;

      return { cuts, materials, fleeces, buttons, sizes };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const configuratorSlice = createSlice({
  name: 'configurator',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchContent.pending, (state) => {
      state.status = Status.PENDING;
      state.error = null;
    });
    builder.addCase(fetchContent.fulfilled, (state, { payload }) => {
      state.cuts = payload.cuts;
      state.materials = payload.materials;
      state.fleeces = payload.fleeces;
      state.buttons = payload.buttons;
      state.sizes = payload.sizes;
      state.status = Status.DONE;
    });
    builder.addCase(fetchContent.rejected, (state, action) => {
      state.error = action.error.message;
      state.status = Status.DONE;
    });
  },
});

export const selectError = (state: RootState) => state.configurator.error;
export const selectIsLoading = (state: RootState) =>
  state.configurator.status === Status.NULL ||
  state.configurator.status === Status.PENDING;

export const selectConfiguratorContent = (state: RootState) => {
  return {
    cuts: state.configurator.cuts,
    materials: state.configurator.materials,
    fleeces: state.configurator.fleeces,
    buttons: state.configurator.buttons,
    sizes: state.configurator.sizes,
  };
};

export default configuratorSlice.reducer;
