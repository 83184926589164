import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      '&.Mui-checked': {
        color: theme.palette.blue.main,
      },
    },
  }),
);

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface StatusIconProps {
  success?: boolean;
  danger?: boolean;
  warning?: boolean;
  info?: boolean;
}

export const StatusIcon = styled.div<StatusIconProps>`
  display: flex;
  align-items: center;
  margin-right: 1rem;

  ${(props) => {
    if (props.success) {
      return `color: ${props.theme.colors.success}`;
    }
    if (props.danger) {
      return `color: ${props.theme.colors.danger}`;
    }
    if (props.warning) {
      return `color: ${props.theme.colors.warning}`;
    }
    if (props.info) {
      return `color: ${props.theme.colors.info}`;
    }
  }};
`;
