import styled from 'styled-components';

export const Table = styled.table`
  font-size: ${(props) => props.theme.fontSizes.s};
  color: ${(props) => props.theme.colors.accent};
  border-collapse: collapse;
  width: 100%;
  white-space: nowrap;

  & td,
  th {
    padding: 1.5rem;
  }

  & thead {
    border-bottom: 2px solid ${(props) => props.theme.colors.accent};
  }
`;
