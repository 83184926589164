import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

const theme = {
  colors: {
    primary: '#1A1A1A',
    accent: '#DABC60',
    light: '#FCFCFC',
    text: '#4A4A4A',
    primary60: 'rgba(26,26,26, 0.6)',
    success: '#13923d',
    danger: '#F44336',
    warning: '#ff9800',
    info: '#2196f3',
    gray: '#4A4A4A',
    lightGray: '#dbdbdb',
    blue: '#1976D2',
  },
  navbarHeight: '10rem',
  borderRadius: '0.5rem',
  productImage: {
    width: '50rem',
    height: '50rem',
    smallScreenWidth: '35rem',
    smallScreenHeight: '35rem',
    mobileWidth: '25rem',
    mobileHeight: '25rem',
  },
  fontSizes: {
    l: '3.2rem',
    m: '1.8rem',
    s: '1.6rem',
    xs: '1.4rem',
  },
};

export const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#1A1A1A',
    },
    secondary: {
      main: '#DABC60',
    },
    success: {
      main: '#13923d',
    },
    error: {
      main: '#F44336',
    },
    blue: {
      main: '#1976D2',
    },
    light: {
      main: '#fff',
    },
    lightGray: {
      main: '#dbdbdb',
    },
  },
  typography: {
    htmlFontSize: 10,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 850,
      md: 1100,
      lg: 1580,
      xl: 1580,
    },
  },
});

export type Theme = typeof theme;

const breakpoints = {
  mobile: '850px',
  tablet: '1100px',
  smallScreen: '1580px',
};

export const device = {
  mobile: `(max-width: ${breakpoints.mobile})`,
  tablet: `(max-width: ${breakpoints.tablet})`,
  smallScreen: `(max-width: ${breakpoints.smallScreen})`,
};

export default theme;
