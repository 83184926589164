import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { useTranslation } from 'react-i18next';
import { IoPersonCircleOutline, IoClose } from 'react-icons/io5';
import { GiHamburgerMenu } from 'react-icons/gi';
import { scroller } from 'react-scroll';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { selectCart } from 'containers/Cart/cartSlice';
import { selectUser, logout } from 'containers/Auth/authSlice';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import Badge from '@material-ui/core/Badge';
import { ProductType } from 'api/models/Product';
import { selectGuides } from 'containers/Guides/redux/selectors';
import { Guide } from 'api/models/Guide';

import logoImage from 'assets/logo/navbar-logo.png';
import enIcon from 'assets/english_flag.png';
import plIcon from 'assets/polish_flag.png';
import {
  Nav,
  Content,
  Logo,
  Links,
  NavLink,
  FlagIcon,
  Dropdown,
  DropdownContent,
  DropdownLink,
  DropdownContentLink,
  HamburgerMenu,
  NavigationDrawer,
  DarkOverlay,
  NavigationDrawerLink,
  NavigationDrawerFlagIcon,
  MobileIconsContainer,
  GuidesContent,
  PageBlur,
  GuideTile,
  GuideThumbnail,
  GuideTitle,
} from './styled';

const Navbar = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const cart = useAppSelector(selectCart);
  const user = useAppSelector(selectUser);
  const guides = useAppSelector(selectGuides);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [guidesContentOpen, setGuidesContentOpen] = useState(false);

  useEffect(() => {
    if (!sidebarOpen) return;

    setSidebarOpen(false);

    // eslint-disable-next-line
  }, [location]);

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const toggleNavigationDrawer = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const scrollToGallery = () => {
    history.push('/about#gallery');
  };

  const showGuidesContent = () => {
    setGuidesContentOpen(true);
  };

  const hideGuidesContent = () => {
    setGuidesContentOpen(false);
  };

  useEffect(() => {
    if (!location.hash) return;

    if (location.hash === '#gallery') {
      scroller.scrollTo('gallery', {
        duration: 500,
        delay: 100,
        smooth: true,
      });
    }
  }, [location]);

  const profileDropdownContent = () => {
    if (!user) {
      return (
        <>
          <Link to="/login">
            <DropdownContentLink>
              {t('navbar.account.login')}
            </DropdownContentLink>
          </Link>
          <Link to="/signup">
            <DropdownContentLink>
              {t('navbar.account.signup')}
            </DropdownContentLink>
          </Link>
        </>
      );
    }

    if (user.isAdmin) {
      return (
        <>
          <Link to="/dashboard/products">
            <DropdownContentLink>
              {t('navbar.account.adminDashboard')}
            </DropdownContentLink>
          </Link>

          <DropdownContentLink onClick={() => dispatch(logout())}>
            {t('navbar.account.logout')}
          </DropdownContentLink>
        </>
      );
    }

    return (
      <>
        <Link to="/account">
          <DropdownContentLink>
            {t('navbar.account.profile')}
          </DropdownContentLink>
        </Link>

        <DropdownContentLink onClick={() => dispatch(logout())}>
          {t('navbar.account.logout')}
        </DropdownContentLink>
      </>
    );
  };

  const profileNavigationDrawerContent = () => {
    if (!user) {
      return (
        <>
          <Link to="/login">
            <NavigationDrawerLink>
              {t('navbar.account.login')}
            </NavigationDrawerLink>
          </Link>

          <Link to="/signup">
            <NavigationDrawerLink>
              {t('navbar.account.signup')}
            </NavigationDrawerLink>
          </Link>
        </>
      );
    }

    if (user.isAdmin) {
      return (
        <>
          <Link to="/dashboard/products">
            <NavigationDrawerLink>
              {t('navbar.account.adminDashboard')}
            </NavigationDrawerLink>
          </Link>

          <NavigationDrawerLink onClick={() => dispatch(logout())}>
            {t('navbar.account.logout')}
          </NavigationDrawerLink>
        </>
      );
    }

    return (
      <>
        <Link to="/account">
          <NavigationDrawerLink>
            {t('navbar.account.profile')}
          </NavigationDrawerLink>
        </Link>

        <NavigationDrawerLink onClick={() => dispatch(logout())}>
          {t('navbar.account.logout')}
        </NavigationDrawerLink>
      </>
    );
  };

  return (
    <>
      {guides && guides.length > 0 && (
        <>
          <PageBlur $hidden={!guidesContentOpen} />

          <GuidesContent
            $hidden={guidesContentOpen}
            onMouseEnter={showGuidesContent}
            onMouseLeave={hideGuidesContent}
          >
            {guides.slice(0, 4).map((guide: Guide) => (
              <Link
                key={guide.id}
                to={`/guides/${guide.data.handle}`}
                onClick={hideGuidesContent}
              >
                <GuideTile>
                  <GuideThumbnail
                    src={guide.data.thumbnail}
                    alt={guide.data.title}
                  />
                  <GuideTitle>{guide.data.title}</GuideTitle>
                </GuideTile>
              </Link>
            ))}
          </GuidesContent>
        </>
      )}

      <Nav>
        <Content>
          <Link to="/">
            <Logo src={logoImage} />
          </Link>

          <Links>
            <Link to="/outlet">
              <NavLink isActive>{t('navbar.outlet')}</NavLink>
            </Link>
            <Dropdown>
              <Link to="/">
                <DropdownLink>{t('navbar.shop')}</DropdownLink>
              </Link>
              <DropdownContent>
                <Link to={`/products/${ProductType.Male}`}>
                  <DropdownContentLink>
                    {t('navbar.shopOptions.man')}
                  </DropdownContentLink>
                </Link>

                <Link to={`/products/${ProductType.Female}`}>
                  <DropdownContentLink>
                    {t('navbar.shopOptions.woman')}
                  </DropdownContentLink>
                </Link>

                <Link to={`/products/${ProductType.Kid}`}>
                  <DropdownContentLink>
                    {t('navbar.shopOptions.kids')}
                  </DropdownContentLink>
                </Link>
              </DropdownContent>
            </Dropdown>

            <Dropdown>
              <DropdownLink>{t('navbar.creator')}</DropdownLink>
              <DropdownContent>
                <Link to="/configurator/male">
                  <DropdownContentLink>
                    {t('navbar.creatorOptions.male')}
                  </DropdownContentLink>
                </Link>

                <Link to="/configurator/female">
                  <DropdownContentLink>
                    {t('navbar.creatorOptions.female')}
                  </DropdownContentLink>
                </Link>
              </DropdownContent>
            </Dropdown>

            {guides && guides.length > 0 && (
              <Link
                to="/guides"
                onMouseEnter={showGuidesContent}
                onMouseLeave={hideGuidesContent}
                onClick={hideGuidesContent}
              >
                <NavLink>{t('navbar.guide')}</NavLink>
              </Link>
            )}

            <Link to="/about">
              <NavLink>{t('navbar.about')}</NavLink>
            </Link>

            <NavLink onClick={scrollToGallery}>{t('navbar.gallery')}</NavLink>

            <Dropdown>
              <DropdownLink icon>
                <IoPersonCircleOutline />
              </DropdownLink>
              <DropdownContent>{profileDropdownContent()}</DropdownContent>
            </Dropdown>

            {cart.length > 0 && (
              <Link to="/cart">
                <DropdownLink icon>
                  <Badge badgeContent={cart.length} color="error">
                    <ShoppingCartOutlinedIcon />
                  </Badge>
                </DropdownLink>
              </Link>
            )}

            <FlagIcon src={plIcon} onClick={() => changeLanguage('pl')} />
            <FlagIcon src={enIcon} onClick={() => changeLanguage('en')} />
          </Links>

          <MobileIconsContainer>
            {cart.length > 0 && (
              <Link to="/cart">
                <DropdownLink icon>
                  <Badge badgeContent={cart.length} color="error">
                    <ShoppingCartOutlinedIcon />
                  </Badge>
                </DropdownLink>
              </Link>
            )}
            <HamburgerMenu onClick={toggleNavigationDrawer}>
              {sidebarOpen ? <IoClose /> : <GiHamburgerMenu />}
            </HamburgerMenu>
          </MobileIconsContainer>
        </Content>

        <DarkOverlay className={sidebarOpen ? 'is-active' : ''} />

        <NavigationDrawer className={sidebarOpen ? 'is-active' : ''}>
          <Link to="/">
            <NavigationDrawerLink>{t('navbar.shop')}</NavigationDrawerLink>
          </Link>
          <Link to="/configurator/male">
            <NavigationDrawerLink>
              {t('navbar.creatorOptions.male')}
            </NavigationDrawerLink>
          </Link>

          <Link to="/configurator/female">
            <NavigationDrawerLink>
              {t('navbar.creatorOptions.female')}
            </NavigationDrawerLink>
          </Link>
          {guides && guides.length > 0 && (
            <NavigationDrawerLink onClick={() => history.push('/guides')}>
              {t('navbar.guide')}
            </NavigationDrawerLink>
          )}
          <NavigationDrawerLink
            onClick={() => {
              setSidebarOpen(false);
              history.push('/about');
            }}
          >
            {t('navbar.about')}
          </NavigationDrawerLink>
          <NavigationDrawerLink
            onClick={() => {
              setSidebarOpen(false);
              scrollToGallery();
            }}
          >
            {t('navbar.gallery')}
          </NavigationDrawerLink>

          {profileNavigationDrawerContent()}

          <NavigationDrawerFlagIcon
            src={plIcon}
            onClick={() => changeLanguage('pl')}
          />
          <NavigationDrawerFlagIcon
            src={enIcon}
            onClick={() => changeLanguage('en')}
          />
        </NavigationDrawer>
      </Nav>
    </>
  );
};
export default Navbar;
