import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  productsSelectors,
  toggleSelected,
  setSelected,
} from './redux/productsSlice';
import {
  fetchProducts,
  deleteProducts,
  updateBestsellerValue,
  updateOutletValue,
} from './redux/thunk';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { CustomToolbar } from 'admin/components/CustomToolbar';

import { Loader } from 'admin/components/Loader';
import { DashboardContainer } from 'theme/global';
import { ErrorMessage } from 'admin/components/ErrorMessage';
import { Text } from 'theme/typography';
import { ProductType } from 'api/models/Product';
import { useStyles } from './styled';
import StarIcon from '@material-ui/icons/Star';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

export const ProductsContainer: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const { isLoading, error, products, selectedProducts } =
    useSelector(productsSelectors);

  useEffect(() => {
    dispatch(fetchProducts({ page: 1, pageSize: 1000 }));
  }, [dispatch]);

  const handleAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = products.map((product) => product.id);
      dispatch(setSelected(newSelected));
      return;
    }

    dispatch(setSelected([]));
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    dispatch(toggleSelected(id));
  };

  const isSelected = (id: number) => selectedProducts.includes(id);

  const handleDeleteClick = () => {
    dispatch(deleteProducts({ productIds: selectedProducts }));
  };

  const handleMakeBestsellerClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
    newBestsellerValue: boolean,
  ) => {
    e.stopPropagation();

    let numberOfBestsellers = 0;
    products.forEach((product) => {
      if (product.isBestseller) numberOfBestsellers += 1;
    });

    if (numberOfBestsellers >= 4 && newBestsellerValue) {
      alert('Maksymalnie można wybrać 4 bestsellery');
      return;
    }

    dispatch(updateBestsellerValue({ id, newValue: newBestsellerValue }));
  };

  const handleTriggerOutletClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
    newOutletValue: boolean,
  ) => {
    e.stopPropagation();

    dispatch(updateOutletValue({ id, newValue: newOutletValue }));
  };

  const getProductType = (type: ProductType | undefined): string => {
    switch (type) {
      case ProductType.Male:
        return 'Kamizelka męska';

      case ProductType.Female:
        return 'Kamizelka damska';

      case ProductType.Kid:
        return 'Kamizelka dziecięca';

      case ProductType.Voucher:
        return 'Voucher';

      case ProductType.TshirtMale:
        return 'Koszulka męska';

      case ProductType.TshirtFemale:
        return 'Koszulka damska';

      default:
        return '';
    }
  };

  const handleEdit = () => {
    if (!selectedProducts || selectedProducts.length === 0) return;

    history.push(`/dashboard/products/edit/${selectedProducts[0]}`);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <DashboardContainer>
          {error && <ErrorMessage>{error}</ErrorMessage>}

          {products.length > 0 ? (
            <Paper>
              <CustomToolbar
                withEdit
                handleEdit={handleEdit}
                handleDelete={handleDeleteClick}
                numSelected={selectedProducts.length}
                title="Lista produktów"
              />
              <TableContainer>
                <Table aria-labelledby="productsTable" size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={
                            selectedProducts.length > 0 &&
                            selectedProducts.length < products.length
                          }
                          checked={
                            products.length > 0 &&
                            selectedProducts.length === products.length
                          }
                          className={classes.checkbox}
                          onChange={handleAllClick}
                        />
                      </TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell>Nazwa</TableCell>
                      <TableCell>Typ</TableCell>
                      <TableCell>Cena</TableCell>
                      <TableCell>Cena promocyjna</TableCell>
                      <TableCell>Bestseller</TableCell>
                      <TableCell>Outlet</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {products.map((product) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={product.id}
                        onClick={(event) => handleClick(event, product.id)}
                        selected={isSelected(product.id)}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isSelected(product.id)}
                            className={classes.checkbox}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {product.id}
                        </TableCell>
                        <TableCell>{product.name}</TableCell>
                        <TableCell>{getProductType(product.type)}</TableCell>
                        <TableCell>
                          {product.isVoucher ? '-' : `${product.price} zł`}
                        </TableCell>
                        <TableCell>
                          {product.discountPrice && product.isOnSale
                            ? `${product.discountPrice} zł`
                            : '-'}
                        </TableCell>
                        <TableCell>
                          <Tooltip
                            title={
                              product.isBestseller
                                ? 'Usuń z bestsellerów'
                                : 'Dodaj do bestsellerów'
                            }
                          >
                            <IconButton
                              onClick={(e) =>
                                handleMakeBestsellerClick(
                                  e,
                                  product.id,
                                  !product.isBestseller,
                                )
                              }
                            >
                              {product.isBestseller ? (
                                <StarIcon
                                  className={classes.bestsellerIconActive}
                                />
                              ) : (
                                <StarBorderOutlinedIcon
                                  className={classes.bestSellerIcon}
                                />
                              )}
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Tooltip
                            title={
                              product.isOutlet
                                ? 'Usuń z outletu'
                                : 'Dodaj do outletu'
                            }
                          >
                            <IconButton
                              onClick={(e) =>
                                handleTriggerOutletClick(
                                  e,
                                  product.id,
                                  !product.isOutlet,
                                )
                              }
                            >
                              {product.isOutlet ? (
                                <StarIcon
                                  className={classes.bestsellerIconActive}
                                />
                              ) : (
                                <StarBorderOutlinedIcon
                                  className={classes.bestSellerIcon}
                                />
                              )}
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ) : (
            <Text>Brak dodanych produktów</Text>
          )}
        </DashboardContainer>
      )}
    </>
  );
};
