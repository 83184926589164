import { createAsyncThunk } from '@reduxjs/toolkit';
import { Cut, Fleece, Material, Button } from 'api/models/CreatorModels';
import { axiosInstance } from 'axiosConfig';
import { ResultsProps, setUploadPercentage } from './creatorSlice';

export const fetchCreatorElements = createAsyncThunk(
  'admin/creator/fetchAll',
  async () => {
    const response = await axiosInstance.get<ResultsProps>('configurator/all');

    return response.data;
  },
);

export const addCut = createAsyncThunk<
  Cut,
  {
    images: FileList;
    withCollar: boolean;
    isFemale: boolean;
    isOversize: boolean;
  },
  { rejectValue: string }
>(
  'admin/configurator/addCut',
  async (
    { images, withCollar, isFemale, isOversize },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const formData = new FormData();
      formData.append('configurator-images[]', images[0]);
      formData.append('withCollar', String(withCollar));
      formData.append('isFemale', String(isFemale));
      formData.append('isOversize', String(isOversize));

      const response = await axiosInstance.post<Cut>(
        'configurator/cuts',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent: ProgressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            dispatch(setUploadPercentage(percentCompleted));
          },
        },
      );

      return response.data;
    } catch (error: any) {
      if (!error.response.data.message) throw error;

      return rejectWithValue(error.response.data.message);
    }
  },
);

export const addMaterial = createAsyncThunk<
  Material,
  {
    iconImage: File;
    cutImages: File[];
    backImage: File;
    beltImage: File;
    collarImage: File;
    isFemale: boolean;
    backOversizeImage?: File;
    collarOversizeImage?: File;
  },
  { rejectValue: string }
>(
  'admin/configurator/addMaterial',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const formData = new FormData();
      formData.append('iconImage', data.iconImage);
      formData.append('backImage', data.backImage);
      formData.append('beltImage', data.beltImage);
      formData.append('collarImage', data.collarImage);
      formData.append('isFemale', String(data.isFemale));

      if (data.backOversizeImage)
        formData.append('backOversizeImage', data.backOversizeImage);
      if (data.collarOversizeImage)
        formData.append('collarOversizeImage', data.collarOversizeImage);

      data.cutImages.forEach((cutImage) => {
        formData.append('cutImages[]', cutImage);
      });

      const response = await axiosInstance.post<Material>(
        'configurator/materials',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent: ProgressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            dispatch(setUploadPercentage(percentCompleted));
          },
        },
      );

      return response.data;
    } catch (error: any) {
      if (!error.response.data.message) throw error;

      return rejectWithValue(error.response.data.message);
    }
  },
);

export const editMaterial = createAsyncThunk<
  Material,
  {
    id: number;
    iconImage?: File;
    cutImages?: File[];
    backImage?: File;
    beltImage?: File;
    collarImage?: File;
    backOversizeImage?: File;
    collarOversizeImage?: File;
    updatedCutImages?: { cutName: number; position: number }[];
    isFemale: boolean;
  },
  { rejectValue: string }
>(
  'admin/configurator/editMaterial',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const formData = new FormData();
      if (data.iconImage) formData.append('iconImage', data.iconImage);
      if (data.backImage) formData.append('backImage', data.backImage);
      if (data.beltImage) formData.append('beltImage', data.beltImage);
      if (data.collarImage) formData.append('collarImage', data.collarImage);

      if (data.backOversizeImage)
        formData.append('backOversizeImage', data.backOversizeImage);
      if (data.collarOversizeImage)
        formData.append('collarOversizeImage', data.collarOversizeImage);

      formData.append('isFemale', String(data.isFemale));
      if (data.updatedCutImages)
        formData.append(
          'updatedCutImages',
          JSON.stringify(data.updatedCutImages),
        );

      if (data.cutImages) {
        data.cutImages.forEach((cutImage) => {
          formData.append('cutImages[]', cutImage);
        });
      }

      const response = await axiosInstance.patch<Material>(
        `configurator/materials/${data.id}`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent: ProgressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            dispatch(setUploadPercentage(percentCompleted));
          },
        },
      );

      return response.data;
    } catch (error: any) {
      if (!error.response.data.message) throw error;

      return rejectWithValue(error.response.data.message);
    }
  },
);

export const addFleece = createAsyncThunk<
  Fleece,
  {
    iconImage: File;
    frontImage: File;
    backImage: File;
    backFemaleImage: File;
  },
  { rejectValue: string }
>(
  'admin/configurator/addFleece',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const formData = new FormData();
      formData.append('iconImage', data.iconImage);
      formData.append('frontImage', data.frontImage);
      formData.append('backImage', data.backImage);
      formData.append('backFemaleImage', data.backFemaleImage);

      const response = await axiosInstance.post<Fleece>(
        'configurator/fleeces',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent: ProgressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            dispatch(setUploadPercentage(percentCompleted));
          },
        },
      );

      return response.data;
    } catch (error: any) {
      if (!error.response.data.message) throw error;

      return rejectWithValue(error.response.data.message);
    }
  },
);

export const addButtons = createAsyncThunk<
  Button,
  {
    iconImage: File;
    cutImages: File[];
    isFemale: boolean;
    isOversize: boolean;
  },
  { rejectValue: string }
>(
  'admin/configurator/addButtons',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const formData = new FormData();
      formData.append('iconImage', data.iconImage);
      formData.append('isFemale', String(data.isFemale));
      formData.append('isOversize', String(data.isOversize));

      data.cutImages.forEach((cutImage) => {
        formData.append('cutImages[]', cutImage);
      });

      const response = await axiosInstance.post<Button>(
        'configurator/buttons',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent: ProgressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            dispatch(setUploadPercentage(percentCompleted));
          },
        },
      );

      return response.data;
    } catch (error: any) {
      if (!error.response.data.message) throw error;

      return rejectWithValue(error.response.data.message);
    }
  },
);

export const deleteCuts = createAsyncThunk<
  number[],
  number[],
  { rejectValue: string }
>('admin/configurator/deleteCuts', async (ids, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.delete<number[]>('configurator/cuts', {
      data: {
        cutsIds: ids,
      },
    });

    return response.data;
  } catch (error: any) {
    if (!error.response.data.message) throw error;

    return rejectWithValue(error.response.data.message);
  }
});

export const deleteMaterials = createAsyncThunk<
  number[],
  number[],
  { rejectValue: string }
>('admin/configurator/deleteMaterials', async (ids, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.delete<number[]>(
      'configurator/materials',
      {
        data: {
          materialIds: ids,
        },
      },
    );

    return response.data;
  } catch (error: any) {
    if (!error.response.data.message) throw error;

    return rejectWithValue(error.response.data.message);
  }
});

export const deleteFleeces = createAsyncThunk<
  number[],
  number[],
  { rejectValue: string }
>('admin/configurator/deleteFleeces', async (ids, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.delete<number[]>(
      'configurator/fleeces',
      {
        data: {
          fleeceIds: ids,
        },
      },
    );

    return response.data;
  } catch (error: any) {
    if (!error.response.data.message) throw error;

    return rejectWithValue(error.response.data.message);
  }
});

export const deleteButtons = createAsyncThunk<
  number[],
  number[],
  { rejectValue: string }
>('admin/configurator/deleteButtons', async (ids, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.delete<number[]>(
      'configurator/buttons',
      {
        data: {
          buttonIds: ids,
        },
      },
    );

    return response.data;
  } catch (error: any) {
    if (!error.response.data.message) throw error;

    return rejectWithValue(error.response.data.message);
  }
});
