import styled from 'styled-components';
import { Container, OutlinedButton } from 'theme/global';
import { device } from 'theme/theme';
import { makeStyles } from '@material-ui/core/styles';

export const CheckoutContainer = styled(Container)`
  margin-top: 12rem;
  min-height: 100vh;
  width: 60%;

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const OrderButton = styled(OutlinedButton)`
  margin-top: 2rem;
`;

export const FieldsGroup = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const useStyles = makeStyles({
  textField: {
    margin: '1rem 0',
  },

  inlineTextField: {
    '&:not(:last-of-type)': {
      marginRight: '1rem',
    },
  },
});

export const TermsLink = styled.a`
  color: ${(props) => props.theme.colors.primary};
  font-weight: bold;

  &::after {
    content: '*';
    color: ${(props) => props.theme.colors.danger};
  }
`;
