import { createSlice } from '@reduxjs/toolkit';
import { Product } from 'api/models/Product';
import { fetchBestsellers, fetchOutletProducts } from './thunk';

interface LandingPageState {
  bestsellersLoading: boolean;
  outletProductsLoading: boolean;
  bestsellers: Product[];
  outletProducts: Product[];
  error: string | undefined;
}

const initialState: LandingPageState = {
  bestsellersLoading: false,
  outletProductsLoading: false,
  bestsellers: [],
  outletProducts: [],
  error: undefined,
};

const landingSlice = createSlice({
  name: 'landing',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBestsellers.pending, (state) => {
      state.bestsellersLoading = true;
      state.error = undefined;
    });
    builder.addCase(fetchBestsellers.fulfilled, (state, { payload }) => {
      state.bestsellersLoading = false;
      state.bestsellers = payload;
    });
    builder.addCase(fetchBestsellers.rejected, (state, action) => {
      state.bestsellersLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    builder.addCase(fetchOutletProducts.pending, (state) => {
      state.outletProductsLoading = true;
      state.error = undefined;
    });
    builder.addCase(fetchOutletProducts.fulfilled, (state, { payload }) => {
      state.outletProductsLoading = false;
      state.outletProducts = payload;
    });
    builder.addCase(fetchOutletProducts.rejected, (state, action) => {
      state.outletProductsLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });
  },
});

export default landingSlice.reducer;
