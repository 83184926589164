import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GalleryImage } from 'api/models/GalleryImage';
import { RootState } from 'store';
import { fetchGalleryImages, deleteImages, uploadImages } from './thunk';

interface GalleryState {
  isLoading: boolean;
  error: string | undefined;
  images: GalleryImage[];
  totalImages: number;
  selectedImages: number[];
  snackbarOpen: boolean;
  snackbarText: string;
  uploadPercentage: number;
  isUploading: boolean;
}

const initialState: GalleryState = {
  isLoading: false,
  error: undefined,
  images: [],
  totalImages: 0,
  selectedImages: [],
  snackbarOpen: false,
  snackbarText: '',
  uploadPercentage: 0,
  isUploading: false,
};

const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    setSelectedImages: (state, action: PayloadAction<number[]>) => {
      state.selectedImages = action.payload;
    },
    toggleSelectedImages: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      if (state.selectedImages.includes(id)) {
        state.selectedImages = state.selectedImages.filter(
          (imageId) => imageId !== id,
        );
      } else {
        state.selectedImages = [...state.selectedImages, id];
      }
    },
    closeSnackbar: (state) => {
      state.snackbarOpen = false;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setUploadPercentage: (state, action: PayloadAction<number>) => {
      state.uploadPercentage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGalleryImages.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
      state.snackbarOpen = false;
      state.snackbarText = '';
    });
    builder.addCase(fetchGalleryImages.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.images = payload.images;
      state.totalImages = payload.total;
    });
    builder.addCase(fetchGalleryImages.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.totalImages = 0;
    });

    builder.addCase(deleteImages.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
      state.snackbarOpen = false;
      state.snackbarText = '';
    });
    builder.addCase(deleteImages.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.images = state.images.filter(
        (image) => !payload.includes(Number(image.id)),
      );
      state.selectedImages = [];
      state.totalImages -= payload.length;

      state.snackbarOpen = true;
      state.snackbarText = 'Pomyślnie usunięto zdjęcia';
    });
    builder.addCase(deleteImages.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    builder.addCase(uploadImages.pending, (state) => {
      state.isUploading = true;
      state.uploadPercentage = 0;
      state.error = undefined;
      state.snackbarOpen = false;
      state.snackbarText = '';
    });
    builder.addCase(uploadImages.fulfilled, (state, { payload }) => {
      state.isUploading = false;
      state.images = [...state.images, ...payload];
      state.totalImages += payload.length;

      state.snackbarOpen = true;
      state.snackbarText = 'Pomyślnie dodano zdjęcia';
    });
    builder.addCase(uploadImages.rejected, (state, action) => {
      state.isUploading = false;
      state.uploadPercentage = 0;
      state.error = action.payload ? action.payload : action.error.message;
    });
  },
});

export const {
  setSelectedImages,
  toggleSelectedImages,
  closeSnackbar,
  setError,
  setUploadPercentage,
} = gallerySlice.actions;

export const selectGalleryState = (state: RootState) => state.gallery;
export const selectUploadPercentage = (state: RootState) =>
  state.gallery.uploadPercentage;

export default gallerySlice.reducer;
