import styled from 'styled-components';
import { device } from 'theme/theme';

export const AdditionalInfoContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media ${device.mobile} {
    display: block;
  }
`;

export const AdditionalInfo = styled.div`
  width: 25rem;
  min-height: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  border-radius: ${(props) => props.theme.borderRadius};
  color: ${(props) => props.theme.colors.primary};
  padding: 1rem 2rem;
  margin: 0 0.5rem;

  @media ${device.mobile} {
    width: 100%;
    margin: 1rem 0;
    justify-content: flex-start;

    &:first-of-type {
      margin-top: 0;
    }
  }
`;

export const AdditionalInfoIcon = styled.div`
  margin-right: 1rem;
`;

export const AdditionalInfoText = styled.p`
  display: inline;
  padding: 0 2rem;
  font-size: ${(props) => props.theme.fontSizes.xs};

  &:first-of-type {
    padding-left: 0;
  }
`;
