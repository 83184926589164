import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from 'axiosConfig';
import { VoucherType, VoucherTypeToAdd } from 'api/models/VoucherType';

export const fetchVoucherTypes = createAsyncThunk(
  'admin/voucherTypes/fetchVoucherTypes',
  async () => {
    const response = await axiosInstance.get<VoucherType[]>('voucher-types');
    return response.data;
  },
);

export const addVoucherType = createAsyncThunk<
  VoucherType,
  VoucherTypeToAdd,
  { rejectValue: string }
>('admin/voucherTypes/addVoucherType', async (newType, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post<VoucherType>('voucher-types', {
      newType,
    });

    return response.data;
  } catch (error) {
    if (!error.message) throw error;

    return rejectWithValue(error.message);
  }
});

export const deleteVoucherTypes = createAsyncThunk<
  number[],
  number[],
  { rejectValue: string }
>('admin/voucherTypes/deleteVoucherTypes', async (ids, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.delete<number[]>('voucher-types', {
      data: { ids },
    });

    return response.data;
  } catch (error) {
    if (!error.message) throw error;

    return rejectWithValue(error.message);
  }
});
