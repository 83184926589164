import React, { useEffect, useState } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';

import { DialogLayout } from '../DialogLayout';
import { ImageUploader } from 'admin/components/ImageUploader';
import { useStyles } from '../DialogLayout/styled';

interface AddCutDialogProps {
  title: string;
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (
    image: FileList,
    withCollar: boolean,
    isFemale: boolean,
    isOversize: boolean,
  ) => void;
}

export const AddCutDialog: React.FC<AddCutDialogProps> = ({
  title,
  isOpen,
  handleClose,
  handleSubmit,
}) => {
  const classes = useStyles();

  const [images, setImages] = useState<FileList>();
  const [withCollar, setWithCollar] = useState(false);
  const [isFemale, setIsFemale] = useState(false);
  const [isOversize, setIsOversize] = useState(false);

  // when isOversize is set to true set isFemale also true
  useEffect(() => {
    if (!isOversize || isFemale) return;

    setIsFemale(true);

    // eslint-disable-next-line
  }, [isOversize]);

  const handleWithCollarChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setWithCollar(event.target.checked);
  };

  const handleIsFemaleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsFemale(event.target.checked);
  };

  const handleIsOversizeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsOversize(event.target.checked);
  };

  const handleAddCut = () => {
    if (!images || images.length === 0) return;

    handleSubmit(images, withCollar, isFemale, isOversize);
  };

  return (
    <DialogLayout
      isOpen={isOpen}
      title={title}
      handleClose={handleClose}
      handleSubmit={handleAddCut}
    >
      <ImageUploader title="Zdjęcie" multiple={false} setImages={setImages} />

      <FormGroup>
        <FormControlLabel
          label="Z kołnierzem"
          control={
            <Checkbox
              checked={withCollar}
              onChange={handleWithCollarChange}
              className={classes.checkbox}
            />
          }
        />

        <FormControlLabel
          label="Damski"
          control={
            <Checkbox
              checked={isFemale}
              onChange={handleIsFemaleChange}
              className={classes.checkbox}
            />
          }
        />

        <FormControlLabel
          label="Oversize"
          control={
            <Checkbox
              checked={isOversize}
              onChange={handleIsOversizeChange}
              className={classes.checkbox}
            />
          }
        />
      </FormGroup>
    </DialogLayout>
  );
};
