import { createSlice } from '@reduxjs/toolkit';
import { fetchGuides, fetchSingleGuide } from './thunk';

interface GuidesState {
  isLoading: boolean;
  error: string | undefined;
  guides: any[];
  currentGuide: any;
}

const initialState: GuidesState = {
  isLoading: false,
  error: undefined,
  guides: [],
  currentGuide: undefined,
};

const guidesSlice = createSlice({
  name: 'guides',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGuides.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(fetchGuides.fulfilled, (state, action) => {
      state.isLoading = false;
      state.guides = action.payload;
    });
    builder.addCase(fetchGuides.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    builder.addCase(fetchSingleGuide.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(fetchSingleGuide.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentGuide = action.payload;
    });
    builder.addCase(fetchSingleGuide.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });
  },
});

export default guidesSlice.reducer;
