import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product } from 'api/models/Product';
import { fetchProducts } from './thunk';

interface OutletState {
  isLoading: boolean;
  products: Product[];
  totalProducts: number;
  currentPage: number;
  fetchProducts: boolean;
  error: string | null | undefined;
}

const initialState: OutletState = {
  isLoading: false,
  products: [],
  totalProducts: 0,
  currentPage: 1,
  fetchProducts: true,
  error: null,
};

export const outletSlice = createSlice({
  name: 'outlet',
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
      state.fetchProducts = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchProducts.fulfilled, (state, { payload }) => {
      state.products = payload.products;
      state.totalProducts = payload.totalNumber;
      state.isLoading = false;
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { setCurrentPage } = outletSlice.actions;

export default outletSlice.reducer;
