import { RootState } from 'store';

export const selectBestsellersLoading = (state: RootState) =>
  state.landing.bestsellersLoading;
export const selectOutletProductsLoading = (state: RootState) =>
  state.landing.outletProductsLoading;
export const selectBestsellers = (state: RootState) =>
  state.landing.bestsellers;
export const selectOutletProducts = (state: RootState) =>
  state.landing.outletProducts;
export const selectError = (state: RootState) => state.landing.error;
