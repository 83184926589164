import styled from 'styled-components';
import { Container } from 'theme/global';

export const PaymentContainer = styled(Container)`
  margin-top: 12rem;
`;

export const Summary = styled.div`
  margin-top: 4rem;
  border-radius: 0.5rem;
`;

export const SummaryTitle = styled.h1`
  font-size: ${(props) => props.theme.fontSizes.m};
  color: ${(props) => props.theme.colors.primary};
  margin: 1rem 0;

  &:last-of-type {
    margin-top: 4rem;
  }
`;

export const SummaryText = styled.p`
  font-size: ${(props) => props.theme.fontSizes.s};
  color: ${(props) => props.theme.colors.primary};
  margin: 0.5rem 0;
`;

export const PayButton = styled.div`
  margin-top: 2rem;
`;

export const PayPalContainer = styled.div`
  margin-top: 2rem;
`;

export const CancelButton = styled.p`
  color: ${(props) => props.theme.colors.danger};
  font-size: ${(props) => props.theme.fontSizes.s};
  cursor: pointer;
  margin-top: 4rem;

  &:hover {
    text-decoration: underline;
  }
`;

export const Link = styled.a`
  color: ${(props) => props.theme.colors.accent};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const RequiredField = styled.span`
  color: ${(props) => props.theme.colors.danger};
`;
