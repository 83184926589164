import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  fetchProducts,
  setCurrentPage,
  selectIsLoading,
  selectProducts,
  selectTotalProducts,
  selectCurrentPage,
} from './productsSlice';
import { Link, useParams } from 'react-router-dom';
import Pagination from './Pagination';
import Loader from 'components/Loader';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { animateScroll } from 'react-scroll';
import { ProductType } from 'api/models/Product';

import {
  ProductsContainer,
  Product,
  ImagesContainer,
  ProductImage,
  LabelContainer,
  Label,
  DiscountPriceContainer,
  Price,
  DiscountPrice,
  PaginationContainer,
  NoProductsText,
} from './styled';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    productImageSkeleton: {
      width: '40rem',
      height: '50rem',
      margin: '0 1rem',

      [theme.breakpoints.down('sm')]: {
        width: '15rem',
        height: '20rem',
      },

      [theme.breakpoints.down('md')]: {
        width: '30rem',
        height: '35rem',
      },
    },
    productLabelSkeleton: {
      width: '40rem',
      height: '2rem',
      marginTop: '1rem',
      marginRight: '1rem',
      marginLeft: '1rem',

      [theme.breakpoints.down('sm')]: {
        width: '15rem',
        height: '1rem',
      },

      [theme.breakpoints.down('md')]: {
        width: '30rem',
        height: '1rem',
      },
    },
    productSecondLabelSkeleton: {
      width: '20rem',
      height: '2rem',
      marginTop: '0.5rem',
      marginBottom: '2rem',
      marginRight: '1rem',
      marginLeft: '1rem',

      [theme.breakpoints.down('sm')]: {
        width: '7.5rem',
        height: '1rem',
      },

      [theme.breakpoints.down('md')]: {
        width: '15rem',
        height: '1rem',
      },
    },
  }),
);

interface ProductsParams {
  category?: ProductType;
}

const Products: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const { category } = useParams<ProductsParams>();

  const isLoading = useAppSelector(selectIsLoading);
  const products = useAppSelector(selectProducts);
  const totalProducts = useAppSelector(selectTotalProducts);
  const currentPage = useAppSelector(selectCurrentPage);

  const [prevCategory, setPrevCategory] = useState<ProductType>();
  const [loadedImages, setLoadedImages] = useState<number[]>([]);

  useEffect(() => {
    animateScroll.scrollToTop();

    dispatch(fetchProducts({ page: currentPage, category }));
    // eslint-disable-next-line
  }, [currentPage, category]);

  useEffect(() => {
    const loadedImagesArr: number[] = [];
    products.forEach((product) => {
      if (loadedImages.includes(product.id)) {
        loadedImagesArr.push(product.id);
      }
    });
    setLoadedImages(loadedImagesArr);
    // eslint-disable-next-line
  }, [products]);

  useEffect(() => {
    if (category === prevCategory) return;

    dispatch(setCurrentPage(1));
    setPrevCategory(category);
  }, [dispatch, category, prevCategory]);

  const handleImageOnLoad = (idx: number) => {
    setLoadedImages((previousImages) => [...previousImages, idx]);
  };

  const renderedProducts = products.map((product) => (
    <div key={product.id}>
      {!loadedImages.includes(product.id) && (
        <>
          <Skeleton
            variant="rect"
            animation="wave"
            className={classes.productImageSkeleton}
          />
          <Skeleton
            variant="rect"
            animation="wave"
            className={classes.productLabelSkeleton}
          />
          <Skeleton
            variant="rect"
            animation="wave"
            className={classes.productSecondLabelSkeleton}
          />
        </>
      )}
      <Product visible={loadedImages.includes(product.id)}>
        <Link to={`/product/${product.id}`}>
          <ImagesContainer>
            <ProductImage
              src={product.images[1].url}
              onLoad={() => handleImageOnLoad(product.id)}
            />
            <ProductImage src={product.images[0].url} />
          </ImagesContainer>

          <LabelContainer voucher={product.isVoucher}>
            <Label>{product.name}</Label>

            {!product.isVoucher && (
              <>
                {product.isOnSale ? (
                  <DiscountPriceContainer>
                    <Price sale>{product.price} zł</Price>
                    <DiscountPrice>{product.discountPrice} zł</DiscountPrice>
                  </DiscountPriceContainer>
                ) : (
                  <Price>{product.price} zł</Price>
                )}
              </>
            )}
          </LabelContainer>
        </Link>
      </Product>
    </div>
  ));

  return (
    <>
      {!isLoading && products.length === 0 ? (
        <NoProductsText>{t('product.noProducts')}</NoProductsText>
      ) : (
        <ProductsContainer>{renderedProducts}</ProductsContainer>
      )}

      {!isLoading && products.length > 0 && (
        <PaginationContainer>
          <Pagination
            currentPage={currentPage}
            totalResults={totalProducts}
            pageSize={6}
            changePage={(newPage) => dispatch(setCurrentPage(newPage))}
          />
        </PaginationContainer>
      )}
      {isLoading && <Loader />}
    </>
  );
};

export default Products;
