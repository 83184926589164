import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Coupon } from 'api/models/Coupon';
import { RootState } from 'store';
import { fetchCoupons, deleteCoupons, addCoupon } from './thunk';

interface CouponsState {
  isLoading: boolean;
  error: string | undefined;
  coupons: Coupon[];
  selectedCoupons: number[];
  snackbarOpen: boolean;
  snackbarText: string;
}

const initialState: CouponsState = {
  isLoading: false,
  error: undefined,
  coupons: [],
  selectedCoupons: [],
  snackbarOpen: false,
  snackbarText: '',
};

const couponsSlice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {
    setSelected: (state, action: PayloadAction<number[]>) => {
      state.selectedCoupons = action.payload;
    },
    toggleSelected: (state, action: PayloadAction<number>) => {
      if (state.selectedCoupons.includes(action.payload)) {
        state.selectedCoupons = state.selectedCoupons.filter(
          (selected) => selected !== action.payload,
        );
      } else {
        state.selectedCoupons.push(action.payload);
      }
    },
    closeSnackbar: (state) => {
      state.snackbarOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCoupons.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(fetchCoupons.fulfilled, (state, action) => {
      state.isLoading = false;
      state.coupons = action.payload;
    });
    builder.addCase(fetchCoupons.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteCoupons.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(deleteCoupons.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.coupons = state.coupons.filter(
        (coupon) => !payload.includes(coupon.id),
      );
      state.selectedCoupons = state.selectedCoupons.filter(
        (selectedCoupon) => !payload.includes(selectedCoupon),
      );

      state.snackbarOpen = true;
      state.snackbarText = 'Pomyślnie usunięto kod promocyjny';
    });
    builder.addCase(deleteCoupons.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    builder.addCase(addCoupon.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(addCoupon.fulfilled, (state, action) => {
      state.isLoading = false;
      state.coupons.push(action.payload);
      state.snackbarOpen = true;
      state.snackbarText = 'Pomyślnie dodano kod promocyjny';
    });
    builder.addCase(addCoupon.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });
  },
});

export const { setSelected, toggleSelected, closeSnackbar } =
  couponsSlice.actions;

export const selectCouponsState = (state: RootState) => state.coupons;

export default couponsSlice.reducer;
