import React, { useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  selectIsLoading,
  selectError,
  selectOrder,
  placeOrder,
} from './checkoutSlice';
import { selectCart, selectCoupon } from 'containers/Cart/cartSlice';
import { CustomProduct, isCustomProduct } from 'api/models/Product';
import { Order, OrderProduct, OrderStatus, Shipping } from 'api/models/Order';
import { selectUser } from 'containers/Auth/authSlice';
import { calculateOrderPrice, sendCustomGtmEvent } from 'utils/helperFunctions';

import {
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from '@material-ui/core';

import {
  CheckoutContainer,
  OrderButton,
  useStyles,
  TermsLink,
  FieldsGroup,
} from './styled';
import { Form } from 'theme/global';
import Stepper from 'components/ProgressStepper';
import Loader from 'components/Loader';
import { GTM_EVENTS } from 'types/enums';

interface FormValues {
  name: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  postalCode: string;
  shippingMethod: Shipping;
  additionalInfo: string;
  termsAccepted: boolean;
}

function Checkout() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);
  const order = useSelector(selectOrder);
  const cartProducts = useSelector(selectCart);
  const coupon = useSelector(selectCoupon);
  const user = useSelector(selectUser);

  const orderPrice = useMemo(() => {
    return calculateOrderPrice(cartProducts);
  }, [cartProducts]);

  useEffect(() => {
    if (!error) return;

    alert(`${t('errors.request')} ${error}`);
  }, [t, error]);

  useEffect(() => {
    if (!order) return;

    history.push('/payment');
  }, [dispatch, history, order]);

  const checkoutSchema = Yup.object().shape({
    name: Yup.string().required(t('auth.requiredField')),
    email: Yup.string()
      .email(t('auth.invalidEmail'))
      .required(t('auth.emptyEmail')),
    phone: Yup.string()
      .required(t('auth.requiredField'))
      .matches(/^[0-9 ()+-]*$/, t('auth.phoneDigits')),
    address: Yup.string().required(t('auth.requiredField')),
    city: Yup.string().required(t('auth.requiredField')),
    postalCode: Yup.string().required(t('auth.requiredField')),
    additionalInfo: Yup.string().max(1000, t('auth.additionalInfoLength')),
    termsAccepted: Yup.boolean().isTrue(t('auth.requiredField')),
  });

  const createOrderObject = (values: FormValues): Order => {
    const customProducts: CustomProduct[] = [];
    const products: OrderProduct[] = [];
    const order: Order = {
      name: values.name,
      email: values.email,
      phone_number: values.phone,
      address: values.address,
      city: values.city,
      post_code: values.postalCode,
      additional_info: values.additionalInfo,
      status: OrderStatus.Pending,
      user_id: 0,
      price: 0,
      discount: coupon ? coupon.discount : 0,
      shipping: values.shippingMethod,
    };

    let price = 0;

    cartProducts.forEach((cartProduct) => {
      if (isCustomProduct(cartProduct.product)) {
        customProducts.push(cartProduct.product);
        price += cartProduct.product.price;
      } else {
        products.push({
          ...cartProduct.product,
          size: cartProduct.size,
          quantity: cartProduct.quantity,
        });

        if (cartProduct.product.isVoucher) {
          const choosedVoucherType = cartProduct.product.voucherTypes.filter(
            (type) => type.name === cartProduct.size,
          );

          price += choosedVoucherType[0].price;
        } else {
          price +=
            (cartProduct.product.isOnSale
              ? cartProduct.product.discountPrice || cartProduct.product.price
              : cartProduct.product.price) * cartProduct.quantity;
        }
      }
    });

    order.products = products;
    order.customProducts = customProducts;
    order.price = price;

    return order;
  };

  const formik = useFormik({
    initialValues: {
      name: user ? user.name : '',
      email: user ? user.email : '',
      phone: user ? user.phone : '',
      address: '',
      city: '',
      postalCode: '',
      shippingMethod: orderPrice > 400 ? Shipping.Free : Shipping.Local,
      additionalInfo: '',
      termsAccepted: false,
    },
    validationSchema: checkoutSchema,
    onSubmit: (values: FormValues) => {
      const order = createOrderObject(values);

      sendCustomGtmEvent(GTM_EVENTS.PlaceOrder, {
        shipping_method: order.shipping,
      });

      dispatch(placeOrder(order));
    },
  });

  const termsLabel = (
    <p>
      {t('checkout.accept')}{' '}
      <TermsLink
        href="/files/terms-of-service.pdf"
        target="_blank"
        rel="noreferrer noopener"
      >
        {t('checkout.terms')}
      </TermsLink>
    </p>
  );

  return (
    <CheckoutContainer>
      <Stepper activeStep={1} />

      <Form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          variant="outlined"
          id="name"
          name="name"
          className={classes.textField}
          label={t('checkout.nameLabel')}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />

        <TextField
          fullWidth
          variant="outlined"
          id="email"
          name="email"
          className={classes.textField}
          label={t('checkout.emailLabel')}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />

        <TextField
          fullWidth
          variant="outlined"
          id="phone"
          name="phone"
          className={classes.textField}
          label={t('checkout.phoneLabel')}
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
        />

        <FieldsGroup>
          <TextField
            fullWidth
            variant="outlined"
            id="address"
            name="address"
            className={clsx([classes.textField, classes.inlineTextField])}
            label={t('checkout.addressLabel')}
            value={formik.values.address}
            onChange={formik.handleChange}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
          />

          <TextField
            fullWidth
            variant="outlined"
            id="postalCode"
            name="postalCode"
            className={clsx([classes.textField, classes.inlineTextField])}
            label={t('checkout.postalCodeLabel')}
            value={formik.values.postalCode}
            onChange={formik.handleChange}
            error={
              formik.touched.postalCode && Boolean(formik.errors.postalCode)
            }
            helperText={formik.touched.postalCode && formik.errors.postalCode}
          />

          <TextField
            fullWidth
            variant="outlined"
            id="city"
            name="city"
            className={clsx([classes.textField, classes.inlineTextField])}
            label={t('checkout.cityLabel')}
            value={formik.values.city}
            onChange={formik.handleChange}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
          />
        </FieldsGroup>

        <FormControl variant="outlined" className={classes.textField}>
          <InputLabel id="shipping-method-label">
            {t('checkout.shippingPlaceholder')}
          </InputLabel>

          <Select
            labelId="shipping-method-label"
            id="shippingMethod"
            name="shippingMethod"
            value={formik.values.shippingMethod}
            onChange={formik.handleChange}
            label={t('checkout.shippingPlaceholder')}
          >
            {orderPrice >= 400 && (
              <MenuItem value={Shipping.Free}>
                {t('checkout.freeShipping')}
              </MenuItem>
            )}
            {orderPrice < 400 && (
              <MenuItem value={Shipping.Local}>
                {t('checkout.shipping1')}
              </MenuItem>
            )}
            {orderPrice < 400 && (
              <MenuItem value={Shipping.Worldwide}>
                {t('checkout.shipping2')}
              </MenuItem>
            )}
            <MenuItem value={Shipping.Self}>{t('checkout.shipping3')}</MenuItem>
          </Select>
        </FormControl>

        <TextField
          fullWidth
          id="additionalInfo"
          name="additionalInfo"
          label={t('checkout.infoPlaceholder')}
          className={classes.textField}
          multiline
          rows={5}
          variant="outlined"
          value={formik.values.additionalInfo}
          onChange={formik.handleChange}
          error={
            formik.touched.additionalInfo &&
            Boolean(formik.errors.additionalInfo)
          }
          helperText={
            formik.touched.additionalInfo && formik.errors.additionalInfo
          }
        />

        <FormControl
          error={
            formik.touched.termsAccepted && Boolean(formik.errors.termsAccepted)
          }
          component="fieldset"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.termsAccepted}
                onChange={formik.handleChange}
                name="termsAccepted"
                color="secondary"
              />
            }
            label={termsLabel}
          />

          {formik.touched.termsAccepted &&
            Boolean(formik.errors.termsAccepted) && (
              <FormHelperText>{t('auth.requiredField')}</FormHelperText>
            )}
        </FormControl>

        <OrderButton type="submit">
          {t('checkout.placeOrderButton')}
        </OrderButton>
      </Form>

      {isLoading && <Loader />}
    </CheckoutContainer>
  );
}

export default Checkout;
