import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Order } from 'api/models/Order';

interface OrdersTableProps {
  orders: Order[];
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    },
    body: {
      fontSize: 16,
    },
  }),
)(TableCell);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: '4rem',
  },
  table: {
    minWidth: 700,
  },
  price: {
    color: theme.palette.success.main,
    fontWeight: 'bold',
  },
}));

export const OrdersTable: React.FC<OrdersTableProps> = ({ orders }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getShipping = (shipping: string): string => {
    switch (shipping) {
      case '1':
        return t('checkout.shipping1');

      case '2':
        return t('checkout.shipping2');

      default:
        return t('checkout.shipping3');
    }
  };

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>{t('ordersTable.id')}</StyledTableCell>
            <StyledTableCell>{t('ordersTable.date')}</StyledTableCell>
            <StyledTableCell>{t('ordersTable.status')}</StyledTableCell>
            <StyledTableCell>{t('ordersTable.shipping')}</StyledTableCell>
            <StyledTableCell>{t('ordersTable.discount')}</StyledTableCell>
            <StyledTableCell>{t('ordersTable.finalPrice')}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => (
            <TableRow key={order.id}>
              <StyledTableCell component="th" scope="row">
                {order.id}
              </StyledTableCell>
              <StyledTableCell>
                {new Date(order.created_at || '').toLocaleDateString()}
              </StyledTableCell>
              <StyledTableCell>{order.status}</StyledTableCell>
              <StyledTableCell>{getShipping(order.shipping)}</StyledTableCell>
              <StyledTableCell>{order.discount} %</StyledTableCell>
              <StyledTableCell className={classes.price}>
                {order.finalPrice} zł
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
