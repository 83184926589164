import React from 'react';

import Alert from '@material-ui/lab/Alert';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: '150rem',
      margin: '0 auto',
      marginBottom: '2rem',
    },
  }),
);

export const ErrorMessage: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Alert severity="error">{children}</Alert>
      </div>
    </>
  );
};
