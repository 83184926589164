import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Cut, Material, Fleece, Button } from 'api/models/CreatorModels';
import { RootState } from 'store';
import {
  fetchCreatorElements,
  deleteCuts,
  deleteMaterials,
  deleteFleeces,
  deleteButtons,
  addCut,
  addMaterial,
  addFleece,
  addButtons,
  editMaterial,
} from './thunk';

export interface ResultsProps {
  cuts: Cut[];
  materials: Material[];
  fleeces: Fleece[];
  buttons: Button[];
}

interface CreatorState {
  isLoading: boolean;
  results: ResultsProps;
  error: string | undefined;
  isUploading: boolean;
  uploadPercentage: number;

  snackbarOpen: boolean;
  snackbarText: string;

  selectedCuts: number[];
  selectedMaterials: number[];
  selectedFleeces: number[];
  selectedButtons: number[];
}

const initialState: CreatorState = {
  isLoading: false,
  results: { cuts: [], materials: [], fleeces: [], buttons: [] },
  error: undefined,
  isUploading: false,
  uploadPercentage: 0,

  snackbarOpen: false,
  snackbarText: '',

  selectedCuts: [],
  selectedMaterials: [],
  selectedFleeces: [],
  selectedButtons: [],
};

const creatorSlice = createSlice({
  name: 'creator',
  initialState,
  reducers: {
    setSelectedCuts: (state, action: PayloadAction<number[]>) => {
      state.selectedCuts = action.payload;
    },
    toggleSelectedCuts: (state, action: PayloadAction<number>) => {
      if (state.selectedCuts.includes(action.payload)) {
        state.selectedCuts = state.selectedCuts.filter(
          (selected) => selected !== action.payload,
        );
      } else {
        state.selectedCuts.push(action.payload);
      }
    },

    setSelectedMaterials: (state, action: PayloadAction<number[]>) => {
      state.selectedMaterials = action.payload;
    },
    toggleSelectedMaterials: (state, action: PayloadAction<number>) => {
      if (state.selectedMaterials.includes(action.payload)) {
        state.selectedMaterials = state.selectedMaterials.filter(
          (selected) => selected !== action.payload,
        );
      } else {
        state.selectedMaterials.push(action.payload);
      }
    },

    setSelectedFleeces: (state, action: PayloadAction<number[]>) => {
      state.selectedFleeces = action.payload;
    },
    toggleSelectedFleeces: (state, action: PayloadAction<number>) => {
      if (state.selectedFleeces.includes(action.payload)) {
        state.selectedFleeces = state.selectedFleeces.filter(
          (selected) => selected !== action.payload,
        );
      } else {
        state.selectedFleeces.push(action.payload);
      }
    },

    setSelectedButtons: (state, action: PayloadAction<number[]>) => {
      state.selectedButtons = action.payload;
    },
    toggleSelectedButtons: (state, action: PayloadAction<number>) => {
      if (state.selectedButtons.includes(action.payload)) {
        state.selectedButtons = state.selectedButtons.filter(
          (selected) => selected !== action.payload,
        );
      } else {
        state.selectedButtons.push(action.payload);
      }
    },

    closeSnackbar: (state) => {
      state.snackbarOpen = false;
    },

    setUploadPercentage: (state, action: PayloadAction<number>) => {
      state.uploadPercentage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCreatorElements.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(fetchCreatorElements.fulfilled, (state, action) => {
      state.isLoading = false;
      state.results = action.payload;
    });
    builder.addCase(fetchCreatorElements.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteCuts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteCuts.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.results.cuts = state.results.cuts.filter(
        (cut) => !payload.includes(cut.id!),
      );
      state.selectedCuts = state.selectedCuts.filter(
        (selectedCut) => !payload.includes(selectedCut),
      );

      state.snackbarOpen = true;
      state.snackbarText = 'Pomyślnie usunięto krój';
    });
    builder.addCase(deleteCuts.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    builder.addCase(deleteMaterials.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteMaterials.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.results.materials = state.results.materials.filter(
        (material) => !payload.includes(material.id!),
      );
      state.selectedMaterials = state.selectedMaterials.filter(
        (selectedMaterial) => !payload.includes(selectedMaterial),
      );

      state.snackbarOpen = true;
      state.snackbarText = 'Pomyślnie usunięto materiał';
    });
    builder.addCase(deleteMaterials.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    builder.addCase(deleteFleeces.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteFleeces.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.results.fleeces = state.results.fleeces.filter(
        (fleece) => !payload.includes(fleece.id!),
      );
      state.selectedFleeces = state.selectedFleeces.filter(
        (selectedFleece) => !payload.includes(selectedFleece),
      );

      state.snackbarOpen = true;
      state.snackbarText = 'Pomyślnie usunięto podszewkę';
    });
    builder.addCase(deleteFleeces.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    builder.addCase(deleteButtons.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteButtons.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.results.buttons = state.results.buttons.filter(
        (button) => !payload.includes(button.id!),
      );
      state.selectedButtons = state.selectedButtons.filter(
        (selectedButton) => !payload.includes(selectedButton),
      );

      state.snackbarOpen = true;
      state.snackbarText = 'Pomyślnie usunięto guziki';
    });
    builder.addCase(deleteButtons.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    builder.addCase(addCut.pending, (state) => {
      state.isUploading = true;
      state.uploadPercentage = 0;
      state.error = undefined;
      state.snackbarOpen = false;
      state.snackbarText = '';
    });
    builder.addCase(addCut.fulfilled, (state, { payload }) => {
      state.isUploading = false;
      state.results.cuts.push(payload);
      state.snackbarOpen = true;
      state.snackbarText = 'Pomyślnie dodano krój';
    });
    builder.addCase(addCut.rejected, (state, action) => {
      state.isUploading = false;
      state.uploadPercentage = 0;
      state.error = action.payload ? action.payload : action.error.message;
    });

    builder.addCase(addMaterial.pending, (state) => {
      state.isUploading = true;
      state.uploadPercentage = 0;
      state.error = undefined;
      state.snackbarOpen = false;
      state.snackbarText = '';
    });
    builder.addCase(addMaterial.fulfilled, (state, { payload }) => {
      state.isUploading = false;
      state.results.materials.push(payload);
      state.snackbarOpen = true;
      state.snackbarText = 'Pomyślnie dodano tkaninę';
    });
    builder.addCase(addMaterial.rejected, (state, action) => {
      state.isUploading = false;
      state.uploadPercentage = 0;
      state.error = action.payload ? action.payload : action.error.message;
    });

    builder.addCase(editMaterial.pending, (state) => {
      state.isUploading = true;
      state.uploadPercentage = 0;
      state.error = undefined;
      state.snackbarOpen = false;
      state.snackbarText = '';
    });
    builder.addCase(editMaterial.fulfilled, (state, { payload }) => {
      state.isUploading = false;
      state.results.materials = state.results.materials.map((material) =>
        material.id === payload.id ? payload : material,
      );
      state.snackbarOpen = true;
      state.snackbarText = 'Pomyślnie zaktualizowano tkaninę';
    });
    builder.addCase(editMaterial.rejected, (state, action) => {
      state.isUploading = false;
      state.uploadPercentage = 0;
      state.error = action.payload ? action.payload : action.error.message;
    });

    builder.addCase(addFleece.pending, (state) => {
      state.isUploading = true;
      state.uploadPercentage = 0;
      state.error = undefined;
      state.snackbarOpen = false;
      state.snackbarText = '';
    });
    builder.addCase(addFleece.fulfilled, (state, { payload }) => {
      state.isUploading = false;
      state.results.fleeces.push(payload);
      state.snackbarOpen = true;
      state.snackbarText = 'Pomyślnie dodano podszewkę';
    });
    builder.addCase(addFleece.rejected, (state, action) => {
      state.isUploading = false;
      state.uploadPercentage = 0;
      state.error = action.payload ? action.payload : action.error.message;
    });

    builder.addCase(addButtons.pending, (state) => {
      state.isUploading = true;
      state.uploadPercentage = 0;
      state.error = undefined;
      state.snackbarOpen = false;
      state.snackbarText = '';
    });
    builder.addCase(addButtons.fulfilled, (state, { payload }) => {
      state.isUploading = false;
      state.results.buttons.push(payload);
      state.snackbarOpen = true;
      state.snackbarText = 'Pomyślnie dodano guziki';
    });
    builder.addCase(addButtons.rejected, (state, action) => {
      state.isUploading = false;
      state.uploadPercentage = 0;
      state.error = action.payload ? action.payload : action.error.message;
    });
  },
});

export const selectCreatorElements = (state: RootState) =>
  state.adminCreator.results;
export const selectCreatorState = (state: RootState) => state.adminCreator;

export const {
  setSelectedCuts,
  toggleSelectedCuts,
  setSelectedMaterials,
  toggleSelectedMaterials,
  setSelectedFleeces,
  toggleSelectedFleeces,
  setSelectedButtons,
  toggleSelectedButtons,
  closeSnackbar,
  setUploadPercentage,
} = creatorSlice.actions;

export default creatorSlice.reducer;
