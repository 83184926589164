import styled from 'styled-components';
import {
  makeStyles,
  Theme,
  createStyles,
  lighten,
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      '&.Mui-checked': {
        color: theme.palette.blue.main,
      },
    },
    uploadButton: {
      backgroundColor: theme.palette.blue.main,
      color: theme.palette.light.main,

      '&:hover': {
        backgroundColor: lighten(theme.palette.blue.main, 0.2),
      },
    },
    uploadInput: {
      display: 'none',
    },
  }),
);

interface RowProps {
  withoutBottomBorder?: boolean;
}

export const Row = styled.div<RowProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: ${(props) =>
    props.withoutBottomBorder
      ? 'none'
      : `1px solid ${props.theme.colors.lightGray}`};

  &:last-of-type {
    border-bottom: none;
  }
`;

export const ThumbnailContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Thumbnail = styled.img`
  width: 6.4rem;
  height: 6.4rem;
  background-color: ${(props) => props.theme.colors.lightGray};
  margin-right: 1rem;
`;

export const Label = styled.p`
  font-size: ${(props) => props.theme.fontSizes.s};
`;
