import React from 'react';

import { ModalContainer, ModalContent } from './styled';

interface ModalProps {
  handleModalClose: () => void;
}

const Modal: React.FunctionComponent<ModalProps> = ({
  handleModalClose,
  children,
}) => {
  return (
    <ModalContainer onClick={handleModalClose}>
      <ModalContent>{children}</ModalContent>
    </ModalContainer>
  );
};

export default Modal;
