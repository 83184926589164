import React, { useEffect, useState } from 'react';

import { DialogLayout } from '../DialogLayout';
import { UploadButton } from '../DialogLayout/UploadButton';
import Checkbox from '@material-ui/core/Checkbox';

import {
  useStyles,
  Row,
  ThumbnailContainer,
  Thumbnail,
  Label,
} from '../DialogLayout/styled';
import { Cut } from 'api/models/CreatorModels';

interface AddButtonsDialogProps {
  title: string;
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (
    iconImage: File,
    cutImages: File[],
    isFemale: boolean,
    isOversize: boolean,
  ) => void;
  cuts: Cut[];
}

export const AddButtonsDialog: React.FC<AddButtonsDialogProps> = ({
  title,
  isOpen,
  handleClose,
  handleSubmit,
  cuts,
}) => {
  const classes = useStyles();

  const [isFemale, setIsFemale] = useState(false);
  const [iconImage, setIconImage] = useState<File>();
  const [iconUrl, setIconUrl] = useState<string>();
  const [cutImages, setCutImages] = useState<File[]>([]);
  const [cutImageUrls, setCutImageUrls] = useState<string[]>([]);
  const [cutFemaleImages, setCutFemaleImages] = useState<File[]>([]);
  const [cutFemaleUrls, setCutFemaleUrls] = useState<string[]>([]);
  const [cutOversizeImages, setCutOversizeImages] = useState<File[]>([]);
  const [cutOversizeUrls, setCutOversizeUrls] = useState<string[]>([]);
  const [isOversize, setIsOversize] = useState(false);

  // if isOversize is set to true set isFemale also true
  useEffect(() => {
    if (!isOversize || isFemale) return;

    setIsFemale(true);

    // eslint-disable-next-line
  }, [isOversize]);

  const handleIsFemaleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsFemale(event.target.checked);
  };

  const addButtons = () => {
    if (!iconImage) {
      alert('Dodaj ikonę');
      return;
    }
    if (isFemale && !isOversize && cutFemaleImages.length === 0) {
      alert('Dodaj zdjęcia dla wszystkich kroi');
      return;
    }
    if (isFemale && isOversize && cutOversizeImages.length === 0) {
      alert('Dodaj zdjęcia dla wszystkich kroi');
      return;
    }
    if (!isFemale && cutImages.length === 0) {
      alert('Dodaj zdjęcia dla wszystkich kroi');
      return;
    }

    let cutImagesToAdd;
    if (isFemale && !isOversize) {
      cutImagesToAdd = cutFemaleImages;
    } else if (isFemale && isOversize) {
      cutImagesToAdd = cutOversizeImages;
    } else {
      cutImagesToAdd = cutImages;
    }
    handleSubmit(iconImage, cutImagesToAdd, isFemale, isOversize);
  };

  const femaleCuts = cuts.filter(
    (cut) => cut.female === 1 && cut.oversize === 'false',
  );
  const maleCuts = cuts.filter(
    (cut) => cut.female === 0 && cut.oversize === 'false',
  );
  const oversizeCuts = cuts.filter((cut) => cut.oversize === 'true');

  const renderedContent = () => {
    if (!isFemale) {
      return maleCuts.map((cut, idx) => (
        <Row key={cut.id}>
          <ThumbnailContainer>
            <Thumbnail src={cutImageUrls[idx]} />
            <Label>Guziki - {cut.name} krój</Label>
          </ThumbnailContainer>

          <UploadButton
            inputId={cut.name}
            setImage={(image: File) => {
              const tempArr = [...cutImages];
              if (tempArr[idx]) {
                tempArr[idx] = image;
              } else {
                tempArr.push(image);
              }
              setCutImages(tempArr);
            }}
            setThumbnailUrl={(url: string) => {
              const tempArr = [...cutImageUrls];
              if (tempArr[idx]) {
                tempArr[idx] = url;
              } else {
                tempArr.push(url);
              }
              setCutImageUrls(tempArr);
            }}
          />
        </Row>
      ));
    }

    if (isFemale && !isOversize) {
      return femaleCuts.map((cut, idx) => (
        <Row key={cut.id}>
          <ThumbnailContainer>
            <Thumbnail src={cutFemaleUrls[idx]} />
            <Label>Guziki - {cut.name} krój</Label>
          </ThumbnailContainer>

          <UploadButton
            inputId={cut.name}
            setImage={(image: File) => {
              const tempArr = [...cutFemaleImages];
              if (tempArr[idx]) {
                tempArr[idx] = image;
              } else {
                tempArr.push(image);
              }
              setCutFemaleImages(tempArr);
            }}
            setThumbnailUrl={(url: string) => {
              const tempArr = [...cutFemaleUrls];
              if (tempArr[idx]) {
                tempArr[idx] = url;
              } else {
                tempArr.push(url);
              }
              setCutFemaleUrls(tempArr);
            }}
          />
        </Row>
      ));
    }

    return oversizeCuts.map((cut, idx) => (
      <Row key={cut.id}>
        <ThumbnailContainer>
          <Thumbnail src={cutOversizeUrls[idx]} />
          <Label>Guziki (oversize) - {cut.name} krój</Label>
        </ThumbnailContainer>

        <UploadButton
          inputId={cut.name}
          setImage={(image: File) => {
            const tempArr = [...cutOversizeImages];
            if (tempArr[idx]) {
              tempArr[idx] = image;
            } else {
              tempArr.push(image);
            }
            setCutOversizeImages(tempArr);
          }}
          setThumbnailUrl={(url: string) => {
            const tempArr = [...cutOversizeUrls];
            if (tempArr[idx]) {
              tempArr[idx] = url;
            } else {
              tempArr.push(url);
            }
            setCutOversizeUrls(tempArr);
          }}
        />
      </Row>
    ));
  };

  return (
    <DialogLayout
      isOpen={isOpen}
      title={title}
      handleClose={handleClose}
      handleSubmit={addButtons}
    >
      <Row withoutBottomBorder>
        <Label>Guziki damskie</Label>
        <Checkbox
          checked={isFemale}
          onChange={handleIsFemaleChange}
          className={classes.checkbox}
        />
      </Row>

      <Row withoutBottomBorder>
        <Label>Guziki oversize</Label>
        <Checkbox
          checked={isOversize}
          onChange={(e) => setIsOversize(e.target.checked)}
          className={classes.checkbox}
        />
      </Row>

      <Row>
        <ThumbnailContainer>
          <Thumbnail src={iconUrl} />
          <Label>Ikona</Label>
        </ThumbnailContainer>

        <UploadButton
          inputId="icon"
          setImage={setIconImage}
          setThumbnailUrl={setIconUrl}
        />
      </Row>

      {renderedContent()}
    </DialogLayout>
  );
};
