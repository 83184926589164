import styled from 'styled-components';
import { Container, Size } from 'theme/global';
import { device } from 'theme/theme';
import { makeStyles } from '@material-ui/core/styles';

export const ProductContainer = styled(Container)`
  margin-top: 12rem;
  max-width: 120rem;
`;

export const MainSection = styled.section`
  display: flex;
  justify-content: center;

  @media ${device.mobile} {
    display: block;
  }
`;

export const AdditionalSection = styled.section`
  display: flex;
  align-items: center;
  margin-top: 5rem;
`;

export const ImagesContainer = styled.div`
  max-width: 50%;
  position: relative;
  margin-right: 5rem;
  height: 100%;

  @media ${device.mobile} {
    max-width: 100%;
    margin-right: 0;
    display: flex;
    justify-content: center;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;

  /* @media ${device.smallScreen} {
    width: 50%;
    max-height: 40rem;
  } */

  & > * {
    border-radius: 0.5rem;
  }

  &.relative {
    position: relative;
  }

  &.is-active {
    opacity: 1;
    z-index: 10;
  }
`;

export const ArrowIcon = styled.div`
  padding: 1.5rem;
  font-size: 4rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: ${(props) => props.theme.colors.primary60};
  color: ${(props) => props.theme.colors.light};
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  z-index: 11;
  cursor: pointer;

  &:first-of-type {
    left: 0;
    right: auto;
  }

  &:last-of-type {
    right: 0;
    left: auto;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const Content = styled.div`
  margin-left: 5rem;
  width: 50%;

  @media ${device.smallScreen} {
    width: 100%;
  }

  @media ${device.mobile} {
    margin-left: 0;
  }
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.fontSizes.m};
  font-weight: 500;

  @media ${device.mobile} {
    margin-top: 5rem;
  }
`;

export const Subtitle = styled(Title)`
  font-size: ${(props) => props.theme.fontSizes.s};
  margin: 2rem 0;
`;

export const Description = styled.p`
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.fontSizes.s};
  margin-bottom: 1rem;
`;

interface PriceProps {
  sale?: boolean;
}

export const Price = styled.p<PriceProps>`
  color: ${(props) => props.theme.colors.primary};
  font-weight: ${(props) => (props.sale ? 'normal' : '700')};
  text-decoration: ${(props) => (props.sale ? 'line-through' : 'none')};
  display: ${(props) => (props.sale ? 'inline-block' : 'block')};
  font-size: ${(props) =>
    props.sale ? props.theme.fontSizes.xs : props.theme.fontSizes.m};
  margin: 1rem 0;
`;

export const DiscountPrice = styled(Price)`
  font-size: ${(props) => props.theme.fontSizes.m};
  display: inline;
  margin-left: 1rem;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: ${(props) => props.theme.colors.lightGray};
  margin: 2rem 0;
`;

export const QuantityContainer = styled.div`
  margin-top: 2rem;
`;

export const QuantityButton = styled(Size)`
  font-size: ${(props) => props.theme.fontSizes.s};
  font-weight: bold;
  outline: none;
`;

export const CartButton = styled.button`
  width: 100%;
  display: block;
  outline: none;
  background-color: ${(props) => props.theme.colors.accent};
  color: white;
  font-weight: 600;
  padding: 2rem 4rem;
  border: none;
  border-radius: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: opacity: 0.2s ease-in-out;

  &:hover {
    opacity: 0.9;
  }
`;

export const CartMessage = styled.p`
  font-size: ${(props) => props.theme.fontSizes.s};
  color: ${(props) => props.theme.colors.success};
  display: inline;
  opacity: 0;
  margin-left: 1rem;
  transition: all 0.3s ease-in-out;

  &.is-active {
    opacity: 1;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const useStyles = makeStyles(() => ({
  sizeChartButton: {
    marginLeft: '-8px',
    marginBottom: '1rem',
    textTransform: 'capitalize',
  },
}));
