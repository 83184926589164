import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  selectCreatorElements,
  selectCreatorState,
  setSelectedCuts,
  setSelectedMaterials,
  toggleSelectedCuts,
  toggleSelectedMaterials,
  setSelectedFleeces,
  toggleSelectedFleeces,
  setSelectedButtons,
  toggleSelectedButtons,
  closeSnackbar,
} from './redux/creatorSlice';
import {
  fetchCreatorElements,
  deleteCuts,
  deleteMaterials,
  deleteFleeces,
  deleteButtons,
  addCut,
  addMaterial,
  addFleece,
  addButtons,
  editMaterial,
} from './redux/thunk';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { CustomButton } from 'admin/components/CustomButton';

import { Loader } from 'admin/components/Loader';
import { ErrorMessage } from 'admin/components/ErrorMessage';
import { DashboardContainer } from 'theme/global';
import { CreatorTable } from './CreatorTable';
import { ConfirmationDialog } from 'admin/components/ConfirmationDialog';
import { AddCutDialog } from 'admin/components/ConfiguratorDialogs/AddCutDialog';
import { AddMaterialDialog } from 'admin/components/ConfiguratorDialogs/AddMaterialDialog';
import { AddFleeceDialog } from 'admin/components/ConfiguratorDialogs/AddFleeceDialog';
import { AddButtonsDialog } from 'admin/components/ConfiguratorDialogs/AddButtonsDialog';
import { LoadingDialog } from 'admin/components/LoadingDialog';

enum ConfiguratorType {
  Cut,
  Material,
  Fleece,
  Button,
}

export const CreatorContainer: React.FC = () => {
  const dispatch = useAppDispatch();

  const { cuts, materials, fleeces, buttons } = useAppSelector(
    selectCreatorElements,
  );
  const {
    isLoading,
    error,
    selectedCuts,
    selectedMaterials,
    selectedFleeces,
    selectedButtons,
    snackbarOpen,
    snackbarText,
    isUploading,
    uploadPercentage,
  } = useAppSelector(selectCreatorState);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [toBeDeletedType, setToBeDeletedType] = useState(ConfiguratorType.Cut);
  const [addCutDialogOpen, setAddCutDialogOpen] = useState(false);
  const [addMaterialDialogOpen, setAddMaterialDialogOpen] = useState(false);
  const [addFleeceDialogOpen, setAddFleeceDialogOpen] = useState(false);
  const [addButtonsDialogOpen, setAddButtonsDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchCreatorElements());
  }, [dispatch]);

  const handleDelete = (type: ConfiguratorType) => {
    switch (type) {
      case ConfiguratorType.Cut:
        dispatch(deleteCuts(selectedCuts));
        setConfirmationDialogOpen(false);
        break;

      case ConfiguratorType.Material:
        dispatch(deleteMaterials(selectedMaterials));
        setConfirmationDialogOpen(false);
        break;

      case ConfiguratorType.Fleece:
        dispatch(deleteFleeces(selectedFleeces));
        setConfirmationDialogOpen(false);
        break;

      case ConfiguratorType.Button:
        dispatch(deleteButtons(selectedButtons));
        setConfirmationDialogOpen(false);
        break;
      default:
        break;
    }
  };

  const handleAddCut = (
    images: FileList,
    withCollar: boolean,
    isFemale: boolean,
    isOversize: boolean,
  ) => {
    dispatch(addCut({ images, withCollar, isFemale, isOversize }));
    setAddCutDialogOpen(false);
  };

  const handleAddMaterial = (
    iconImage: File,
    cutImages: File[],
    backImage: File,
    beltImage: File,
    collarImage: File,
    isFemale: boolean,
    backOversizeImage?: File,
    collarOversizeImage?: File,
  ) => {
    dispatch(
      addMaterial({
        iconImage,
        cutImages,
        backImage,
        beltImage,
        collarImage,
        isFemale,
        backOversizeImage,
        collarOversizeImage,
      }),
    );
    setAddMaterialDialogOpen(false);
  };

  const handleEditMaterial = (
    id: number,
    isFemale: boolean,
    updatedCutImages?: { cutName: number; position: number }[],
    iconImage?: File,
    cutImages?: File[],
    backImage?: File,
    beltImage?: File,
    collarImage?: File,
    backOversizeImage?: File,
    collarOversizeImage?: File,
  ) => {
    dispatch(
      editMaterial({
        id,
        updatedCutImages,
        iconImage,
        cutImages,
        backImage,
        beltImage,
        collarImage,
        isFemale,
        backOversizeImage,
        collarOversizeImage,
      }),
    );
  };

  const handleAddFleece = (
    iconImage: File,
    frontImage: File,
    backImage: File,
    backFemaleImage: File,
  ) => {
    dispatch(addFleece({ iconImage, frontImage, backImage, backFemaleImage }));
    setAddFleeceDialogOpen(false);
  };

  const handleAddButtons = (
    iconImage: File,
    cutImages: File[],
    isFemale: boolean,
    isOversize: boolean,
  ) => {
    dispatch(addButtons({ iconImage, cutImages, isFemale, isOversize }));
    setAddButtonsDialogOpen(false);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <DashboardContainer>
          {error && <ErrorMessage>{error}</ErrorMessage>}

          {cuts.length > 0 && (
            <CreatorTable
              title="Kroje"
              type="cut"
              elements={cuts}
              selectedElemensts={selectedCuts}
              setSelected={(selected: number[]) =>
                dispatch(setSelectedCuts(selected))
              }
              toggleSelected={(selected: number) =>
                dispatch(toggleSelectedCuts(selected))
              }
              handleDelete={() => {
                setToBeDeletedType(ConfiguratorType.Cut);
                setConfirmationDialogOpen(true);
              }}
            />
          )}

          <CustomButton
            type="button"
            topMargin="1rem"
            handleClick={() => setAddCutDialogOpen(true)}
          >
            Dodaj krój
          </CustomButton>
          <AddCutDialog
            isOpen={addCutDialogOpen}
            handleClose={() => setAddCutDialogOpen(false)}
            title="Dodaj nowy krój"
            handleSubmit={handleAddCut}
          />

          {materials.length > 0 && (
            <CreatorTable
              title="Tkaniny"
              type="material"
              withMargin
              elements={materials}
              selectedElemensts={selectedMaterials}
              editable
              setSelected={(selected: number[]) =>
                dispatch(setSelectedMaterials(selected))
              }
              toggleSelected={(selected: number) =>
                dispatch(toggleSelectedMaterials(selected))
              }
              handleDelete={() => {
                setToBeDeletedType(ConfiguratorType.Material);
                setConfirmationDialogOpen(true);
              }}
              handleEdit={() => setAddMaterialDialogOpen(true)}
            />
          )}

          <CustomButton
            type="button"
            topMargin="1rem"
            handleClick={() => setAddMaterialDialogOpen(true)}
          >
            Dodaj tkaninę
          </CustomButton>
          <AddMaterialDialog
            isOpen={addMaterialDialogOpen}
            handleClose={() => setAddMaterialDialogOpen(false)}
            title="Dodaj nową tkaninę"
            handleSubmit={handleAddMaterial}
            handleEdit={handleEditMaterial}
            cuts={cuts}
            selectedMaterial={
              selectedMaterials.length === 1
                ? materials.find(
                    (material) => material.id === selectedMaterials[0],
                  )
                : undefined
            }
          />

          {fleeces.length > 0 && (
            <CreatorTable
              title="Podszewki"
              type="fleece"
              withMargin
              elements={fleeces}
              selectedElemensts={selectedFleeces}
              setSelected={(selected: number[]) =>
                dispatch(setSelectedFleeces(selected))
              }
              toggleSelected={(selected: number) =>
                dispatch(toggleSelectedFleeces(selected))
              }
              handleDelete={() => {
                setToBeDeletedType(ConfiguratorType.Fleece);
                setConfirmationDialogOpen(true);
              }}
            />
          )}

          <CustomButton
            type="button"
            topMargin="1rem"
            handleClick={() => setAddFleeceDialogOpen(true)}
          >
            Dodaj podszewkę
          </CustomButton>
          <AddFleeceDialog
            isOpen={addFleeceDialogOpen}
            handleClose={() => setAddFleeceDialogOpen(false)}
            title="Dodaj nową podszewkę"
            handleSubmit={handleAddFleece}
          />

          {buttons.length > 0 && (
            <CreatorTable
              title="Guziki"
              type="button"
              withMargin
              elements={buttons}
              selectedElemensts={selectedButtons}
              setSelected={(selected: number[]) =>
                dispatch(setSelectedButtons(selected))
              }
              toggleSelected={(selected: number) =>
                dispatch(toggleSelectedButtons(selected))
              }
              handleDelete={() => {
                setToBeDeletedType(ConfiguratorType.Button);
                setConfirmationDialogOpen(true);
              }}
            />
          )}

          <CustomButton
            type="button"
            topMargin="1rem"
            handleClick={() => setAddButtonsDialogOpen(true)}
          >
            Dodaj guziki
          </CustomButton>
          <AddButtonsDialog
            isOpen={addButtonsDialogOpen}
            handleClose={() => setAddButtonsDialogOpen(false)}
            title="Dodaj nowe guziki"
            handleSubmit={handleAddButtons}
            cuts={cuts}
          />
        </DashboardContainer>
      )}

      <ConfirmationDialog
        isOpen={confirmationDialogOpen}
        closeDialog={() => setConfirmationDialogOpen(false)}
        acceptButton="Usuń"
        declineButton="Anuluj"
        title="Czy na pewno chcesz usunąć wybrane elementy?"
        text="Wybrane elementy zostaną usunięte. Tej operacji nie można cofnąć."
        handleAccept={() => handleDelete(toBeDeletedType)}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => dispatch(closeSnackbar())}
      >
        <Alert onClose={() => dispatch(closeSnackbar())} severity="success">
          {snackbarText}
        </Alert>
      </Snackbar>

      <LoadingDialog
        title="Dodawanie nowego elementu..."
        isOpen={isUploading}
        progress={uploadPercentage}
      />
    </>
  );
};
