import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { selectCurrentGuide } from '../redux/selectors';
import { fetchSingleGuide } from '../redux/thunk';

import {
  builder,
  BuilderContent,
  BuilderComponent,
  useIsPreviewing,
} from '@builder.io/react';

import { GuidesContainer, LoaderContainer } from '../styled';
import { useAppSelector, useAppDispatch } from 'hooks/redux';

import ClipLoader from 'react-spinners/ClipLoader';

builder.init(process.env.REACT_APP_BUILDER_API_KEY || '');

interface Params {
  handle: string;
}

function Guide() {
  const dispatch = useAppDispatch();
  const isPreviewing = useIsPreviewing();

  const { handle } = useParams<Params>();

  const guide = useAppSelector(selectCurrentGuide);

  useEffect(() => {
    dispatch(fetchSingleGuide({ builder, handle }));
  }, [dispatch, handle]);

  if (!guide && !isPreviewing) {
    return <p>Nie znaleziono poradnika</p>;
  }

  return (
    <GuidesContainer topMargin>
      <BuilderContent
        content={guide}
        options={{ includeRefs: true }}
        model="blog-article"
      >
        {(data, loading, fullContent) => (
          <>
            {loading ? (
              <LoaderContainer>
                <ClipLoader loading size={32} color="#DABC60" />
              </LoaderContainer>
            ) : (
              <BuilderComponent
                content={fullContent}
                options={{ includeRefs: true }}
              />
            )}
          </>
        )}
      </BuilderContent>
    </GuidesContainer>
  );
}

export default Guide;
