import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import { LoaderContainer } from './styled';

interface LoaderProps {
  plainBackground?: boolean;
}

const Loader: React.FC<LoaderProps> = ({ plainBackground }) => {
  return (
    <LoaderContainer plainBackground={plainBackground}>
      <ClipLoader loading size={64} color="#DABC60" />
    </LoaderContainer>
  );
};

export default Loader;
