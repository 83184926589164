import { createAsyncThunk } from '@reduxjs/toolkit';
import { Product } from 'api/models/Product';
import { axiosInstance } from 'axiosConfig';

export const fetchProducts = createAsyncThunk<
  { products: Product[]; totalNumber: number },
  { page: number }
>('outlet/fetchProducts', async ({ page }) => {
  const response = await axiosInstance.get(
    `products/outlet?page=${page}&pageSize=6`,
  );

  const products = response.data.results as Product[];
  const totalNumber = response.data.total;

  return { products, totalNumber };
});
