import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUploadPercentage } from 'admin/containers/GalleryContainer/redux/gallerySlice';

import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { ProductImage } from 'api/models/Product';

import { useStyles, ImagesContainer, Image } from './styled';
import { Title } from '../../containers/ProductsContainer/EditProduct/styled';

interface ImageUploaderProps {
  fetchedThumbnails?: { id: number; url: string }[];
  setImages: (files: FileList) => void;
  title: string;
  multiple?: boolean;
}

export const ImageUploader: React.FC<ImageUploaderProps> = ({
  title,
  setImages,
  fetchedThumbnails = [],
  multiple = true,
}) => {
  const classes = useStyles();

  const [thumbnails, setThumbnails] =
    useState<ProductImage[]>(fetchedThumbnails);
  const galleryUploadPercentage = useSelector(selectUploadPercentage);

  useEffect(() => {
    if (galleryUploadPercentage < 100) return;

    setThumbnails([]);
  }, [galleryUploadPercentage]);

  const readImgUrl = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const url = e.target ? String(e.target.result) : '';
      const randomId = Math.random() * 10000;
      setThumbnails((oldThumbnails) => [
        ...oldThumbnails,
        { id: randomId, url },
      ]);
    };
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files) return;

    setThumbnails([]);
    for (let i = 0; i < files.length; i += 1) {
      readImgUrl(files[i]);
    }

    setImages(files);
  };

  return (
    <>
      <Title>{title}</Title>

      <input
        accept="image/jpeg, image/png, image/webp"
        className={classes.input}
        id="image-file"
        multiple={multiple}
        name="product-images"
        type="file"
        onChange={handleImageChange}
      />
      <label htmlFor="image-file">
        <Button
          variant="contained"
          component="span"
          className={classes.uploadButton}
          startIcon={<CloudUploadIcon />}
        >
          Dodaj
        </Button>
      </label>

      <ImagesContainer>
        {thumbnails.map((thumbnail) => (
          <Image key={thumbnail.id} src={thumbnail.url} alt="Uploaded" />
        ))}
      </ImagesContainer>
    </>
  );
};
