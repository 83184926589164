import { createAsyncThunk } from '@reduxjs/toolkit';
import { Coupon } from 'api/models/Coupon';
import { axiosInstance } from 'axiosConfig';

export const fetchCoupons = createAsyncThunk(
  'admin/coupons/fetchCoupons',
  async () => {
    const response = await axiosInstance.get<Coupon[]>('coupons');
    return response.data;
  },
);

export const deleteCoupons = createAsyncThunk<
  number[],
  number[],
  { rejectValue: string }
>('admin/coupons/deleteCoupons', async (ids, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.delete<number[]>('coupons', {
      data: {
        couponIds: ids,
      },
    });

    return response.data;
  } catch (error: any) {
    if (!error.message) throw error;

    return rejectWithValue(error.message);
  }
});

export const addCoupon = createAsyncThunk<
  Coupon,
  { name: string; discount: number },
  { rejectValue: string }
>(
  'admin/coupons/addCoupon',
  async ({ name, discount }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<Coupon>('coupons', {
        name,
        discount,
      });

      return response.data;
    } catch (error: any) {
      if (!error.message) throw error;

      return rejectWithValue(error.message);
    }
  },
);
