import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { device } from 'theme/theme';

interface ContainerProps {
  topMargin?: boolean;
  withFooter?: boolean;
}

export const Container = styled.div<ContainerProps>`
  max-width: 170rem;
  margin: 0 auto;
  margin-top: ${(props) => (props.topMargin ? '12rem' : 0)};
  padding: 0 4rem;
  min-height: ${(props) =>
    props.withFooter ? 'calc(100vh - 7.9rem - 38.2rem - 4rem - 10rem)' : ''};
`;

export const DashboardContainer = styled.div`
  max-width: 150rem;
  margin: 0 auto;
`;

export const OutlinedButton = styled.button`
  outline: none;
  text-decoration: none;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.colors.primary};
  background-color: transparent;
  font-size: ${(props) => props.theme.fontSizes.s};
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:not(.disabled):not(:disabled):hover {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.accent};
  }

  &.disabled {
    cursor: not-allowed;
    border-color: ${(props) => props.theme.colors.lightGray};
    color: ${(props) => props.theme.colors.lightGray};
  }
`;

export const Button = styled(OutlinedButton)`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.accent};

  &:hover {
    opacity: 0.8;
  }

  &.disabled {
    background-color: ${(props) => props.theme.colors.lightGray};
    border-color: ${(props) => props.theme.colors.lightGray};
    color: ${(props) => props.theme.colors.gray};
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

export const Size = styled.p`
  display: inline-block;
  padding: 1rem 1.5rem;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  border-radius: 0.5rem;
  margin: 0 1rem 1rem 0;
  font-size: 1.4rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  user-select: none;

  &:first-of-type {
    margin-left: 0;
  }

  &.disabled {
    cursor: not-allowed;
    color: ${(props) => props.theme.colors.gray};
    font-weight: normal;
    opacity: 0.5;
  }

  &:not(.disabled):hover {
    border-color: ${(props) => props.theme.colors.primary};
  }

  &.is-active {
    border-color: ${(props) => props.theme.colors.primary};
  }
`;

interface FormProps {
  center?: boolean;
}

export const Form = styled.form<FormProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.center ? 'center' : 'flex-start')};
`;

export const InputContainer = styled.div`
  margin: 0.5rem 0;
  width: 100%;
`;

interface StyledInputProps {
  error?: boolean;
  withLabel?: boolean;
}

export const StyledInput = styled.input<StyledInputProps>`
  display: block;
  padding: 1.5rem 2rem;
  margin: ${(props) =>
    props.withLabel && !props.error ? '0.5rem 0 1.5rem 0' : '0.5rem 0 0 0'};
  border-radius: 0.5rem;
  border: 1px solid
    ${(props) =>
      props.error ? props.theme.colors.danger : props.theme.colors.lightGray};
  color: ${(props) =>
    props.error ? props.theme.colors.danger : props.theme.colors.primary};
  font-size: ${(props) => props.theme.fontSizes.s};
  font-family: inherit;
  width: 100%;
  transition: all 0.3s ease-in-out;

  &:focus {
    border-color: ${(props) =>
      props.error ? props.theme.colors.danger : props.theme.colors.primary};
    outline: none;
  }
`;

export const StyledSelect = styled.select`
  display: block;
  padding: 1.5rem 2rem;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.fontSizes.s};
  font-family: inherit;
  transition: all 0.3s ease-in-out;
  --webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  width: calc(100% + 1.8rem);

  &:focus {
    border-color: ${(props) => props.theme.colors.primary};
    outline: none;
  }

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const SelectContainer = styled(InputContainer)`
  position: relative;
  width: fit-content;

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const SelectArrow = styled.div`
  font-size: 1.8rem;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  @media ${device.mobile} {
    right: 1rem;
  }
`;

export const Label = styled.label`
  font-size: ${(props) => props.theme.fontSizes.xs};
  color: ${(props) => props.theme.colors.primary};
`;

interface StyledTextareaProps {
  error?: boolean;
}

export const StyledTextarea = styled.textarea<StyledTextareaProps>`
  display: block;
  padding: 1.5rem 2rem;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid
    ${(props) =>
      props.error ? props.theme.colors.danger : props.theme.colors.lightGray};
  color: ${(props) =>
    props.error ? props.theme.colors.danger : props.theme.colors.primary};
  font-size: ${(props) => props.theme.fontSizes.s};
  font-family: inherit;
  transition: all 0.3s ease-in-out;
  width: 100%;
  resize: none;

  &:focus {
    border-color: ${(props) =>
      props.error ? props.theme.colors.danger : props.theme.colors.primary};
    outline: none;
  }
`;

interface HelperTextProps {
  error?: boolean;
}

export const HelperText = styled.p<HelperTextProps>`
  width: 100%;
  font-size: ${(props) => (props.error ? '1.2rem' : props.theme.fontSizes.xs)};
  color: ${(props) =>
    props.error ? props.theme.colors.danger : props.theme.colors.gray};
  margin: ${(props) => (props.error ? '0.5rem 0 0 0' : '1rem 0 2rem 0')};
  text-align: left;
`;

export const HelperTextLink = styled(Link)`
  font-weight: bold;
  color: ${(props) => props.theme.colors.primary};
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${(props) => props.theme.colors.accent};
  }
`;

export const ErrorContainer = styled.div`
  margin: 1rem 0;
  padding: 2rem;
  background-color: ${(props) => props.theme.colors.danger};
  width: 100%;
  border-radius: 0.5rem;
`;

export const ErrorMessage = styled.p`
  color: ${(props) => props.theme.colors.light};
  font-size: ${(props) => props.theme.fontSizes.s};
`;
