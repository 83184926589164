import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      '&.Mui-checked': {
        color: theme.palette.blue.main,
      },
    },
  }),
);
