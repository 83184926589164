import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VoucherType } from 'api/models/VoucherType';
import { RootState } from 'store';
import { fetchVoucherTypes, addVoucherType, deleteVoucherTypes } from './thunk';

interface VoucherTypesState {
  isLoading: boolean;
  error: string | undefined;
  voucherTypes: VoucherType[];
  selectedTypes: number[];
  snackbarOpen: boolean;
  snackbarText: string;
}

const initialState: VoucherTypesState = {
  isLoading: false,
  error: undefined,
  voucherTypes: [],
  selectedTypes: [],
  snackbarOpen: false,
  snackbarText: '',
};

const voucherTypesSlice = createSlice({
  name: 'voucherTypes',
  initialState,
  reducers: {
    setSelected: (state, action: PayloadAction<number[]>) => {
      state.selectedTypes = action.payload;
    },
    toggleSelected: (state, action: PayloadAction<number>) => {
      if (state.selectedTypes.includes(action.payload)) {
        state.selectedTypes = state.selectedTypes.filter(
          (selected) => selected !== action.payload,
        );
      } else {
        state.selectedTypes.push(action.payload);
      }
    },
    closeSnackbar: (state) => {
      state.snackbarOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVoucherTypes.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(fetchVoucherTypes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.voucherTypes = action.payload;
    });
    builder.addCase(fetchVoucherTypes.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(addVoucherType.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(addVoucherType.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.voucherTypes.push(payload);
      state.snackbarOpen = true;
      state.snackbarText = 'Pomyślnie dodano rodzaj voucheru';
    });
    builder.addCase(addVoucherType.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    builder.addCase(deleteVoucherTypes.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(deleteVoucherTypes.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.voucherTypes = state.voucherTypes.filter(
        (type) => !payload.includes(type.id),
      );
      state.selectedTypes = [];

      state.snackbarOpen = true;
      state.snackbarText = 'Pomyślnie usunięto rodzaje voucherów';
    });
    builder.addCase(deleteVoucherTypes.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });
  },
});

export const { setSelected, toggleSelected, closeSnackbar } =
  voucherTypesSlice.actions;

export const selectVoucherTypesState = (state: RootState) => state.voucherTypes;

export default voucherTypesSlice.reducer;
