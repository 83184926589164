import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  fetchOrders,
  selectIsLoading,
  selectError,
  selectOrders,
} from './userAccountSlice';
import Loader from 'components/Loader';
import { OrdersTable } from 'components/OrdersTable';

import { AccountContainer, Title, Text, BackButton } from './styled';

const UserAccount = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const isLoading = useSelector(selectIsLoading);
  const orders = useSelector(selectOrders);
  const error = useSelector(selectError);

  useEffect(() => {
    dispatch(fetchOrders());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!error) return;

    alert(`There was an error: ${error}`);
  }, [error]);

  return (
    <>
      <AccountContainer topMargin withFooter>
        <Title>{t('userAccount.title')}</Title>
        {orders.length > 0 ? (
          <OrdersTable orders={orders} />
        ) : (
          <Text>{t('userAccount.noOrders')}</Text>
        )}

        <BackButton onClick={() => history.push('/products')}>
          {t('userAccount.backButton')}
        </BackButton>
      </AccountContainer>

      {isLoading && <Loader />}
    </>
  );
};

export default UserAccount;
