import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      '&.Mui-checked': {
        color: theme.palette.blue.main,
      },
    },
    bestsellerIconActive: {
      color: theme.palette.secondary.main,
      cursor: 'pointer',

      '&:hover': {
        color: theme.palette.grey[400],
      },
    },
    bestSellerIcon: {
      color: theme.palette.grey[400],
      cursor: 'pointer',

      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
  }),
);
