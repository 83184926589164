import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from 'axiosConfig';
import { GalleryImage } from 'api/models/GalleryImage';
import { setUploadPercentage } from './gallerySlice';

export const fetchGalleryImages = createAsyncThunk(
  'admin/gallery/fetchGalleryImages',
  async () => {
    const response = await axiosInstance.get<{
      images: GalleryImage[];
      total: number;
    }>('content/gallery?page=1&pageSize=1000');

    const { images, total } = response.data;

    return { images, total };
  },
);

export const deleteImages = createAsyncThunk<
  number[],
  number[],
  { rejectValue: string }
>('admin/gallery/deleteImages', async (ids, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.delete<number[]>('content/gallery', {
      data: { ids },
    });

    return response.data;
  } catch (error: any) {
    if (!error.message) throw error;

    return rejectWithValue(error.message);
  }
});

export const uploadImages = createAsyncThunk<
  GalleryImage[],
  FileList,
  { rejectValue: string }
>(
  'admin/gallery/uploadImages',
  async (images, { rejectWithValue, dispatch }) => {
    try {
      const formData = new FormData();

      for (let i = 0; i < images.length; i += 1) {
        formData.append('gallery-images[]', images[i]);
      }

      const response = await axiosInstance.post<GalleryImage[]>(
        'content/gallery',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent: ProgressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            dispatch(setUploadPercentage(percentCompleted));
          },
        },
      );

      return response.data;
    } catch (error: any) {
      if (!error.message) throw error;

      return rejectWithValue(error.message);
    }
  },
);
