import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkCouponCode,
  selectIsLoading,
  selectError,
  selectCoupon,
} from '../cartSlice';
import { Link } from 'react-router-dom';

import {
  Form,
  StyledInput,
  SubmitButton,
  HelperText,
  InputContainer,
} from './styled';
import { OutlinedButton } from 'theme/global';
import Loader from 'components/Loader';

const CouponForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [coupon, setCoupon] = useState<string>('');
  const [wrongCode, setWrongCode] = useState<boolean>(false);
  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);
  const activeCoupon = useSelector(selectCoupon);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!coupon) return;

    dispatch(checkCouponCode(coupon));
  };

  const handleChange = (e: any) => {
    setCoupon(e.target.value);
    if (wrongCode) {
      setWrongCode(false);
    }
  };

  useEffect(() => {
    if (!error) return;

    setWrongCode(true);
  }, [error]);

  useEffect(() => {
    if (!activeCoupon) return;

    setCoupon('');
  }, [activeCoupon]);

  return (
    <Form onSubmit={handleSubmit}>
      <InputContainer>
        <StyledInput
          type="text"
          placeholder={t('cart.couponPlaceholder')}
          value={coupon}
          onChange={handleChange}
          error={wrongCode}
        />

        {wrongCode && <HelperText>{t('cart.wrongCode')}</HelperText>}
      </InputContainer>

      <SubmitButton type="submit">{t('cart.submitCoupon')}</SubmitButton>
      <Link to="/checkout">
        <OutlinedButton type="button">{t('cart.makeOrder')}</OutlinedButton>
      </Link>

      {isLoading && <Loader />}
    </Form>
  );
};

export default CouponForm;
