import React from 'react';
import ReactPaginate from 'react-paginate';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

interface PaginationProps {
  currentPage: number;
  pageSize: number;
  totalResults: number;
  changePage: (page: number) => void;
}

const Pagination = ({
  currentPage,
  pageSize,
  totalResults,
  changePage,
}: PaginationProps) => {
  const pageCount = Math.ceil(totalResults / pageSize);

  return (
    <ReactPaginate
      pageCount={pageCount}
      pageRangeDisplayed={1}
      marginPagesDisplayed={1}
      forcePage={currentPage - 1}
      onPageChange={(newPage) => changePage(newPage.selected + 1)}
      breakLabel="..."
      previousLabel={<MdKeyboardArrowLeft />}
      nextLabel={<MdKeyboardArrowRight />}
      containerClassName="pagination"
      previousLinkClassName="pagination__link"
      nextLinkClassName="pagination__link"
      disabledClassName="pagination__link--disabled"
      activeClassName="pagination__link--active"
    />
  );
};

export default Pagination;
