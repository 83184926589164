import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCart,
  removeFromCart,
  CartProduct,
  selectCoupon,
  removeCouponCode,
} from './cartSlice';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { selectOrder } from 'containers/Checkout/checkoutSlice';

import CouponForm from './CouponForm';
import { CustomProduct, isCustomProduct, Product } from 'api/models/Product';

import {
  CartContainer,
  Header,
  Title,
  CartItem,
  ItemContent,
  ItemName,
  ItemPrice,
  HelpText,
  CloseIcon,
  Divider,
  ItemImage,
  TotalAmount,
  TotalPrice,
  SummaryContainer,
  CouponInfo,
  CouponContainer,
  RemoveCouponIcon,
  ItemImageContainer,
} from './styled';
import { Button } from 'theme/global';
import Logo from 'assets/logo/logo.png';

const Cart = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const cart = useSelector(selectCart);
  const appliedCoupon = useSelector(selectCoupon);
  const order = useSelector(selectOrder);

  useEffect(() => {
    if (cart.length === 0) {
      history.push('/');
    }
  }, [history, cart]);

  useEffect(() => {
    if (!order) return;

    history.push('/payment');
  }, [order, history]);

  const calculatePrice = (item: CartProduct) => {
    let productPrice = 0;

    if (!isCustomProduct(item.product)) {
      if (!item.product.isVoucher) {
        productPrice =
          (item.product.isOnSale
            ? item.product.discountPrice
            : item.product.price) || 0;
      } else {
        const choosedVoucherType = item.product.voucherTypes.filter(
          (type) => type.name === item.size,
        );

        productPrice = choosedVoucherType[0].price;
      }
    }

    return (
      (isCustomProduct(item.product) ? item.product.price : productPrice) *
      item.quantity
    );
  };

  const handleRemoveClick = (id: number) => {
    dispatch(removeFromCart(id));
  };

  const goToProduct = (product: Product | CustomProduct) => {
    if (isCustomProduct(product)) return;

    history.push(`/products/${product.id}`);
  };

  const calculateTotal = () => {
    let total = 0;
    cart.forEach((cartItem) => {
      total += calculatePrice(cartItem);
    });

    const discount = appliedCoupon ? 1 - appliedCoupon.discount / 100 : 1;
    return (total * discount).toFixed(2);
  };

  const renderedCartItems =
    cart &&
    cart.map((cartItem) => (
      <CartItem key={cartItem.product.id || Math.random() * 100}>
        <ItemImageContainer>
          <ItemImage
            src={
              isCustomProduct(cartItem.product)
                ? Logo
                : cartItem.product.images[0].url
            }
            onClick={() => goToProduct(cartItem.product)}
          />
        </ItemImageContainer>

        <ItemContent>
          <div>
            <ItemName onClick={() => goToProduct(cartItem.product)}>
              {cartItem.product.name}
            </ItemName>
            <HelpText>
              {t('product.size')}: {cartItem.size}
            </HelpText>
            <HelpText>
              {t('product.quantity')}: {cartItem.quantity}
            </HelpText>
          </div>

          <ItemPrice>{calculatePrice(cartItem).toFixed(2)} zł</ItemPrice>
        </ItemContent>

        <CloseIcon onClick={() => handleRemoveClick(cartItem.product.id || 0)}>
          <AiFillCloseCircle />
        </CloseIcon>
      </CartItem>
    ));

  return (
    <CartContainer>
      <Header>
        <Title>{t('cart.title')}</Title>

        <Link to="products">
          <Button>{t('cart.continueButton')}</Button>
        </Link>
      </Header>
      <Divider />

      {renderedCartItems}

      <SummaryContainer>
        <TotalAmount>
          {t('cart.total')}: <TotalPrice>{calculateTotal()} zł</TotalPrice>
        </TotalAmount>

        {appliedCoupon && (
          <CouponContainer>
            <CouponInfo>
              {t('cart.couponPlaceholder')}:{' '}
              <TotalPrice>
                {appliedCoupon.name} - {appliedCoupon.discount}%
              </TotalPrice>
            </CouponInfo>

            <RemoveCouponIcon onClick={() => dispatch(removeCouponCode())}>
              <AiFillCloseCircle />
            </RemoveCouponIcon>
          </CouponContainer>
        )}
      </SummaryContainer>

      <CouponForm />
    </CartContainer>
  );
};

export default Cart;
