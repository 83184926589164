import styled from 'styled-components';
import { device } from 'theme/theme';

interface CarouselProps {
  fullWidth?: boolean;
}

export const CarouselContainer = styled.div<CarouselProps>`
  max-width: ${(props) => (props.fullWidth ? '100%' : '50%')};

  @media ${device.mobile} {
    max-width: 100%;
  }
`;

interface IndicatorProps {
  isActive: boolean;
}

export const Indicator = styled.div<IndicatorProps>`
  width: 1.6rem;
  height: 1.6rem;
  margin: 0 1rem;
  border-radius: 50%;
  background-color: ${(props) =>
    props.isActive ? props.theme.colors.accent : props.theme.colors.primary};
  opacity: ${(props) => (props.isActive ? 1 : 0.5)};
  transition: all 0.3s ease-in-out;
  display: inline-block;
`;

export const CarouselImage = styled.img<CarouselProps>`
  max-height: ${(props) => (props.fullWidth ? '40rem' : '60rem')};
  width: 100%;
  object-fit: cover;
  border-radius: 1rem;

  ${(props) =>
    props.fullWidth &&
    `
    @media ${device.mobile} {
      max-height: 30rem;
    }    
  `}
`;
