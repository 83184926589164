import styled from 'styled-components';
import { Container } from 'theme/global';
import { device } from 'theme/theme';

export const ProductsContainer = styled(Container)`
  margin-top: 15rem;
  display: grid;
  gap: 10rem 0;
  grid-template-columns: repeat(
    auto-fit,
    minmax(${(props) => props.theme.productImage.width}, 1fr)
  );
  /* grid-template-rows: minmax(55rem, 60rem); */
  justify-items: center;

  @media ${device.smallScreen} {
    grid-template-columns: repeat(
      auto-fit,
      minmax(${(props) => props.theme.productImage.smallScreenWidth}, 1fr)
    );
  }

  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

interface ProductProps {
  visible?: boolean;
}

export const Product = styled.div<ProductProps>`
  display: ${(props) => (props.visible ? '' : 'none')};

  @media ${device.smallScreen} {
    margin: 0 1rem;
  }

  @media ${device.mobile} {
    margin-bottom: 2rem;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const ImagesContainer = styled.div`
  position: relative;
  text-align: center;
  cursor: pointer;
`;

export const ProductImage = styled.img`
  max-width: ${(props) => props.theme.productImage.width};
  max-height: ${(props) => props.theme.productImage.height};
  width: auto;
  height: auto;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  border-radius: 0.3rem;

  &:last-of-type {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    &:hover {
      opacity: 0;
    }
  }

  @media ${device.smallScreen} {
    max-width: ${(props) => props.theme.productImage.smallScreenWidth};
    max-height: ${(props) => props.theme.productImage.smallScreenHeight};
  }

  @media ${device.mobile} {
    max-width: ${(props) => props.theme.productImage.mobileWidth};
    max-height: ${(props) => props.theme.productImage.mobileHeight};
  }
`;

interface LabelContainerProps {
  voucher?: boolean;
}

export const LabelContainer = styled.div<LabelContainerProps>`
  display: flex;
  justify-content: ${(props) => (props.voucher ? 'center' : 'space-between')};
  align-items: center;
  max-width: ${(props) => props.theme.productImage.width};
  background-color: ${(props) => props.theme.colors.light};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 1rem 1.5rem;
  margin-top: 1.5rem;
  border-radius: 0.3rem;

  @media ${device.mobile} {
    max-width: ${(props) => props.theme.productImage.mobileWidth};
  }
`;

export const Label = styled.p`
  font-size: ${(props) => props.theme.fontSizes.m};
  color: ${(props) => props.theme.colors.primary};
  margin-right: 1rem;

  @media ${device.smallScreen} {
    font-size: ${(props) => props.theme.fontSizes.s};
  }

  @media ${device.mobile} {
    font-size: ${(props) => props.theme.fontSizes.xs};
  }
`;

interface PriceProps {
  sale?: boolean;
}

export const Price = styled(Label)<PriceProps>`
  text-decoration: ${(props) => (props.sale ? 'line-through' : 'none')};
  font-size: ${(props) => (props.sale ? '1.2rem' : '1.8rem')};

  @media ${device.smallScreen} {
    font-size: ${(props) => props.theme.fontSizes.s};
  }

  @media ${device.mobile} {
    font-size: ${(props) => props.theme.fontSizes.xs};
  }
`;

export const DiscountPriceContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DiscountPrice = styled(Price)`
  color: ${(props) => props.theme.colors.success};
  margin-left: 0.5rem;
`;

export const PaginationContainer = styled(Container)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 5rem;

  @media ${device.mobile} {
    justify-content: center;
  }
`;

export const NoProductsText = styled.h1`
  position: absolute;
  left: 50%;
  top: calc(50% - 8.4rem);
  transform: translate(-50%, -50%);
  font-size: ${(props) => props.theme.fontSizes.m};
  color: ${(props) => props.theme.colors.primary};
  font-weight: 500;
`;
