import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderById } from '../redux/thunk';
import { selectOrdersState } from '../redux/ordersSlice';
import { OrderProduct, Order, Shipping } from 'api/models/Order';
import { CustomProduct, ProductType } from 'api/models/Product';
import { useTranslation } from 'react-i18next';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { Loader } from 'admin/components/Loader';
import { DashboardContainer } from 'theme/global';
import { ErrorMessage } from 'admin/components/ErrorMessage';
import { useStyles } from './styled';
import { CustomButton } from 'admin/components/CustomButton';

interface ParamsProps {
  id?: string;
}

export const OrderDetails: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<ParamsProps>();

  const { isLoading, error, currentOrder } = useSelector(selectOrdersState);

  useEffect(() => {
    if (!id) return;

    dispatch(fetchOrderById(Number(id)));
  }, [dispatch, id]);

  return (
    <>
      {isLoading || !currentOrder ? (
        <Loader />
      ) : (
        <DashboardContainer>
          {error && <ErrorMessage>{error}</ErrorMessage>}

          <Paper className={classes.paperWithMargin}>
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Szczegóły zamówienia
            </Typography>
            <OrderDetailsTable row={currentOrder} />
          </Paper>

          {currentOrder.products && currentOrder.products.length > 0 && (
            <Paper className={classes.paperWithMargin}>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Zamówione produkty
              </Typography>
              <ProductsTable rows={currentOrder.products} />
            </Paper>
          )}

          {currentOrder.customProducts &&
            currentOrder.customProducts.length > 0 && (
              <Paper>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  Zamówione produkty z kreatora
                </Typography>
                <CustomProductsTable rows={currentOrder.customProducts} />
              </Paper>
            )}

          <CustomButton topMargin="4rem" handleClick={() => history.goBack()}>
            Wstecz
          </CustomButton>
        </DashboardContainer>
      )}
    </>
  );
};

interface ProductsTableProps {
  rows: OrderProduct[];
}

export function ProductsTable({ rows }: ProductsTableProps) {
  const getType = (type: ProductType | undefined): string => {
    switch (type) {
      case ProductType.Male:
        return 'Kamizelka męska';
      case ProductType.Female:
        return 'Kamizelka damska';
      case ProductType.Kid:
        return 'Kamizelka dziecięca';
      case ProductType.Voucher:
        return 'Voucher';
      case ProductType.TshirtMale:
        return 'Koszulka męska';
      case ProductType.TshirtFemale:
        return 'Koszulka damska';

      default:
        return '';
    }
  };

  const getPrice = (price?: number, discountPrice?: number) => {
    if (!price || price === 0) return '-';

    if (discountPrice && discountPrice < price) return `${discountPrice} zł`;
    return `${price} zł`;
  };

  return (
    <TableContainer>
      <Table aria-label="Products table">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Nazwa</TableCell>
            <TableCell>Typ</TableCell>
            <TableCell>Rozmiar</TableCell>
            <TableCell>Ilość</TableCell>
            <TableCell>Cena</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{getType(row.type)}</TableCell>
              <TableCell>{row.size}</TableCell>
              <TableCell>{row.quantity}</TableCell>
              <TableCell>{getPrice(row.price, row.discountPrice)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

interface CustomProductsTableProps {
  rows: CustomProduct[];
}

export function CustomProductsTable({ rows }: CustomProductsTableProps) {
  const getType = (type: ProductType): string => {
    switch (type) {
      case ProductType.Male:
        return 'Kamizelka męska';
      case ProductType.Female:
        return 'Kamizelka damska';
      case ProductType.Kid:
        return 'Kamizelka dziecięca';
      case ProductType.Voucher:
        return 'Voucher';
      case ProductType.TshirtMale:
        return 'Koszulka męska';
      case ProductType.TshirtFemale:
        return 'Koszulka damska';

      default:
        return '';
    }
  };

  return (
    <TableContainer>
      <Table aria-label="Custom products table">
        <TableHead>
          <TableRow>
            <TableCell>Typ</TableCell>
            <TableCell>Krój</TableCell>
            <TableCell>Materiał</TableCell>
            <TableCell>Podszewka</TableCell>
            <TableCell>Guziki</TableCell>
            <TableCell>Materiał na tyle</TableCell>
            <TableCell>Rozmiar</TableCell>
            <TableCell>Cena</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => (
            <TableRow key={idx}>
              <TableCell component="th" scope="row">
                {getType(row.type)}
              </TableCell>
              <TableCell>{row.cut}</TableCell>
              <TableCell>{row.material}</TableCell>
              <TableCell>{row.fleece}</TableCell>
              <TableCell>{row.buttons}</TableCell>
              <TableCell>{row.back}</TableCell>
              <TableCell>{row.size}</TableCell>
              <TableCell>{row.price} zł</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

interface OrderDetailsProps {
  row: Order;
}

export function OrderDetailsTable({ row }: OrderDetailsProps) {
  const { t } = useTranslation();

  const getShipping = (shipping: Shipping): string => {
    switch (shipping) {
      case Shipping.Local:
        return t('checkout.shipping1');

      case Shipping.Worldwide:
        return t('checkout.shipping2');

      case Shipping.Self:
        return t('checkout.shipping3');

      default:
        return t('checkout.freeShipping');
    }
  };
  return (
    <TableContainer>
      <Table aria-label="Order details table">
        <TableHead>
          <TableRow>
            <TableCell>Numer zamówienia</TableCell>
            <TableCell>Data zamówienia</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Imię i nazwisko</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Numer telefonu</TableCell>
            <TableCell>Adres</TableCell>
            <TableCell>Metoda wysyłki</TableCell>
            <TableCell>Dodatakowe informacje</TableCell>
            <TableCell>Kwota</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              {row.id}
            </TableCell>
            <TableCell>{row.created_at}</TableCell>
            <TableCell>{row.status}</TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.email}</TableCell>
            <TableCell>{row.phone_number}</TableCell>
            <TableCell>
              {row.address}, {row.post_code}
              {row.city ? `, ${row.city}` : ''}
            </TableCell>
            <TableCell>{getShipping(row.shipping)}</TableCell>
            <TableCell>{row.additional_info}</TableCell>
            <TableCell>{row.finalPrice?.toFixed(2)} zł</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
