import styled from 'styled-components';

export const ImagesContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
`;

export const Image = styled.img`
  max-width: 25rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`;

export const SelectedIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.2rem;
  color: ${(props) => props.theme.colors.blue};
  opacity: 0;
  transition: all 0.1s ease-in-out;
  z-index: -1;
`;

export const ImageWrapper = styled.div`
  position: relative;
  cursor: pointer;

  &.selected {
    ${SelectedIcon} {
      opacity: 1;
      z-index: 5;
    }

    ${Image} {
      opacity: 0.6;
    }
  }
`;
