import React, { useEffect, useState } from 'react';

import { DialogLayout } from '../DialogLayout';
import { UploadButton } from '../DialogLayout/UploadButton';
import Checkbox from '@material-ui/core/Checkbox';

import {
  useStyles,
  Row,
  ThumbnailContainer,
  Thumbnail,
  Label,
} from '../DialogLayout/styled';
import { Cut, Material } from 'api/models/CreatorModels';

interface AddMaterialDialogProps {
  title: string;
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (
    iconImage: File,
    cutImages: File[],
    backImage: File,
    beltImage: File,
    collarImage: File,
    isFemale: boolean,
    backOversizeImage?: File,
    collarOversizeImage?: File,
  ) => void;
  handleEdit: (
    id: number,
    isFemale: boolean,
    updatedCutImages?: { cutName: number; position: number }[],
    iconImage?: File,
    cutImages?: File[],
    backImage?: File,
    beltImage?: File,
    collarImage?: File,
    backOversizeImage?: File,
    collarOversizeImage?: File,
  ) => void;
  cuts: Cut[];
  selectedMaterial?: Material;
}

export const AddMaterialDialog: React.FC<AddMaterialDialogProps> = ({
  title,
  isOpen,
  handleClose,
  handleSubmit,
  handleEdit,
  cuts,
  selectedMaterial,
}) => {
  const classes = useStyles();

  const [isFemale, setIsFemale] = useState(false);
  const [iconImage, setIconImage] = useState<File>();
  const [iconUrl, setIconUrl] = useState<string>();
  const [backImage, setBackImage] = useState<File>();
  const [backUrl, setBackUrl] = useState<string>();
  const [backOversizeImage, setBackOversizeImage] = useState<File>();
  const [backOversizeUrl, setBackOversizeUrl] = useState<string | null>(null);
  const [beltImage, setBeltImage] = useState<File>();
  const [beltUrl, setBeltUrl] = useState<string>();
  const [collarImage, setCollarImage] = useState<File>();
  const [collarUrl, setCollarUrl] = useState<string>();
  const [collarOversizeImage, setCollarOversizeImage] = useState<File>();
  const [collarOversizeUrl, setCollarOversizeUrl] = useState<string | null>(
    null,
  );
  const [frontImages, setFrontImages] = useState<File[]>([]);
  const [frontImageUrls, setFrontImageUrls] = useState<string[]>([]);
  const [frontFemaleImages, setFrontFemaleImages] = useState<File[]>([]);
  const [frontFemaleUrls, setFrontFemaleUrls] = useState<string[]>([]);
  const [updatedCutImages, setUpdatedCutImages] = useState<
    { cutName: number; position: number }[]
  >([]);

  // set default values
  useEffect(() => {
    if (!selectedMaterial) return;

    setIsFemale(Boolean(selectedMaterial.female));
    setIconUrl(selectedMaterial.icon_url);
    setBackUrl(selectedMaterial.back_image_url);
    setBeltUrl(selectedMaterial.single_addon_url);
    setCollarUrl(selectedMaterial.addon_with_collar_url);
    setBackOversizeUrl(selectedMaterial.oversize_back_image_url);
    setCollarOversizeUrl(selectedMaterial.oversize_collar_image_url);

    if (selectedMaterial.female) {
      const femaleCuts = cuts.filter((cut) => cut.female);
      const urls: string[] = [];

      // eslint-disable-next-line
      for (const cut of femaleCuts) {
        urls.push(
          `/images/creator/female/${cut.name}/${selectedMaterial.name}.png`,
        );
      }

      setFrontFemaleUrls(urls);
    } else {
      const maleCuts = cuts.filter((cut) => !cut.female);
      const urls: string[] = [];

      // eslint-disable-next-line
      for (const cut of maleCuts) {
        urls.push(`/images/creator/${cut.id}/${selectedMaterial.name}.png`);
      }

      setFrontImageUrls(urls);
    }
    // eslint-disable-next-line
  }, [selectedMaterial]);

  const handleIsFemaleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsFemale(event.target.checked);
  };

  const addMaterial = () => {
    if (!iconImage || !backImage || !beltImage || !collarImage) {
      alert('Dodaj wszystkie zdjęcia');
      return;
    }
    if (isFemale && frontFemaleImages.length === 0) {
      alert('Dodaj wszystkie zdjęcia kroi');
      return;
    }
    if (!isFemale && frontImages.length === 0) {
      alert('Dodaj wszystkie zdjęcia kroi');
      return;
    }

    const frontImagesToAdd = isFemale ? frontFemaleImages : frontImages;

    handleSubmit(
      iconImage,
      frontImagesToAdd,
      backImage,
      beltImage,
      collarImage,
      isFemale,
      backOversizeImage,
      collarOversizeImage,
    );
  };

  const editMaterial = () => {
    if (!selectedMaterial?.id) return;

    const frontImagesToAdd = isFemale ? frontFemaleImages : frontImages;

    handleEdit(
      selectedMaterial.id,
      isFemale,
      updatedCutImages,
      iconImage,
      frontImagesToAdd,
      backImage,
      beltImage,
      collarImage,
      backOversizeImage,
      collarOversizeImage,
    );
  };

  const femaleCuts = cuts.filter((cut) => cut.female === 1);
  const maleCuts = cuts.filter((cut) => cut.female === 0);

  return (
    <DialogLayout
      isOpen={isOpen}
      title={title}
      handleClose={handleClose}
      handleSubmit={selectedMaterial ? editMaterial : addMaterial}
    >
      <Row withoutBottomBorder>
        <Label>Tkanina damska</Label>
        <Checkbox
          checked={isFemale}
          onChange={handleIsFemaleChange}
          className={classes.checkbox}
        />
      </Row>

      <Row>
        <ThumbnailContainer>
          <Thumbnail src={iconUrl} />
          <Label>Ikona</Label>
        </ThumbnailContainer>

        <UploadButton
          inputId="icon"
          setImage={setIconImage}
          setThumbnailUrl={setIconUrl}
        />
      </Row>

      {!isFemale ? (
        <>
          {maleCuts.map((cut, idx) => (
            <Row key={cut.id}>
              <ThumbnailContainer>
                <Thumbnail src={frontImageUrls[idx]} />
                <Label>Przód - {cut.name} krój</Label>
              </ThumbnailContainer>

              <UploadButton
                inputId={cut.name}
                setImage={(image: File) => {
                  const tempArr = [...frontImages];
                  if (tempArr[idx]) {
                    tempArr[idx] = image;
                  } else {
                    tempArr.push(image);
                  }
                  setFrontImages(tempArr);
                  setUpdatedCutImages((val) => [
                    ...val,
                    { cutName: cut.id!, position: tempArr.indexOf(image) },
                  ]);
                }}
                setThumbnailUrl={(url: string) => {
                  const tempArr = [...frontImageUrls];
                  if (tempArr[idx]) {
                    tempArr[idx] = url;
                  } else {
                    tempArr.push(url);
                  }
                  setFrontImageUrls(tempArr);
                }}
              />
            </Row>
          ))}
        </>
      ) : (
        <>
          {femaleCuts.map((cut, idx) => (
            <Row key={cut.id}>
              <ThumbnailContainer>
                <Thumbnail src={frontFemaleUrls[idx]} />
                <Label>Przód - {cut.name} krój</Label>
              </ThumbnailContainer>

              <UploadButton
                inputId={cut.name}
                setImage={(image: File) => {
                  const tempArr = [...frontFemaleImages];
                  if (tempArr[idx]) {
                    tempArr[idx] = image;
                  } else {
                    tempArr.push(image);
                  }
                  setFrontFemaleImages(tempArr);
                  setUpdatedCutImages((val) => [
                    ...val,
                    { cutName: cut.id!, position: tempArr.indexOf(image) },
                  ]);
                }}
                setThumbnailUrl={(url: string) => {
                  const tempArr = [...frontFemaleUrls];
                  if (tempArr[idx]) {
                    tempArr[idx] = url;
                  } else {
                    tempArr.push(url);
                  }
                  setFrontFemaleUrls(tempArr);
                }}
              />
            </Row>
          ))}
        </>
      )}

      <Row>
        <ThumbnailContainer>
          <Thumbnail src={backUrl} />
          <Label>Tył</Label>
        </ThumbnailContainer>

        <UploadButton
          inputId="back"
          setImage={setBackImage}
          setThumbnailUrl={setBackUrl}
        />
      </Row>

      {isFemale && femaleCuts.some((cut) => cut.oversize === 'true') && (
        <>
          <Row>
            <ThumbnailContainer>
              <Thumbnail src={backOversizeUrl ?? ''} />
              <Label>Tył (oversize)</Label>
            </ThumbnailContainer>

            <UploadButton
              inputId="backOversize"
              setImage={setBackOversizeImage}
              setThumbnailUrl={setBackOversizeUrl}
            />
          </Row>

          <Row>
            <ThumbnailContainer>
              <Thumbnail src={collarOversizeUrl ?? ''} />
              <Label>Kołnierz (oversize)</Label>
            </ThumbnailContainer>

            <UploadButton
              inputId="collarOversize"
              setImage={setCollarOversizeImage}
              setThumbnailUrl={setCollarOversizeUrl}
            />
          </Row>
        </>
      )}

      <Row>
        <ThumbnailContainer>
          <Thumbnail src={beltUrl} />
          <Label>Pasek</Label>
        </ThumbnailContainer>

        <UploadButton
          inputId="belt"
          setImage={setBeltImage}
          setThumbnailUrl={setBeltUrl}
        />
      </Row>

      <Row>
        <ThumbnailContainer>
          <Thumbnail src={collarUrl} />
          <Label>Pasek z kołnierzem</Label>
        </ThumbnailContainer>

        <UploadButton
          inputId="collar"
          setImage={setCollarImage}
          setThumbnailUrl={setCollarUrl}
        />
      </Row>
    </DialogLayout>
  );
};
