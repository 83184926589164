import React from 'react';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { LinearProgressProps } from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { useStyles, BorderLinearProgress } from './styled';

interface LoadingDialogProps {
  title: string;
  isOpen: boolean;
  progress: number;
}

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number },
) {
  const classes = useStyles();
  const { value } = props;

  return (
    <Box display="flex" alignItems="center" className={classes.loaderContainer}>
      <Box width="100%" mr={1}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography
          variant="body2"
          color="textSecondary"
        >{`${value}%`}</Typography>
      </Box>
    </Box>
  );
}

export const LoadingDialog: React.FC<LoadingDialogProps> = (props) => {
  const { title, isOpen, progress } = props;

  return (
    <Dialog fullWidth aria-labelledby="loading-dialog-title" open={isOpen}>
      <DialogTitle id="loading-dialog-title">{title}</DialogTitle>
      <LinearProgressWithLabel value={progress} />
    </Dialog>
  );
};
