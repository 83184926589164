import React from 'react';
import { useTranslation } from 'react-i18next';

import { Title } from 'theme/typography';
import { Container } from 'theme/global';
import { FaRegCaretSquareDown } from 'react-icons/fa';
import {
  AboutUsSection,
  AboutUsContent,
  AboutUs,
  Icon,
  Divider,
  IconWrapper,
} from './styled';
import ImageCarousel from 'components/ImageCarousel';
import Gallery from './Gallery';

import img1 from 'assets/landing/1.jpg';
import img2 from 'assets/landing/2.jpg';
import img3 from 'assets/landing/3.jpg';

const About: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <AboutUsSection name="aboutUs" id="about-us">
        <ImageCarousel images={[img1, img2, img3]} />

        <AboutUsContent>
          <Title>{t('landing.title')}</Title>
          <AboutUs>{t('landing.aboutUs')}</AboutUs>

          <IconWrapper>
            <Icon to="gallery" smooth duration={500} ignoreCancelEvents>
              <FaRegCaretSquareDown />
            </Icon>
          </IconWrapper>
        </AboutUsContent>
      </AboutUsSection>

      <Divider />

      <Gallery />
    </Container>
  );
};

export default About;
