import styled from 'styled-components';

interface LoaderContainerProps {
  plainBackground?: boolean;
}

export const LoaderContainer = styled.div<LoaderContainerProps>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${(props) =>
    props.plainBackground
      ? props.theme.colors.primary
      : 'rgba(26, 26, 26, 0.6)'};
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
`;
