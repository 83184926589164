import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Container, Text, AcceptButton } from './styled';

export const CookiePolicy: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    if (location.pathname.includes('/dashboard')) return;

    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    setIsVisible(!cookiesAccepted || cookiesAccepted !== 'true');
  }, [location]);

  const handleAccept = () => {
    setIsVisible(false);
    localStorage.setItem('cookiesAccepted', 'true');
  };

  return isVisible ? (
    <Container>
      <Text>{t('app.cookieText')}</Text>
      <AcceptButton onClick={handleAccept}>
        {t('app.cookieAccept')}
      </AcceptButton>
    </Container>
  ) : (
    <></>
  );
};
