import styled from 'styled-components';
import { Container, OutlinedButton } from 'theme/global';

export const AccountContainer = styled(Container)``;

export const Title = styled.h1`
  font-size: ${(props) => props.theme.fontSizes.l};
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 4rem;
`;

export const Text = styled.p`
  font-size: ${(props) => props.theme.fontSizes.s};
  color: ${(props) => props.theme.colors.gray};
`;

export const BackButton = styled(OutlinedButton)`
  margin-top: 4rem;
`;
