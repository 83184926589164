import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchGalleryImages,
  selectGalleryImages,
  selectTotalImages,
  selectFetchImages,
  setFetchImages,
} from '../aboutSlice';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { FaRegCaretSquareDown } from 'react-icons/fa';

import {
  GalleryContainer,
  Image,
  LoaderContainer,
  GalleryImage,
  ArrowIcon,
  MoreIcon,
} from './styled';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spinner: {
      color: theme.palette.secondary.main,
      marginTop: '4rem',
    },
    dialog: {
      '& .MuiPaper-root': {
        backgroundColor: 'transparent',
        padding: 0,
      },
      '& .MuiPaper-elevation24': {
        boxShadow: 'none',
      },
      '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.8);',
      },
    },
  }),
);

function Gallery() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [galleryOpen, setGalleryOpen] = useState<boolean>(false);
  const [imagesLoaded, setImagesLoaded] = useState<number>(0);
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const totalImages = useSelector(selectTotalImages);
  const galleryImages = useSelector(selectGalleryImages);
  const fetchImages = useSelector(selectFetchImages);

  useEffect(() => {
    if (!fetchImages) return;

    dispatch(fetchGalleryImages({ page: currentPage }));
  }, [dispatch, currentPage, fetchImages]);

  const handleNextImageClick = useCallback(() => {
    if (!galleryOpen) return;

    if (currentImage === galleryImages.length - 1) {
      setCurrentImage(0);
    } else {
      setCurrentImage((count) => count + 1);
    }
  }, [galleryOpen, currentImage, galleryImages]);

  const handlePreviousImageClick = useCallback(() => {
    if (!galleryOpen) return;

    if (currentImage === 0) {
      setCurrentImage(galleryImages.length - 1);
    } else {
      setCurrentImage((count) => count - 1);
    }
  }, [galleryOpen, currentImage, galleryImages]);

  useEffect(() => {
    const arrowClick = (e: KeyboardEvent) => {
      if (e.key === 'ArrowRight') {
        handleNextImageClick();
      } else if (e.key === 'ArrowLeft') {
        handlePreviousImageClick();
      }
    };

    window.addEventListener('keydown', arrowClick);

    return () => window.removeEventListener('keydown', arrowClick);
  }, [handleNextImageClick, handlePreviousImageClick]);

  const handleImageLoad = () => {
    setImagesLoaded((count) => count + 1);
  };

  const handleGalleryOpened = (imageNumber: number) => {
    setCurrentImage(imageNumber);
    setGalleryOpen(true);
  };

  const loadMoreImages = () => {
    if (galleryImages.length >= totalImages || imagesLoaded < 8 * currentPage)
      return;

    setCurrentPage((count) => count + 1);
    dispatch(setFetchImages());
  };

  const renderedGalleryImages = galleryImages.map((image, idx) => {
    return (
      <Image
        key={image.id}
        onLoad={handleImageLoad}
        src={image.url}
        alt="Scudetto gallery"
        isLoaded={imagesLoaded >= 8}
        onClick={() => handleGalleryOpened(idx)}
      />
    );
  });

  return (
    <>
      {imagesLoaded < 8 && (
        <LoaderContainer>
          <CircularProgress className={classes.spinner} />
        </LoaderContainer>
      )}

      <GalleryContainer name="gallery">
        {renderedGalleryImages}
      </GalleryContainer>

      {(imagesLoaded < 8 * currentPage &&
        currentPage !== 1 &&
        imagesLoaded < totalImages) ||
      !totalImages ? (
        <LoaderContainer>
          <CircularProgress className={classes.spinner} />
        </LoaderContainer>
      ) : (
        <Dialog
          onClose={() => setGalleryOpen(false)}
          open={galleryOpen}
          className={classes.dialog}
          fullWidth
          maxWidth="sm"
        >
          <ArrowIcon onClick={handlePreviousImageClick}>
            <MdKeyboardArrowLeft />
          </ArrowIcon>

          <GalleryImage src={galleryImages[currentImage].url} />

          <ArrowIcon onClick={handleNextImageClick}>
            <MdKeyboardArrowRight />
          </ArrowIcon>
        </Dialog>
      )}

      {imagesLoaded >= 8 && imagesLoaded < totalImages && (
        <MoreIcon
          disabled={imagesLoaded < 8 * currentPage}
          onClick={loadMoreImages}
        >
          <FaRegCaretSquareDown />
        </MoreIcon>
      )}
    </>
  );
}

export default Gallery;
