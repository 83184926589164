import styled from 'styled-components';
import { device } from 'theme/theme';
import { Element } from 'react-scroll';

export const GalleryContainer = styled(Element)`
  width: 100%;
  height: 100%;
  margin-top: 10rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;

  @media (max-width: 1320px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.mobile} {
    display: block;
  }
`;

interface ImageProps {
  isLoaded?: boolean;
}

export const Image = styled.img<ImageProps>`
  max-width: 30rem;
  height: auto;
  display: ${(props) => (props.isLoaded ? '' : 'none')};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  @media ${device.mobile} {
    max-width: 100%;
  }
`;

export const GalleryImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ArrowIcon = styled.div`
  padding: 1.5rem;
  font-size: 2rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: ${(props) => props.theme.colors.primary60};
  color: ${(props) => props.theme.colors.light};
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  z-index: 10;
  cursor: pointer;

  &:first-of-type {
    left: 2rem;
    right: auto;
  }

  &:last-of-type {
    right: 2rem;
    left: auto;
  }

  &:hover {
    opacity: 0.8;
  }
`;

interface MoreIconProps {
  disabled?: boolean;
}

export const MoreIcon = styled.div<MoreIconProps>`
  font-size: 5rem;
  color: ${(props) =>
    props.disabled ? props.theme.colors.lightGray : props.theme.colors.accent};
  transition: all 0.2s ease-in-out;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  text-align: center;
  margin-top: 4rem;

  &:hover {
    opacity: 0.8;
  }
`;
