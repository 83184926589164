import React from 'react';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useStyles, StepperContainer } from './styled';

interface StepperProps {
  activeStep: number;
}

function ProgressStepper({ activeStep }: StepperProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <StepperContainer>
      <Stepper
        className={classes.root}
        activeStep={activeStep}
        alternativeLabel
      >
        <Step>
          <StepLabel>{t('checkout.stepper.first')}</StepLabel>
        </Step>

        <Step>
          <StepLabel>{t('checkout.stepper.second')}</StepLabel>
        </Step>

        <Step>
          <StepLabel>{t('checkout.stepper.third')}</StepLabel>
        </Step>

        <Step>
          <StepLabel>{t('checkout.stepper.fourth')}</StepLabel>
        </Step>
      </Stepper>
    </StepperContainer>
  );
}

export default ProgressStepper;
