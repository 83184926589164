import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { addVoucherType } from './redux/thunk';
import { VoucherTypeToAdd } from 'api/models/VoucherType';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { CustomButton } from 'admin/components/CustomButton';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    marginTop: '4rem',
  },
  input: {
    display: 'block',
    marginRight: '1rem',
    maxWidth: '30rem',
  },
}));

interface FormProps {
  name: string;
  price: number | undefined;
}

export const AddVoucherTypeForm: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('To pole jest wymagane'),
    price: Yup.number()
      .positive()
      .moreThan(0)
      .required('To pole jest wymagane'),
  });

  const formik = useFormik<FormProps>({
    initialValues: {
      name: '',
      price: undefined,
    },
    validationSchema,
    onSubmit: (values) => {
      if (!values) return;

      const newType = values as VoucherTypeToAdd;
      dispatch(addVoucherType(newType));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className={classes.root}>
      <TextField
        fullWidth
        variant="outlined"
        label="Nazwa"
        name="name"
        id="name"
        size="small"
        className={classes.input}
        value={formik.values.name || ''}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Cena"
        name="price"
        id="price"
        size="small"
        type="number"
        className={classes.input}
        value={formik.values.price || ''}
        onChange={formik.handleChange}
        error={formik.touched.price && Boolean(formik.errors.price)}
        helperText={formik.touched.price && formik.errors.price}
      />
      <CustomButton type="submit">Dodaj</CustomButton>
    </form>
  );
};
