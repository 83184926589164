import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from 'containers/Auth/authSlice';

interface UserRouteProps {
  children: React.ReactNode;
  exact: boolean;
  path: string;
}

const UserRoute: React.FC<UserRouteProps> = ({ children, exact, path }) => {
  const user = useSelector(selectUser);

  const destination = (location: any) => {
    if (!user) {
      return (
        <Redirect to={{ pathname: '/login', state: { from: location } }} />
      );
    }

    if (user.isAdmin)
      return (
        <Redirect
          to={{ pathname: '/dashboard/products', state: { from: location } }}
        />
      );

    return children;
  };

  return (
    <Route
      exact={exact}
      path={path}
      render={({ location }) => destination(location)}
    />
  );
};

export default UserRoute;
