import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import store from 'store';
import { Provider } from 'react-redux';
import theme, { muiTheme } from './theme/theme';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import Loader from 'components/Loader';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './containers/App';
import reportWebVitals from './reportWebVitals';
import 'locales/i18n';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';

const persistor = persistStore(store);

const env = process.env.NODE_ENV;

const gtmId = process.env.REACT_APP_GTM_ID;
const pixelId = process.env.REACT_APP_FACEBOOK_PIXEL;

if (env === 'production') {
  if (pixelId) {
    ReactPixel.init(pixelId);
    ReactPixel.pageView();
  }

  if (gtmId) TagManager.initialize({ gtmId });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <MuiThemeProvider theme={muiTheme}>
          <ThemeProvider theme={theme}>
            <Router>
              <App />
            </Router>
          </ThemeProvider>
        </MuiThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
