import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { CartProduct } from 'containers/Cart/cartSlice';
import { Coupon } from 'api/models/Coupon';
import { Product, isCustomProduct, CustomProduct } from 'api/models/Product';
import { Shipping } from 'api/models/Order';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    },
    body: {
      fontSize: 16,

      '&.summary': {
        backgroundColor: theme.palette.action.hover,
        fontWeight: 'bold',
      },
    },
  }),
)(TableCell);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: '4rem',
  },
  table: {
    minWidth: 700,
  },
  price: {
    color: theme.palette.success.main,
    fontWeight: 'bold',
  },
}));

interface SummaryTableProps {
  rows: CartProduct[];
  totalPrice: number | undefined;
  coupon: Coupon | null;
  shipping: Shipping;
}

function SummaryTable({
  rows,
  totalPrice,
  coupon,
  shipping,
}: SummaryTableProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const getShipping = (): string => {
    switch (shipping) {
      case Shipping.Local:
        return t('checkout.shipping1');

      case Shipping.Worldwide:
        return t('checkout.shipping2');

      case Shipping.Self:
        return t('checkout.shipping3');

      default:
        return t('checkout.freeShipping');
    }
  };

  const getProductPrice = (product: Product | CustomProduct, size: string) => {
    if (isCustomProduct(product)) return product.price;

    if (product.isVoucher) {
      const choosedVoucherType = product.voucherTypes.filter(
        (type) => type.name === size,
      );

      return choosedVoucherType[0].price;
    }

    return product.isOnSale && product.discountPrice
      ? product.discountPrice
      : product.price;
  };

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>{t('payment.name')}</StyledTableCell>
            <StyledTableCell align="right">{t('payment.size')}</StyledTableCell>
            <StyledTableCell align="right">
              {t('payment.quantity')}
            </StyledTableCell>
            <StyledTableCell align="right">
              {t('payment.price')}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => (
            <TableRow key={idx}>
              <StyledTableCell component="th" scope="row">
                {row.product.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.size}</StyledTableCell>
              <StyledTableCell align="right">{row.quantity}</StyledTableCell>
              <StyledTableCell align="right" className={classes.price}>
                {getProductPrice(row.product, row.size) * row.quantity} zł
              </StyledTableCell>
            </TableRow>
          ))}
          <TableRow>
            <StyledTableCell />
            <StyledTableCell />
            <StyledTableCell className="summary">
              {t('payment.shippingMethod')}
            </StyledTableCell>
            <StyledTableCell align="right" className="summary">
              {getShipping()}
            </StyledTableCell>
          </TableRow>
          {coupon && (
            <TableRow>
              <StyledTableCell />
              <StyledTableCell />
              <StyledTableCell className="summary">
                {t('payment.discountCode')}
              </StyledTableCell>
              <StyledTableCell align="right" className="summary">
                {coupon.name} - {coupon.discount} %
              </StyledTableCell>
            </TableRow>
          )}

          <TableRow>
            <StyledTableCell />
            <StyledTableCell />
            <StyledTableCell className="summary">
              {t('payment.total')}
            </StyledTableCell>
            <StyledTableCell align="right" className="summary">
              {totalPrice && totalPrice.toFixed(2)} zł
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SummaryTable;
