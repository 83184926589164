import styled from 'styled-components';
import { device } from 'theme/theme';
import { Container } from 'theme/global';

export const AuthContainer = styled(Container)`
  width: 40%;
  margin-top: 12rem;
  min-height: calc(100vh - 38.3rem - 10rem - 11.7rem);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const Logo = styled.img`
  max-width: 6.4rem;
  height: auto;
  margin-bottom: 2rem;
`;
