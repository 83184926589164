import styled from 'styled-components';
import { Container } from 'theme/global';
import { device } from 'theme/theme';

export const GuidesContainer = styled(Container)``;

export const Title = styled.p`
  text-transform: uppercase;
  text-align: left;
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.fontSizes.s};
  font-weight: 400;
  margin: 4rem 0;
`;

export const GuidesGrid = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 5rem 2rem;

  @media ${device.mobile} {
    display: block;
  }
`;

export const Guide = styled.article`
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  @media ${device.mobile} {
    margin-bottom: 5rem;
  }
`;

export const GuideThumbnail = styled.img`
  width: 100%;
  border-radius: 1rem;
`;

export const GuideTitle = styled.p`
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.fontSizes.m};
  margin-top: 1rem;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
