import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Order, OrderStatus } from 'api/models/Order';
import { RootState } from 'store';
import { axiosInstance } from 'axiosConfig';
import { resetCheckoutState } from 'containers/App/actions';

interface CheckoutState {
  isLoading: boolean;
  error: string | undefined;
  order: Order | undefined;
}

const initialState: CheckoutState = {
  isLoading: false,
  error: undefined,
  order: undefined,
};

export const placeOrder = createAsyncThunk<
  Order | undefined,
  Order,
  { rejectValue: string }
>('checkout/placeOrder', async (order, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post<Order>('orders', order);

    if (response.status !== 200) {
      return rejectWithValue(response.statusText);
    }

    return response.data;
  } catch (error: any) {
    if (!error.message) {
      throw error;
    }

    return rejectWithValue(error.message as string);
  }
});

export const updateOrderStatus = createAsyncThunk<
  void,
  { id: number; status: OrderStatus }
>('checkout/updateOrderStatus', async ({ id, status }) => {
  await axiosInstance.put(`orders`, { ids: [id], newStatus: status });
});

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(placeOrder.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(placeOrder.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.order = payload;
    });
    builder.addCase(placeOrder.rejected, (state, action) => {
      state.error = action.error.message;
      state.isLoading = false;
    });

    // Reset state
    builder.addCase(resetCheckoutState, (state) => {
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
      state.order = initialState.order;
    });
  },
});

export const selectIsLoading = (state: RootState) => state.checkout.isLoading;
export const selectError = (state: RootState) => state.checkout.error;
export const selectOrder = (state: RootState) => state.checkout.order;

export default checkoutSlice.reducer;
