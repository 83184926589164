import { createAsyncThunk } from '@reduxjs/toolkit';
import { Builder, BuilderContent } from '@builder.io/react';

export const fetchGuides = createAsyncThunk<
  BuilderContent[],
  Builder,
  { rejectValue: string }
>('guides/fetchGuides', async (builder, { rejectWithValue }) => {
  try {
    const guides = await builder.getAll('blog-article', {
      options: { includeRefs: true },
      omit: 'data.blocks',
    });

    const sortedGuides = [...guides].sort((a: any, b: any) => {
      return (b.createdDate as number) - (a.createdDate as number);
    });

    return sortedGuides as BuilderContent[];
  } catch (error: any) {
    if (!error.message) throw error;

    return rejectWithValue(error.message);
  }
});

export const fetchSingleGuide = createAsyncThunk<
  BuilderContent,
  { builder: Builder; handle: string },
  { rejectValue: string }
>('guides/fetchSingleGuide', async (args, { rejectWithValue }) => {
  const { builder, handle } = args;

  try {
    const guide = await builder
      .get('blog-article', {
        options: { includeRefs: true },
        query: {
          'data.handle': handle,
        },
      })
      .promise();

    return guide;
  } catch (error: any) {
    if (!error.message) throw error;

    return rejectWithValue(error.message);
  }
});
