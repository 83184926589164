import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSizes, deleteSizes } from './redux/thunk';
import {
  selectSizesState,
  setSelected,
  toggleSelected,
  closeSnackbar,
} from './redux/sizesSlice';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { CustomToolbar } from 'admin/components/CustomToolbar';

import { ErrorMessage } from 'admin/components/ErrorMessage';
import { DashboardContainer } from 'theme/global';
import { Text } from 'theme/typography';
import { Loader } from 'admin/components/Loader';
import { AddSizeForm } from './AddSizeForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      '&.Mui-checked': {
        color: theme.palette.blue.main,
      },
    },
  }),
);

export const SizesContainer = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { isLoading, error, sizes, selectedSizes, snackbarOpen, snackbarText } =
    useSelector(selectSizesState);

  useEffect(() => {
    dispatch(fetchSizes(null));
  }, [dispatch]);

  const handleAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = sizes.map((size) => size.id);
      dispatch(setSelected(newSelected));
      return;
    }

    dispatch(setSelected([]));
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    dispatch(toggleSelected(id));
  };

  const isSelected = (id: number) => selectedSizes.includes(id);

  const handleDeleteClick = () => {
    dispatch(deleteSizes(selectedSizes));
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <DashboardContainer>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {sizes.length > 0 ? (
            <Paper>
              <CustomToolbar
                handleDelete={handleDeleteClick}
                numSelected={selectedSizes.length}
                title="Lista rozmiarów"
              />
              <TableContainer>
                <Table aria-labelledby="productsTable" size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={
                            selectedSizes.length > 0 &&
                            selectedSizes.length < sizes.length
                          }
                          checked={
                            sizes.length > 0 &&
                            selectedSizes.length === sizes.length
                          }
                          className={classes.checkbox}
                          onChange={handleAllClick}
                        />
                      </TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell>Rozmiar</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {sizes.map((size) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={size.id}
                        onClick={(event) => handleClick(event, size.id)}
                        selected={isSelected(size.id)}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isSelected(size.id)}
                            className={classes.checkbox}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {size.id}
                        </TableCell>
                        <TableCell>{size.sizeName}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ) : (
            <Text>Brak dodanych rozmiarów</Text>
          )}

          <AddSizeForm />
        </DashboardContainer>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => dispatch(closeSnackbar())}
      >
        <Alert onClose={() => dispatch(closeSnackbar())} severity="success">
          {snackbarText}
        </Alert>
      </Snackbar>
    </>
  );
};
