import styled from 'styled-components';
import { Container } from 'theme/global';
import { device } from 'theme/theme';

export const ConfiguratorContainer = styled(Container)`
  margin-top: 12rem;
  min-height: 100vh;
  width: 100%;
  display: grid;
  grid-template-areas:
    'top top top'
    'left main right'
    'bottom bottom bottom';
  justify-items: center;

  @media ${device.tablet} {
    grid-template-areas:
      'top'
      'left'
      'main'
      'right'
      'bottom';
  }
`;

export const CutSection = styled.div`
  grid-area: top;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MaterialSection = styled.div`
  grid-area: left;
`;

export const MainSection = styled.div`
  grid-area: main;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-image: url(/images/configurator/creator_border.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10rem; */
  @media ${device.tablet} {
    margin: 2rem 0;
    display: block;
  }
`;

export const FleeceSection = styled.div`
  grid-area: right;
`;

export const BottomSection = styled.div`
  grid-area: bottom;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: ${(props) => props.theme.fontSizes.m};
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  margin: 1rem;
`;

export const Price = styled.span`
  color: ${(props) => props.theme.colors.success};
`;

export const ThumbnailsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(6.4rem, 1fr));
  gap: 1.5rem;
  max-width: 28.6rem;
`;

interface ThumbnailProps {
  withMargin?: boolean;
  disabled?: boolean;
}

export const Thumbnail = styled.img<ThumbnailProps>`
  width: 6.4rem;
  height: 6.4rem;
  margin: ${(props) => (props.withMargin ? '0 1rem' : 0)};
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    transform: scale(2);
  }

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.7;
    z-index: 0;

    &:hover {
      cursor: not-allowed;
      transform: none;
    }
  `}

  @media ${device.tablet} {
    width: 4.8rem;
    height: 4.8rem;
  }
`;

export const Cut = styled.img`
  max-height: 12rem;
  margin: 0 1.5rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    transform: scale(1.75);
  }

  @media ${device.tablet} {
    max-height: 8rem;
    margin: 0.5rem;
  }
`;

export const ButtonsContainer = styled.div`
  margin-bottom: 4rem;
`;

export const HelperText = styled.p`
  font-size: ${(props) => props.theme.fontSizes.s};
  color: ${(props) => props.theme.colors.gray};
  margin-top: 1rem;
  text-align: center;
`;

export const ImagesContainer = styled.div`
  position: relative;
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 48rem;
  min-width: 22rem;
  width: 100%;
  height: auto;

  &.is-relative {
    position: relative;
  }
`;

export const SizesContainer = styled.div`
  margin: 2rem 0;
`;

export const CutsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
`;

export const StyledRadio = styled.input`
  cursor: pointer;

  &:nth-of-type(2) {
    margin-left: 2rem;
  }
`;

export const Label = styled.label`
  font-size: ${(props) => props.theme.fontSizes.s};
  color: ${(props) => props.theme.colors.primary};
  margin-left: 0.5rem;
  cursor: pointer;
`;
