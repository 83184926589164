import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { axiosInstance } from 'axiosConfig';
import { Przelewy24Transaction } from 'api/models/Przelewy24Transaction';
import { resetCheckoutState } from 'containers/App/actions';
import { P24PaymentMethod } from 'api/models/P24PaymentMethod';

interface PaymentState {
  isLoading: boolean;
  paymentMethodsLoading: boolean;
  error: string | undefined;
  payUrl: string | undefined;
  paymentSession: string | undefined;
  paymentMethods: P24PaymentMethod[] | undefined;
}

const initialState: PaymentState = {
  isLoading: false,
  paymentMethodsLoading: false,
  error: undefined,
  payUrl: undefined,
  paymentSession: undefined,
  paymentMethods: undefined,
};

export const fetchPaymentMethods = createAsyncThunk<
  P24PaymentMethod[],
  'pl' | 'en',
  { rejectValue: string }
>('payment/getPaymentMethods', async (lang, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get<P24PaymentMethod[]>(
      `payment/payment-methods/${lang}`,
    );

    if (response.status !== 200) {
      return rejectWithValue(response.statusText);
    }

    return response.data;
  } catch (error: any) {
    if (!error.response.data) {
      throw error;
    }

    return rejectWithValue(error.response.data as string);
  }
});

export const registerTransaction = createAsyncThunk<
  { paymentUrl: string; sessionId: string },
  { transaction: Przelewy24Transaction; paymentId: string; orderId: number },
  { rejectValue: string }
>(
  'payment/registerTransaction',
  async ({ transaction, paymentId, orderId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<{
        paymentUrl: string;
        sessionId: string;
      }>('payment/transaction/register', {
        transaction,
        sessionId: paymentId,
        orderId,
      });

      if (response.status !== 200) {
        return rejectWithValue(response.statusText);
      }

      const { paymentUrl, sessionId } = response.data;

      return { paymentUrl, sessionId };
    } catch (error: any) {
      if (!error.response.data) {
        throw error;
      }

      return rejectWithValue(error.response.data as string);
    }
  },
);

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(registerTransaction.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
      state.payUrl = undefined;
    });
    builder.addCase(registerTransaction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.payUrl = action.payload.paymentUrl;
      state.paymentSession = action.payload.sessionId;
    });
    builder.addCase(registerTransaction.rejected, (state, action) => {
      state.error = action.payload ? action.payload : action.error.message;
      state.isLoading = false;
    });

    // Fetch payment methods from P24
    builder.addCase(fetchPaymentMethods.pending, (state) => {
      state.paymentMethodsLoading = true;
      state.error = undefined;
    });
    builder.addCase(fetchPaymentMethods.fulfilled, (state, action) => {
      state.paymentMethodsLoading = false;
      state.paymentMethods = action.payload;
    });
    builder.addCase(fetchPaymentMethods.rejected, (state, action) => {
      state.error = action.payload ? action.payload : action.error.message;
      state.paymentMethodsLoading = false;
    });

    // Reset state
    builder.addCase(resetCheckoutState, (state) => {
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
      state.payUrl = initialState.payUrl;
      state.paymentSession = initialState.paymentSession;
    });
  },
});

export const selectIsLoading = (state: RootState) => state.payment.isLoading;
export const selectError = (state: RootState) => state.payment.error;
export const selectPayUrl = (state: RootState) => state.payment.payUrl;
export const selectPaymentSession = (state: RootState) =>
  state.payment.paymentSession;
export const selectPaymentMethods = (state: RootState) =>
  state.payment.paymentMethods;

export default paymentSlice.reducer;
