import styled from 'styled-components';
import { Container } from 'theme/global';
import { device } from 'theme/theme';

export const CartContainer = styled(Container)`
  min-height: 100vh;
  margin-top: 12rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.mobile} {
    display: block;
  }
`;

export const Title = styled.h1`
  font-size: ${(props) => props.theme.fontSizes.l};
  color: ${(props) => props.theme.colors.primary};

  @media ${device.mobile} {
    margin-bottom: 1rem;
  }
`;

export const CartItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 15rem;
  margin-bottom: 2rem;
`;

export const ItemImageContainer = styled.div`
  width: 12rem;
  display: flex;
  justify-content: center;
`;

export const ItemImage = styled.img`
  height: 100%;
  width: auto;
  max-height: 15rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`;

export const ItemContent = styled.div`
  height: 100%;
  margin-left: 4rem;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.mobile} {
    margin-left: 1rem;
  }
`;

export const ItemName = styled.p`
  font-size: ${(props) => props.theme.fontSizes.m};
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`;

export const ItemPrice = styled.p`
  font-size: ${(props) => props.theme.fontSizes.m};
  color: ${(props) => props.theme.colors.success};
  font-weight: bold;
`;

export const HelpText = styled.p`
  font-size: ${(props) => props.theme.fontSizes.xs};
  color: ${(props) => props.theme.colors.primary};
  margin: 0.5rem 0;
  font-weight: bold;
`;

export const CloseIcon = styled.div`
  font-size: ${(props) => props.theme.fontSizes.m};
  color: ${(props) => props.theme.colors.danger};
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.2rem;
  background-color: ${(props) => props.theme.colors.accent};
  margin: 1rem 0 3rem 0;
`;

export const SummaryContainer = styled.div`
  margin: 3rem 0;
  background-color: ${(props) => props.theme.colors.lightGray};
  padding: 1rem 2rem;
  border-radius: 0.5rem;
`;

export const TotalAmount = styled(HelpText)`
  font-size: ${(props) => props.theme.fontSizes.m};
`;

export const TotalPrice = styled.span`
  font-size: ${(props) => props.theme.fontSizes.m};
  color: ${(props) => props.theme.colors.success};
`;

export const CouponInfo = styled(TotalAmount)``;

export const CouponContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RemoveCouponIcon = styled.div`
  font-size: ${(props) => props.theme.fontSizes.m};
  color: ${(props) => props.theme.colors.gray};
  margin-left: 1rem;
  line-height: 1rem;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
