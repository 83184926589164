import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface ConfirmationDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
  title: string;
  text: string;
  declineButton: string;
  acceptButton: string;
  handleAccept: () => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (
  props,
) => {
  const {
    isOpen,
    closeDialog,
    title,
    text,
    declineButton,
    acceptButton,
    handleAccept,
  } = props;

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary" autoFocus>
            {declineButton}
          </Button>
          <Button onClick={handleAccept} color="primary">
            {acceptButton}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
