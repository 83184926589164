import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

interface DialogLayoutProps {
  title: string;
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}

export const DialogLayout: React.FC<DialogLayoutProps> = ({
  title,
  isOpen,
  handleClose,
  handleSubmit,
  children,
}) => {
  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Anuluj
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Dodaj
        </Button>
      </DialogActions>
    </Dialog>
  );
};
