import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProgressStepper from 'components/ProgressStepper';
import { resetCheckoutState } from 'containers/App/actions';
import { useDispatch } from 'react-redux';

import { Container } from 'theme/global';
import { BackButton, Title, Text } from './styled';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      padding: '4rem',
      width: '100%',
      height: '100%',
      textAlign: 'center',
      marginTop: '4rem',
    },
  }),
);

interface ParamTypes {
  status: string;
}

function Summary() {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const { status } = useParams<ParamTypes>();

  useEffect(() => {
    if (status !== 'success') return;

    dispatch(resetCheckoutState());
  }, [dispatch, status]);

  const handleGoBack = () => {
    history.push('/');
  };

  const renderedContent = (): React.ReactNode => {
    let content: React.ReactNode;

    if (!status) return <></>;

    if (status !== 'success') {
      content = (
        <>
          <Title>{t('summary.titleError')}</Title>
          <Text>{t('summary.messageError')}</Text>
        </>
      );
    } else {
      content = (
        <>
          <Title>{t('summary.titleSuccess')}</Title>
          <Text>{t('summary.messageSuccess')}</Text>
        </>
      );
    }

    return content;
  };

  return (
    <Container topMargin withFooter>
      <ProgressStepper activeStep={4} />

      <Paper elevation={2} className={classes.paper}>
        {renderedContent()}
      </Paper>

      <BackButton onClick={handleGoBack}>Wróć do sklepu</BackButton>
    </Container>
  );
}

export default Summary;
