import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product } from 'api/models/Product';
import { RootState } from 'store';
import {
  fetchProducts,
  deleteProducts,
  addProduct,
  fetchProductById,
  updateProduct,
  updateBestsellerValue,
  updateOutletValue,
} from './thunk';

interface ProductsState {
  isUploading: boolean;
  isLoading: boolean;
  error: string | undefined;
  products: Product[];
  productsNumber: number;
  selectedProducts: number[];
  uploadPercentage: number;
  currentProduct: Product | undefined;
}

const initialState: ProductsState = {
  isUploading: false,
  isLoading: false,
  error: undefined,
  products: [],
  productsNumber: 0,
  selectedProducts: [],
  uploadPercentage: 0,
  currentProduct: undefined,
};

const productsSlice = createSlice({
  name: 'adminProductsSlice',
  initialState,
  reducers: {
    setSelected: (state, action: PayloadAction<number[]>) => {
      state.selectedProducts = action.payload;
    },
    toggleSelected: (state, action: PayloadAction<number>) => {
      if (state.selectedProducts.includes(action.payload)) {
        state.selectedProducts = state.selectedProducts.filter(
          (selected) => selected !== action.payload,
        );
      } else {
        state.selectedProducts.push(action.payload);
      }
    },
    setUploadPercentage: (state, action: PayloadAction<number>) => {
      state.uploadPercentage = action.payload;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.products = action.payload.products;
      state.productsNumber = action.payload.totalNumber;
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    builder.addCase(deleteProducts.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(deleteProducts.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.products = state.products.filter(
        (product) => !payload.includes(product.id),
      );
      state.productsNumber -= 1;
      state.selectedProducts = state.selectedProducts.filter(
        (selectedProduct) => !payload.includes(selectedProduct),
      );
    });
    builder.addCase(deleteProducts.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    builder.addCase(addProduct.pending, (state) => {
      state.isUploading = true;
      state.error = undefined;
      state.uploadPercentage = 0;
    });
    builder.addCase(addProduct.fulfilled, (state, { payload }) => {
      state.isUploading = false;
      state.products.push(payload);
      state.uploadPercentage = 0;
    });
    builder.addCase(addProduct.rejected, (state, action) => {
      state.isUploading = false;
      state.uploadPercentage = 0;
      state.error = action.payload ? action.payload : action.error.message;
    });

    builder.addCase(fetchProductById.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(fetchProductById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.currentProduct = payload;
    });
    builder.addCase(fetchProductById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    builder.addCase(updateProduct.pending, (state) => {
      state.isUploading = true;
      state.error = undefined;
      state.uploadPercentage = 0;
    });
    builder.addCase(updateProduct.fulfilled, (state, { payload }) => {
      state.isUploading = false;
      state.uploadPercentage = 0;
      state.products = state.products.map((product) =>
        product.id === payload.id ? payload : product,
      );
    });
    builder.addCase(updateProduct.rejected, (state, action) => {
      state.isUploading = false;
      state.uploadPercentage = 0;
      state.error = action.payload ? action.payload : action.error.message;
    });

    builder.addCase(updateBestsellerValue.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(updateBestsellerValue.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.products = state.products.map((product) =>
        product.id === payload.id ? payload : product,
      );
    });
    builder.addCase(updateBestsellerValue.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    builder.addCase(updateOutletValue.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(updateOutletValue.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.products = state.products.map((product) =>
        product.id === payload.id ? payload : product,
      );
    });
    builder.addCase(updateOutletValue.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });
  },
});

export const { toggleSelected, setSelected, setUploadPercentage, setError } =
  productsSlice.actions;

export const productsSelectors = (state: RootState) => state.adminProducts;

export default productsSlice.reducer;
