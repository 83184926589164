import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'containers/Auth/authSlice';
import { useHistory, Link } from 'react-router-dom';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import AddIcon from '@material-ui/icons/Add';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import BuildIcon from '@material-ui/icons/Build';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import HeightIcon from '@material-ui/icons/Height';
import LabelIcon from '@material-ui/icons/Label';

const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    title: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
  }),
);

const productItems = [
  {
    name: 'Wszystkie produkty',
    icon: <FormatListBulletedIcon />,
    link: '/dashboard/products',
  },
  { name: 'Dodaj produkt', icon: <AddIcon />, link: '/dashboard/products/add' },
  {
    name: 'Zamówienia',
    icon: <ShoppingBasketIcon />,
    link: '/dashboard/orders',
  },
  {
    name: 'Rozmiary',
    icon: <HeightIcon />,
    link: '/dashboard/sizes',
  },
  {
    name: 'Rodzaje voucherów',
    icon: <LabelIcon />,
    link: '/dashboard/voucher-types',
  },
  { name: 'Kody rabatowe', icon: <MoneyOffIcon />, link: '/dashboard/coupons' },
];

const manageWebsiteItems = [
  { name: 'Galeria', icon: <PhotoLibraryIcon />, link: '/dashboard/gallery' },
  { name: 'Kreator', icon: <BuildIcon />, link: '/dashboard/creator' },
];

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dropdownOpen = Boolean(anchorEl);

  const handleDropdownOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const renderedDropdownMenu = (
    <div>
      <IconButton
        aria-label="Admin account menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleDropdownOpen}
        color="inherit"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={dropdownOpen}
        onClose={handleDropdownClose}
      >
        <MenuItem onClick={() => history.push('/')}>Przejdź do sklepu</MenuItem>
        <MenuItem onClick={() => dispatch(logout())}>Wyloguj</MenuItem>
      </Menu>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title} noWrap>
            Scudetto dashboard
          </Typography>
          {renderedDropdownMenu}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            {productItems.map((item, idx) => (
              <Link key={idx} to={item.link}>
                <ListItem button>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItem>
              </Link>
            ))}
          </List>

          <Divider />

          <List>
            {manageWebsiteItems.map((item, idx) => (
              <Link key={idx} to={item.link}>
                <ListItem button key={idx}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItem>
              </Link>
            ))}
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        {children}
      </main>
    </div>
  );
};
