import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  signUp,
  selectUser,
  selectIsLoading,
  selectError,
  resetError,
} from '../authSlice';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { AuthContainer, Logo } from '../styled';
import {
  Form,
  InputContainer,
  OutlinedButton,
  StyledInput,
  HelperText,
  HelperTextLink,
  ErrorContainer,
  ErrorMessage,
} from 'theme/global';
import LogoImg from 'assets/logo/logo.png';

interface FormValues {
  name: string;
  phone: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const SignUp: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);
  const user = useSelector(selectUser);

  useEffect(() => {
    dispatch(resetError());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!user) return;

    formik.resetForm();
    history.push('/account');
    // eslint-disable-next-line
  }, [user]);

  const signupSchema = Yup.object().shape({
    name: Yup.string().required(t('auth.nameRequired')),
    phone: Yup.string()
      .required(t('auth.phoneRequired'))
      .matches(/^[0-9 ]+$/, t('auth.phoneDigits')),
    email: Yup.string()
      .email(t('auth.invalidEmail'))
      .required(t('auth.emptyEmail')),
    password: Yup.string()
      .required(t('auth.passwordEmpty'))
      .min(6, t('auth.passwordLength')),
    confirmPassword: Yup.string()
      .required(t('auth.requiredField'))
      .oneOf([Yup.ref('password'), null], t('auth.passwordMatch')),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: signupSchema,
    onSubmit: (values: FormValues) => {
      dispatch(signUp(values));
    },
  });

  return (
    <AuthContainer>
      <Link to="/">
        <Logo src={LogoImg} />
      </Link>

      <Form center onSubmit={formik.handleSubmit}>
        <InputContainer>
          <StyledInput
            id="name"
            name="name"
            type="text"
            placeholder={t('auth.namePlaceholder')}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.errors.name !== undefined && formik.touched.name}
          />
          {formik.errors.name && formik.touched.name && (
            <HelperText error>{formik.errors.name}</HelperText>
          )}
        </InputContainer>

        <InputContainer>
          <StyledInput
            id="phone"
            name="phone"
            type="text"
            placeholder={t('auth.phonePlaceholder')}
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.errors.phone !== undefined && formik.touched.phone}
          />
          {formik.errors.phone && formik.touched.phone && (
            <HelperText error>{formik.errors.phone}</HelperText>
          )}
        </InputContainer>

        <InputContainer>
          <StyledInput
            id="email"
            name="email"
            type="email"
            placeholder={t('auth.emailPlaceholder')}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.errors.email !== undefined && formik.touched.email}
          />
          {formik.errors.email && formik.touched.email && (
            <HelperText error>{formik.errors.email}</HelperText>
          )}
        </InputContainer>

        <InputContainer>
          <StyledInput
            id="password"
            name="password"
            type="password"
            placeholder={t('auth.passwordPlaceholder')}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={
              formik.errors.password !== undefined && formik.touched.password
            }
          />
          {formik.errors.password && formik.touched.password && (
            <HelperText error>{formik.errors.password}</HelperText>
          )}
        </InputContainer>

        <InputContainer>
          <StyledInput
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            placeholder={t('auth.confirmPasswordPlaceholder')}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={
              formik.errors.confirmPassword !== undefined &&
              formik.touched.confirmPassword
            }
          />
          {formik.errors.confirmPassword && formik.touched.confirmPassword && (
            <HelperText error>{formik.errors.confirmPassword}</HelperText>
          )}
        </InputContainer>

        <HelperText>
          {t('auth.alreadyMember')}
          <HelperTextLink to="/login">{t('auth.login')}</HelperTextLink>
        </HelperText>

        {error && (
          <ErrorContainer>
            <ErrorMessage>{error}</ErrorMessage>
          </ErrorContainer>
        )}

        <OutlinedButton type="submit" className={clsx({ disabled: isLoading })}>
          {t('auth.signUpLink')}
        </OutlinedButton>
      </Form>
    </AuthContainer>
  );
};

export default SignUp;
