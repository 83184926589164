import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrders, deleteOrders, updateOrders } from './redux/thunk';
import { useHistory } from 'react-router-dom';
import {
  selectOrdersState,
  closeSnackbar,
  setSelected,
  toggleSelected,
} from './redux/ordersSlice';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import CancelIcon from '@material-ui/icons/Cancel';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import HelpIcon from '@material-ui/icons/Help';
import { CustomToolbar } from 'admin/components/CustomToolbar';

import { ErrorMessage } from 'admin/components/ErrorMessage';
import { DashboardContainer } from 'theme/global';
import { Text } from 'theme/typography';
import { Loader } from 'admin/components/Loader';
import { useStyles, StatusContainer, StatusIcon } from './styled';
import { OrderStatus } from 'api/models/Order';
import { ConfirmationDialog } from 'admin/components/ConfirmationDialog';
import { UpdateDialog } from './UpdateDialog';

export const OrdersContainer = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const {
    isLoading,
    error,
    orders,
    snackbarOpen,
    snackbarText,
    selectedOrders,
  } = useSelector(selectOrdersState);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  const handleAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = orders.map((order) => order.id);
      dispatch(setSelected(newSelected));
      return;
    }

    dispatch(setSelected([]));
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    dispatch(toggleSelected(id));
  };

  const isSelected = (id: number) => selectedOrders.includes(id);

  const handleDeleteClick = () => {
    setDialogOpen(false);
    dispatch(deleteOrders(selectedOrders));
  };

  const handleUpdateClick = (status: OrderStatus | undefined) => {
    if (!status) return;

    setUpdateDialogOpen(false);
    dispatch(updateOrders({ ids: selectedOrders, status }));
  };

  const getDate = (value: string): string => {
    const date = new Date(value);
    return date.toLocaleDateString();
  };

  const getOrderStatusIcon = (status: OrderStatus) => {
    switch (status) {
      case OrderStatus.Pending:
        return (
          <StatusIcon warning>
            <MonetizationOnIcon />
          </StatusIcon>
        );

      case OrderStatus.Paid:
        return (
          <StatusIcon info>
            <HourglassEmptyIcon />
          </StatusIcon>
        );

      case OrderStatus.Canceled:
        return (
          <StatusIcon danger>
            <CancelIcon />
          </StatusIcon>
        );

      case OrderStatus.Sent:
        return (
          <StatusIcon success>
            <CheckCircleIcon />
          </StatusIcon>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <DashboardContainer>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {orders.length > 0 ? (
            <Paper>
              <CustomToolbar
                handleDelete={() => setDialogOpen(true)}
                numSelected={selectedOrders.length}
                title="Lista zamówień"
                withEdit
                editMultiple
                handleEdit={() => setUpdateDialogOpen(true)}
              />
              <TableContainer>
                <Table aria-labelledby="productsTable" size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={
                            selectedOrders.length > 0 &&
                            selectedOrders.length < orders.length
                          }
                          checked={
                            orders.length > 0 &&
                            selectedOrders.length === orders.length
                          }
                          className={classes.checkbox}
                          onChange={handleAllClick}
                        />
                      </TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Numer zamówienia</TableCell>
                      <TableCell>Data złożenia</TableCell>
                      <TableCell>Kwota</TableCell>
                      <TableCell>Rabat</TableCell>
                      <TableCell align="right">Szczegóły</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {orders.map((order) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        style={{ cursor: 'pointer' }}
                        key={order.id}
                        selected={isSelected(order.id)}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={(event) => handleClick(event, order.id)}
                            checked={isSelected(order.id)}
                            className={classes.checkbox}
                          />
                        </TableCell>
                        <TableCell
                          onClick={() =>
                            history.push(`/dashboard/orders/${order.id}`)
                          }
                        >
                          <StatusContainer>
                            {getOrderStatusIcon(order.status as OrderStatus)}
                            {order.status}
                          </StatusContainer>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          onClick={() =>
                            history.push(`/dashboard/orders/${order.id}`)
                          }
                        >
                          {order.id}
                        </TableCell>
                        <TableCell
                          onClick={() =>
                            history.push(`/dashboard/orders/${order.id}`)
                          }
                        >
                          {getDate(order.created_at)}
                        </TableCell>
                        <TableCell
                          onClick={() =>
                            history.push(`/dashboard/orders/${order.id}`)
                          }
                        >
                          {order.finalPrice.toFixed(2)} zł
                        </TableCell>
                        <TableCell
                          onClick={() =>
                            history.push(`/dashboard/orders/${order.id}`)
                          }
                        >
                          {order.discount}%
                        </TableCell>
                        <TableCell
                          onClick={() =>
                            history.push(`/dashboard/orders/${order.id}`)
                          }
                          align="right"
                        >
                          <HelpIcon />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ) : (
            <Text>Brak zamówień</Text>
          )}
        </DashboardContainer>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => dispatch(closeSnackbar())}
      >
        <Alert onClose={() => dispatch(closeSnackbar())} severity="success">
          {snackbarText}
        </Alert>
      </Snackbar>

      <ConfirmationDialog
        isOpen={dialogOpen}
        title="Czy na pewno chcesz usunąć wybrane zamówienia?"
        text="Wybrane zamówienia zostaną usunięte. Tej operacji nie można cofnąć."
        declineButton="Anuluj"
        acceptButton="Usuń"
        closeDialog={() => setDialogOpen(false)}
        handleAccept={handleDeleteClick}
      />

      <UpdateDialog
        isOpen={updateDialogOpen}
        closeDialog={() => setUpdateDialogOpen(false)}
        handleAccept={handleUpdateClick}
      />
    </>
  );
};
