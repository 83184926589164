import React from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useTranslation, Trans } from 'react-i18next';

import { TermsContainer, Title, Text, BackButton, Link } from './styled';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      padding: '4rem',
      width: '100%',
      height: '100%',
    },
  }),
);

function Terms() {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <TermsContainer topMargin withFooter>
      <Paper className={classes.paper}>
        <Title>{t('terms.title')}</Title>

        <ol>
          <Text>
            <Trans i18nKey="terms.text1">
              Kontakt ze sprzedawcą odbywa się drogą elektroniczną:{' '}
              <Link href="mailto:contact@scudetto.store">
                contact@scudetto.store
              </Link>
              , poprzez funpage na{' '}
              <Link href="https://www.facebook.com/Scudetto-1127607537444039/">
                facebooku
              </Link>{' '}
              lub telefoniczną (od poniedziałku do piątku od 9:00-16:00) Piotr:
              788863434, Mariusz: 600372555. Preferujemy te pierwszą formę, ale
              jeśli chcesz posłuchać jak brzmią nasze głosy, albo tak Ci
              wygodniej - dzwoń śmiało.
            </Trans>
          </Text>
          <Text>{t('terms.text2')}</Text>
          <Text>{t('terms.text3')}</Text>
          <Text>{t('terms.text4')}</Text>
          <Text>{t('terms.text5')}</Text>
        </ol>

        <BackButton onClick={() => history.goBack()}>
          {t('terms.backButton')}
        </BackButton>
      </Paper>
    </TermsContainer>
  );
}

export default Terms;
