import React from 'react';
import Modal from 'components/Modal';
import { ProductType } from 'api/models/Product';
import { useTranslation } from 'react-i18next';

import { Table } from './styled';

interface SizesModal {
  handleModalClose: () => void;
  type: ProductType | undefined;
}

const SizesModal = ({ handleModalClose, type }: SizesModal) => {
  const { t } = useTranslation();

  const renderedTable = () => {
    switch (type) {
      case ProductType.Male:
        return (
          <Table>
            <thead>
              <tr>
                <th>{t('product.size')}</th>
                <th>44</th>
                <th>46</th>
                <th>48</th>
                <th>50</th>
                <th>52</th>
                <th>54</th>
                <th>56</th>
                <th>58</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{t('product.sizesChart.chest')}</th>
                <td>89-92</td>
                <td>93-96</td>
                <td>97-100</td>
                <td>101-104</td>
                <td>105-108</td>
                <td>109-112</td>
                <td>113-116</td>
                <td>117-120</td>
              </tr>
              <tr>
                <th>{t('product.sizesChart.waist')}</th>
                <td>83-86</td>
                <td>87-90</td>
                <td>91-94</td>
                <td>95-98</td>
                <td>99-102</td>
                <td>103-106</td>
                <td>107-110</td>
                <td>111-114</td>
              </tr>
              <tr>
                <th>{t('product.sizesChart.back')}</th>
                <td>57</td>
                <td>57,5</td>
                <td>58</td>
                <td>58,5</td>
                <td>59</td>
                <td>59,5</td>
                <td>60</td>
                <td>60,5</td>
              </tr>
            </tbody>
          </Table>
        );
      case ProductType.Female:
        return (
          <Table>
            <thead>
              <tr>
                <th>{t('product.size')}</th>
                <th>34</th>
                <th>36</th>
                <th>38</th>
                <th>40</th>
                <th>42</th>
                <th>44</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{t('product.sizesChart.chest')}</th>
                <td>77-80</td>
                <td>81-84</td>
                <td>85-88</td>
                <td>89-92</td>
                <td>93-96</td>
                <td>97-100</td>
              </tr>
              <tr>
                <th>{t('product.sizesChart.waist')}</th>
                <td>59-62</td>
                <td>63-66</td>
                <td>67-70</td>
                <td>71-74</td>
                <td>75-78</td>
                <td>79-82</td>
              </tr>
              <tr>
                <th>{t('product.sizesChart.back')}</th>
                <td>48</td>
                <td>48,5</td>
                <td>49</td>
                <td>49,5</td>
                <td>50</td>
                <td>50,5</td>
              </tr>
            </tbody>
          </Table>
        );
      case ProductType.Kid:
        return (
          <Table>
            <thead>
              <tr>
                <th>{t('product.size')}</th>
                <th>104</th>
                <th>110</th>
                <th>116</th>
                <th>122</th>
                <th>128</th>
                <th>134</th>
                <th>140</th>
                <th>146</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{t('product.sizesChart.chest')}</th>
                <td>55-57</td>
                <td>56-58</td>
                <td>57-59</td>
                <td>58-62</td>
                <td>61-65</td>
                <td>64-68</td>
                <td>67-71</td>
                <td>70-74</td>
              </tr>
              <tr>
                <th>{t('product.sizesChart.height')}</th>
                <td>99-104</td>
                <td>105-110</td>
                <td>111-116</td>
                <td>117-122</td>
                <td>123-128</td>
                <td>129-134</td>
                <td>135-140</td>
                <td>141-146</td>
              </tr>
            </tbody>
          </Table>
        );

      default:
        break;
    }
  };

  return <Modal handleModalClose={handleModalClose}>{renderedTable()}</Modal>;
};

export default SizesModal;
