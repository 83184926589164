export interface Material {
  id?: number;
  name: string;
  quantity: number;
  icon_url: string;
  back_image_url: string;
  oversize_back_image_url: string | null;
  oversize_collar_image_url: string | null;
  single_addon_url: string;
  addon_with_collar_url: string;
  female: number;
  created_at?: Date;
  updated_at?: Date;
}

export interface Fleece {
  id?: number;
  name: string;
  quantity: number;
  image_url: string;
  icon_url: string;
  back_image_url: string;
  back_female_image_url: string;
  created_at?: Date;
  updated_at?: Date;
}

export interface Cut {
  id?: number;
  name: string;
  has_collar: string;
  female: number;
  oversize: string;
  created_at?: Date;
  updated_at?: Date;
}

export interface Button {
  id?: number;
  name: string;
  icon_url: string;
  female: number;
  oversize: string;
  created_at?: Date;
  updated_at?: Date;
}

export const isMaterial = (object: unknown): object is Material => {
  return Object.prototype.hasOwnProperty.call(object, 'addon_with_collar_url');
};

export const isCut = (object: unknown): object is Cut => {
  return Object.prototype.hasOwnProperty.call(object, 'has_collar');
};

export const isFleece = (object: unknown): object is Fleece => {
  return Object.prototype.hasOwnProperty.call(object, 'back_female_image_url');
};
