import styled from 'styled-components';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { device } from 'theme/theme';

export const StepperContainer = styled.div`
  display: block;

  @media ${device.mobile} {
    display: none;
  }
`;

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
  }),
);
