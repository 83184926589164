import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from 'axiosConfig';
import { FetchedOrder, OrderStatus } from 'api/models/Order';

export const fetchOrders = createAsyncThunk(
  'admin/orders/fetchOrders',
  async () => {
    const response = await axiosInstance.get<FetchedOrder[]>('orders');
    return response.data;
  },
);

export const fetchOrderById = createAsyncThunk<
  FetchedOrder,
  number,
  { rejectValue: string }
>('admin/orders/fetchOrderById', async (id, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get<FetchedOrder>(`orders/${id}`);

    return response.data;
  } catch (error: any) {
    if (!error.message) throw error;

    return rejectWithValue(error.message);
  }
});

export const deleteOrders = createAsyncThunk<
  number[],
  number[],
  { rejectValue: string }
>('admin/orders/deleteOrders', async (ids, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.delete<number[]>('orders', {
      data: {
        ids,
      },
    });

    return response.data;
  } catch (error: any) {
    if (!error.message) throw error;

    return rejectWithValue(error.message);
  }
});

export const updateOrders = createAsyncThunk<
  { updatedOrders: FetchedOrder[]; updatedIds: number[] },
  { ids: number[]; status: OrderStatus },
  { rejectValue: string }
>('admin/orders/updateOrders', async ({ ids, status }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.put<{
      updatedOrders: FetchedOrder[];
      updatedIds: number[];
    }>('orders', {
      ids,
      newStatus: status,
    });

    const { updatedOrders, updatedIds } = response.data;
    return { updatedOrders, updatedIds };
  } catch (error: any) {
    if (!error.message) throw error;

    return rejectWithValue(error.message);
  }
});
