import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectGalleryState,
  setSelectedImages,
  toggleSelectedImages,
  closeSnackbar,
  setError,
} from './redux/gallerySlice';
import { fetchGalleryImages, deleteImages, uploadImages } from './redux/thunk';
import clsx from 'clsx';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { ImageUploader } from 'admin/components/ImageUploader';
import { ConfirmationDialog } from 'admin/components/ConfirmationDialog';
import { LoadingDialog } from 'admin/components/LoadingDialog';
import { CustomButton } from 'admin/components/CustomButton';
import { Loader } from 'admin/components/Loader';
import { DashboardContainer } from 'theme/global';
import { ErrorMessage } from 'admin/components/ErrorMessage';
import { CustomToolbar } from 'admin/components/CustomToolbar';
import { ImCheckboxChecked } from 'react-icons/im';
import { ImagesContainer, ImageWrapper, Image, SelectedIcon } from './styled';

export const GalleryContainer: React.FC = () => {
  const dispatch = useDispatch();

  const {
    isLoading,
    error,
    images,
    selectedImages,
    snackbarOpen,
    snackbarText,
    uploadPercentage,
    isUploading,
  } = useSelector(selectGalleryState);
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    useState<boolean>(false);
  const [imagesToUpload, setImagesToUpload] = useState<FileList>();

  useEffect(() => {
    dispatch(fetchGalleryImages());
  }, [dispatch]);

  useEffect(() => {
    if (!imagesToUpload) return;

    if (imagesToUpload.length > 5) {
      dispatch(setError('Przekroczony maksymalny limit 5 zdjęć do dodania'));
    } else {
      dispatch(setError(undefined));
    }
  }, [dispatch, imagesToUpload]);

  useEffect(() => {
    if (uploadPercentage < 100) return;

    setImagesToUpload(undefined);
  }, [uploadPercentage]);

  const isSelected = (id: number) => selectedImages.includes(id);

  const handleDelete = () => {
    setConfirmationDialogOpen(false);
    dispatch(deleteImages(selectedImages));
  };

  const handleClick = (id: number) => {
    dispatch(toggleSelectedImages(id));
  };

  const handleSelectAll = () => {
    const newSelected = images.map((image) => Number(image.id));
    dispatch(setSelectedImages(newSelected));
  };

  const handleDeselectAll = () => {
    dispatch(setSelectedImages([]));
  };

  const handleUploadImages = () => {
    if (!imagesToUpload || imagesToUpload.length > 5) return;

    dispatch(uploadImages(imagesToUpload));
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <DashboardContainer>
          {error && <ErrorMessage>{error}</ErrorMessage>}

          <ImageUploader
            setImages={setImagesToUpload}
            title="Dodaj nowe zdjęcia"
          />

          {imagesToUpload && imagesToUpload.length > 0 && (
            <CustomButton topMargin="2rem" handleClick={handleUploadImages}>
              Zapisz
            </CustomButton>
          )}

          <CustomToolbar
            numSelected={selectedImages.length}
            title="Aktualne zdjęcia"
            handleDelete={() => setConfirmationDialogOpen(true)}
          />
          {selectedImages.length > 0 && (
            <>
              <CustomButton topMargin="2rem" handleClick={handleSelectAll}>
                Zaznacz wszystkie
              </CustomButton>
              <CustomButton
                topMargin="2rem"
                leftMargin="1rem"
                handleClick={handleDeselectAll}
              >
                Odznacz wszystkie
              </CustomButton>
            </>
          )}

          <ImagesContainer>
            {images.map((image) => (
              <ImageWrapper
                key={image.id}
                className={clsx({ selected: isSelected(Number(image.id)) })}
                onClick={() => handleClick(Number(image.id))}
              >
                <Image src={image.url} />

                <SelectedIcon>
                  <ImCheckboxChecked />
                </SelectedIcon>
              </ImageWrapper>
            ))}
          </ImagesContainer>
        </DashboardContainer>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => dispatch(closeSnackbar())}
      >
        <Alert onClose={() => dispatch(closeSnackbar())} severity="success">
          {snackbarText}
        </Alert>
      </Snackbar>

      <ConfirmationDialog
        isOpen={confirmationDialogOpen}
        closeDialog={() => setConfirmationDialogOpen(false)}
        declineButton="Anuluj"
        acceptButton="Usuń"
        title="Czy na pewno chcesz usunąć wybrane zdjęcia?"
        text="Wybrane zdjęcia zostaną usunięte. Tej operacji nie można cofnąć."
        handleAccept={handleDelete}
      />

      <LoadingDialog
        title="Dodawanie zdjęć..."
        isOpen={isUploading}
        progress={uploadPercentage}
      />
    </>
  );
};
