import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Size } from 'api/models/Size';
import { RootState } from 'store';
import { fetchSizes, deleteSizes, addSize } from './thunk';

interface SizesState {
  isLoading: boolean;
  error: string | undefined;
  sizes: Size[];
  selectedSizes: number[];
  snackbarOpen: boolean;
  snackbarText: string;
}

const initialState: SizesState = {
  isLoading: false,
  error: undefined,
  sizes: [],
  selectedSizes: [],
  snackbarOpen: false,
  snackbarText: '',
};

const sizesSlice = createSlice({
  name: 'sizes',
  initialState,
  reducers: {
    setSelected: (state, action: PayloadAction<number[]>) => {
      state.selectedSizes = action.payload;
    },
    toggleSelected: (state, action: PayloadAction<number>) => {
      if (state.selectedSizes.includes(action.payload)) {
        state.selectedSizes = state.selectedSizes.filter(
          (selected) => selected !== action.payload,
        );
      } else {
        state.selectedSizes.push(action.payload);
      }
    },
    closeSnackbar: (state) => {
      state.snackbarOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSizes.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(fetchSizes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.sizes = action.payload;
    });
    builder.addCase(fetchSizes.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    builder.addCase(deleteSizes.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(deleteSizes.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.sizes = state.sizes.filter((size) => !payload.includes(size.id));
      state.selectedSizes = state.selectedSizes.filter(
        (selectedSize) => !payload.includes(selectedSize),
      );

      state.snackbarOpen = true;
      state.snackbarText = 'Pomyślnie usunięto rozmiar';
    });
    builder.addCase(deleteSizes.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    builder.addCase(addSize.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(addSize.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.sizes.push(payload);

      state.snackbarOpen = true;
      state.snackbarText = 'Pomyślnie dodano nowy rozmiar';
    });
    builder.addCase(addSize.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });
  },
});

export const { setSelected, toggleSelected, closeSnackbar } =
  sizesSlice.actions;

export const selectSizesState = (state: RootState) => state.sizes;

export default sizesSlice.reducer;
