import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCoupons, deleteCoupons } from './redux/thunk';
import {
  selectCouponsState,
  setSelected,
  toggleSelected,
  closeSnackbar,
} from './redux/couponsSlice';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { CustomToolbar } from 'admin/components/CustomToolbar';

import { ErrorMessage } from 'admin/components/ErrorMessage';
import { DashboardContainer } from 'theme/global';
import { Text } from 'theme/typography';
import { Loader } from 'admin/components/Loader';
import { useStyles } from './styled';
import { AddCouponForm } from './AddCouponForm';

export const CouponsContainer = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    isLoading,
    error,
    coupons,
    selectedCoupons,
    snackbarOpen,
    snackbarText,
  } = useSelector(selectCouponsState);

  useEffect(() => {
    dispatch(fetchCoupons());
  }, [dispatch]);

  const handleAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = coupons.map((coupon) => coupon.id);
      dispatch(setSelected(newSelected));
      return;
    }

    dispatch(setSelected([]));
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    dispatch(toggleSelected(id));
  };

  const isSelected = (id: number) => selectedCoupons.includes(id);

  const handleDeleteClick = () => {
    dispatch(deleteCoupons(selectedCoupons));
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <DashboardContainer>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {coupons.length > 0 ? (
            <Paper>
              <CustomToolbar
                handleDelete={handleDeleteClick}
                numSelected={selectedCoupons.length}
                title="Lista kodów promocyjnych"
              />
              <TableContainer>
                <Table aria-labelledby="couponsTable" size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={
                            selectedCoupons.length > 0 &&
                            selectedCoupons.length < coupons.length
                          }
                          checked={
                            coupons.length > 0 &&
                            selectedCoupons.length === coupons.length
                          }
                          className={classes.checkbox}
                          onChange={handleAllClick}
                        />
                      </TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell>Nazwa</TableCell>
                      <TableCell>Rabat</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {coupons.map((coupon) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={coupon.id}
                        onClick={(event) => handleClick(event, coupon.id)}
                        selected={isSelected(coupon.id)}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isSelected(coupon.id)}
                            className={classes.checkbox}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {coupon.id}
                        </TableCell>
                        <TableCell>{coupon.name}</TableCell>
                        <TableCell>{coupon.discount}%</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ) : (
            <Text>Brak dodanych kodów promocyjnych</Text>
          )}

          <AddCouponForm />
        </DashboardContainer>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => dispatch(closeSnackbar())}
      >
        <Alert onClose={() => dispatch(closeSnackbar())} severity="success">
          {snackbarText}
        </Alert>
      </Snackbar>
    </>
  );
};
