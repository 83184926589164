import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  selectBestsellersLoading,
  selectBestsellers,
  selectOutletProductsLoading,
  selectOutletProducts,
} from './redux/selectors';
import { fetchBestsellers, fetchOutletProducts } from './redux/thunk';
import { ProductType } from 'api/models/Product';
import { Guide } from 'api/models/Guide';
import { selectGuides } from 'containers/Guides/redux/selectors';

import ImageCarousel from 'components/ImageCarousel';
import {
  MainContainer,
  SectionTitle,
  SlidesSection,
  ShopSection,
  BestsellersSection,
  ShopTile,
  ShopTileImage,
  ShopTileText,
  BestsellerTile,
  BestsellerImage,
  BestsellerName,
  BestsellerPrice,
  useStyles,
  LoaderContainer,
  AdditionalSectionContainer,
  GuideTile,
  GuideTileImage,
  GuideTileText,
  GuideButtonContainer,
} from './styled';
import { OutlinedButton } from 'theme/global';
import manImage from 'assets/landing/man.jpg';
import womanImage from 'assets/landing/woman.jpg';
import kidImage from 'assets/landing/kid.jpg';
import slide1Image from 'assets/landing/slide1.jpg';
import slide2Image from 'assets/landing/slide2.jpg';
import slide3Image from 'assets/landing/slide3.jpg';
import Skeleton from '@material-ui/lab/Skeleton';
import ClipLoader from 'react-spinners/ClipLoader';
import AdditionalInfoSection from 'components/AdditionalInfoSection';

const Landing = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStyles();

  const bestsellersLoading = useAppSelector(selectBestsellersLoading);
  const bestsellers = useAppSelector(selectBestsellers);
  const guides = useAppSelector(selectGuides);
  const outletProductsLoading = useAppSelector(selectOutletProductsLoading);
  const outletProducts = useAppSelector(selectOutletProducts);

  const [loadedImages, setLoadedImages] = useState<number[]>([]);
  const [outletLoadedImages, setOutletLoadedImages] = useState<number[]>([]);

  useEffect(() => {
    dispatch(fetchBestsellers());
    dispatch(fetchOutletProducts());
  }, [dispatch]);

  const handleImageOnLoad = (idx: number) => {
    setLoadedImages((previousImages) => [...previousImages, idx]);
  };

  const handleOutletImageOnLoad = (idx: number) => {
    setOutletLoadedImages((previousImages) => [...previousImages, idx]);
  };

  return (
    <MainContainer topMargin>
      <SlidesSection>
        <ImageCarousel
          images={[slide1Image, slide2Image, slide3Image]}
          fullWidth
        />
      </SlidesSection>

      <SectionTitle>{t('landing.vestTitle')}</SectionTitle>
      <ShopSection>
        <ShopTile to={`/products/${ProductType.Male}`}>
          <ShopTileImage src={manImage} alt="Męskie" />
          <ShopTileText>{t('landing.manVests')}</ShopTileText>
        </ShopTile>

        <ShopTile to={`/products/${ProductType.Female}`}>
          <ShopTileImage src={womanImage} alt="Damskie" />
          <ShopTileText>{t('landing.womanVests')}</ShopTileText>
        </ShopTile>

        <ShopTile to={`/products/${ProductType.Kid}`}>
          <ShopTileImage src={kidImage} alt="Dzieci" />
          <ShopTileText>{t('landing.kidsVest')}</ShopTileText>
        </ShopTile>
      </ShopSection>

      {bestsellersLoading ||
        (bestsellers.length > 0 && (
          <>
            <SectionTitle>Bestseller</SectionTitle>
            {bestsellersLoading && (
              <LoaderContainer>
                <ClipLoader loading size={32} color="#DABC60" />
              </LoaderContainer>
            )}
            <BestsellersSection>
              {bestsellers.map((product) => (
                <BestsellerTile key={product.id}>
                  {!loadedImages.includes(product.id) && (
                    <Skeleton
                      variant="rect"
                      animation="wave"
                      className={classes.skeleton}
                    />
                  )}

                  <Link to={`/product/${product.id}`}>
                    <BestsellerImage
                      src={product.images[0].url}
                      alt={product.name}
                      onLoad={() => handleImageOnLoad(product.id)}
                      isLoading={!loadedImages.includes(product.id)}
                    />
                    <BestsellerName>{product.name}</BestsellerName>
                    <BestsellerPrice isOnSale={product.isOnSale}>
                      {product.price} PLN
                    </BestsellerPrice>

                    {product.isOnSale ? (
                      <BestsellerPrice>
                        {product.discountPrice} PLN
                      </BestsellerPrice>
                    ) : (
                      <></>
                    )}
                  </Link>
                </BestsellerTile>
              ))}
            </BestsellersSection>
          </>
        ))}

      {outletProductsLoading ||
        (outletProducts.length > 0 && (
          <>
            <SectionTitle>Outlet</SectionTitle>
            {outletProductsLoading && (
              <LoaderContainer>
                <ClipLoader loading size={32} color="#DABC60" />
              </LoaderContainer>
            )}
            <BestsellersSection>
              {outletProducts.map((product) => (
                <BestsellerTile key={product.id}>
                  {!outletLoadedImages.includes(product.id) && (
                    <Skeleton
                      variant="rect"
                      animation="wave"
                      className={classes.skeleton}
                    />
                  )}

                  <Link to={`/product/${product.id}`}>
                    <BestsellerImage
                      src={product.images[0].url}
                      alt={product.name}
                      onLoad={() => handleOutletImageOnLoad(product.id)}
                      isLoading={!outletLoadedImages.includes(product.id)}
                    />
                    <BestsellerName>{product.name}</BestsellerName>
                    <BestsellerPrice isOnSale={product.isOnSale}>
                      {product.price} PLN
                    </BestsellerPrice>

                    {product.isOnSale ? (
                      <BestsellerPrice>
                        {product.discountPrice} PLN
                      </BestsellerPrice>
                    ) : (
                      <></>
                    )}
                  </Link>
                </BestsellerTile>
              ))}
            </BestsellersSection>
          </>
        ))}

      {guides && guides.length > 0 && (
        <>
          <SectionTitle>{t('landing.guideTitle')}</SectionTitle>
          <ShopSection $fromStart={guides.length < 3}>
            {guides.slice(0, 3).map((guide: Guide) => (
              <GuideTile key={guide.id} to={`/guides/${guide.data.handle}`}>
                <GuideTileImage
                  src={guide.data.thumbnail}
                  alt={guide.data.title}
                />
                <GuideTileText>{guide.data.title}</GuideTileText>
              </GuideTile>
            ))}
          </ShopSection>

          <GuideButtonContainer>
            <OutlinedButton onClick={() => history.push('/guides')}>
              Zobacz więcej
            </OutlinedButton>
          </GuideButtonContainer>
        </>
      )}

      <AdditionalSectionContainer>
        <AdditionalInfoSection />
      </AdditionalSectionContainer>
    </MainContainer>
  );
};

export default Landing;
