import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { OrderStatus } from 'api/models/Order';

interface UpdateDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
  handleAccept: (status: OrderStatus | undefined) => void;
}

export const UpdateDialog: React.FC<UpdateDialogProps> = (props) => {
  const { isOpen, closeDialog, handleAccept } = props;

  const [status, setStatus] = useState<OrderStatus>();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as OrderStatus);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Uaktualnij status</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Wybierz nowy status dla wybranych zamówień
          </DialogContentText>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">
              Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={status || ''}
              onChange={handleChange}
              label="Status"
            >
              <MenuItem value="">
                <em>Brak</em>
              </MenuItem>
              <MenuItem value={OrderStatus.Sent}>{OrderStatus.Sent}</MenuItem>
              <MenuItem value={OrderStatus.Paid}>{OrderStatus.Paid}</MenuItem>
              <MenuItem value={OrderStatus.Pending}>
                {OrderStatus.Pending}
              </MenuItem>
              <MenuItem value={OrderStatus.Canceled}>
                {OrderStatus.Canceled}
              </MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Anuluj
          </Button>
          <Button onClick={() => handleAccept(status)} color="primary">
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
