import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from 'axiosConfig';
import { Size } from 'api/models/Size';

export const fetchSizes = createAsyncThunk<
  Size[],
  any,
  { rejectValue: string }
>('admin/sizes/fetchSizes', async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get<Size[]>('sizes');

    return response.data;
  } catch (error: any) {
    if (!error.message) throw error;

    return rejectWithValue(error.message);
  }
});

export const deleteSizes = createAsyncThunk<
  number[],
  number[],
  { rejectValue: string }
>('admin/sizes/deleteSizes', async (ids, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.delete<{ sizeIds: number[] }>(
      'sizes',
      {
        data: {
          sizeIds: ids,
        },
      },
    );

    return response.data.sizeIds;
  } catch (error: any) {
    if (!error.message) throw error;

    return rejectWithValue(error.message);
  }
});

export const addSize = createAsyncThunk<Size, string, { rejectValue: string }>(
  'admin/sizes/addSize',
  async (sizeName, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<Size>('sizes', {
        sizeName,
      });

      return response.data;
    } catch (error: any) {
      if (!error.message) throw error;

      return rejectWithValue(error.message);
    }
  },
);
