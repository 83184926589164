import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from 'axiosConfig';
import { Product, ProductToAdd, ProductType } from 'api/models/Product';
import { Size } from 'api/models/Size';
import { VoucherType } from 'api/models/VoucherType';
import { setUploadPercentage } from './productsSlice';

export const fetchProducts = createAsyncThunk<
  { products: Product[]; totalNumber: number },
  { page: number; pageSize: number },
  { rejectValue: string }
>(
  'amin/products/fetchProducts',
  async ({ page, pageSize }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `products?page=${page}&pageSize=${pageSize}`,
      );

      return {
        products: response.data.results,
        totalNumber: response.data.total,
      };
    } catch (error: any) {
      if (!error.message) throw error;

      return rejectWithValue(error.message);
    }
  },
);

export const deleteProducts = createAsyncThunk<
  number[],
  { productIds: number[] },
  { rejectValue: string }
>(
  'admin/products/deleteProducts',
  async ({ productIds }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`products`, {
        data: {
          productIds,
        },
      });

      return response.data.productIds;
    } catch (error: any) {
      if (!error.message) throw error;

      return rejectWithValue(error.message);
    }
  },
);

export const addProduct = createAsyncThunk<
  Product,
  {
    product: ProductToAdd;
    images: FileList;
    sizes?: Size[];
    voucherTypes?: VoucherType[];
  },
  { rejectValue: string }
>(
  'admin/products/addProduct',
  async (
    { product, images, sizes, voucherTypes },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const formData = new FormData();

      Object.entries(product).forEach(([key, value]) => {
        formData.append(key, value);
      });

      for (let i = 0; i < images.length; i += 1) {
        formData.append('product-images[]', images[i]);
      }

      if (product.type !== ProductType.Voucher) {
        formData.append('sizes', JSON.stringify(sizes));
      } else {
        formData.append('voucherTypes', JSON.stringify(voucherTypes));
      }

      const response = await axiosInstance.post<Product>('products', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          dispatch(setUploadPercentage(percentCompleted));
        },
      });

      return response.data;
    } catch (error: any) {
      if (!error.message) throw error;

      return rejectWithValue(error.message);
    }
  },
);

export const fetchProductById = createAsyncThunk<
  Product,
  number,
  { rejectValue: string }
>('admin/products/fetchProductById', async (id, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get<Product>(`products/${id}`);

    return response.data;
  } catch (error: any) {
    if (!error.message) throw error;

    return rejectWithValue(error.message);
  }
});

export const updateProduct = createAsyncThunk<
  Product,
  {
    id: number;
    product: ProductToAdd;
    images: FileList;
    sizes?: Size[];
    voucherTypes?: VoucherType[];
  },
  { rejectValue: string }
>(
  'admin/products/updateProduct',
  async (
    { id, product, images, sizes, voucherTypes },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const formData = new FormData();

      Object.entries(product).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if (images) {
        for (let i = 0; i < images.length; i += 1) {
          formData.append('product-images[]', images[i]);
        }
      }

      if (product.type !== ProductType.Voucher) {
        formData.append('sizes', JSON.stringify(sizes));
      } else {
        formData.append('voucherTypes', JSON.stringify(voucherTypes));
      }

      const response = await axiosInstance.put<Product>(
        `products/${id}`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent: ProgressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            dispatch(setUploadPercentage(percentCompleted));
          },
        },
      );

      return response.data;
    } catch (error: any) {
      if (!error.message) throw error;

      return rejectWithValue(error.message);
    }
  },
);

export const updateBestsellerValue = createAsyncThunk<
  Product,
  { id: number; newValue: boolean },
  { rejectValue: string }
>(
  'admin/products/updateBestsellerValue',
  async ({ id, newValue }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put<Product>(
        `products/bestseller/${id}`,
        { value: newValue },
      );

      if (response.status !== 200)
        throw new Error('Unexpected error, please try again later');

      return response.data;
    } catch (error: any) {
      if (!error.message) throw error;

      return rejectWithValue(error.message);
    }
  },
);

export const updateOutletValue = createAsyncThunk<
  Product,
  { id: number; newValue: boolean },
  { rejectValue: string }
>(
  'admin/products/updateOutletValue',
  async ({ id, newValue }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put<Product>(
        `products/outlet/${id}`,
        { value: newValue },
      );

      if (response.status !== 200)
        throw new Error('Unexpected error, please try again later');

      return response.data;
    } catch (error: any) {
      if (!error.message) throw error;

      return rejectWithValue(error.message);
    }
  },
);
