import { CustomProduct, ProductType } from './Product';

export enum Shipping {
  Local = '1',
  Worldwide = '2',
  Self = '3',
  Free = '4',
}

export enum OrderStatus {
  Canceled = 'Zamówienie anulowane',
  Pending = 'Oczekujące na płatność',
  Paid = 'Przyjęte do realizacji',
  Sent = 'Zamówienie wysłane',
}

export interface OrderProduct {
  id: number;
  size: string;
  quantity: number;
  price?: number;
  discountPrice?: number;
  name?: string;
  type?: ProductType;
}

export interface FetchedOrder {
  id: number;
  name: string;
  email: string;
  phone_number: string;
  address: string;
  city: string;
  post_code: string;
  additional_info: string;
  status: OrderStatus;
  user_id: number;
  discount: number;
  shipping: Shipping;
  price: number;
  finalPrice: number;
  products?: OrderProduct[];
  customProducts?: CustomProduct[];
  created_at: string;
  updated_at: string;
}

export interface Order {
  id?: number;
  name: string;
  email: string;
  phone_number: string;
  address: string;
  city: string;
  post_code: string;
  additional_info: string;
  status: OrderStatus;
  user_id: number;
  discount: number;
  shipping: Shipping;
  price: number;
  finalPrice?: number;
  products?: OrderProduct[];
  customProducts?: CustomProduct[];
  created_at?: string;
  updated_at?: string;
}
