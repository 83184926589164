import React from 'react';
import { useTranslation } from 'react-i18next';
import { P24PaymentMethod } from 'api/models/P24PaymentMethod';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Container, Title, PaymentMethodsGrid, PaymentMethod } from './styled';

interface PaymentProvidersProps {
  paymentMethods: P24PaymentMethod[];
  chosenPaymentMethod: number | undefined;
  setPaymentMethod: (id: number) => void;
}

// Some payment methods have to be excluded because they do not have valid images and data
const excludedMethods = [97, 105, 110, 135, 160];

function PaymentProviders({
  paymentMethods,
  chosenPaymentMethod,
  setPaymentMethod,
}: PaymentProvidersProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const availableMethods = isMobileScreen
    ? paymentMethods.filter(
        (method) =>
          method.status === true &&
          !excludedMethods.includes(method.id) &&
          method.mobile,
      )
    : paymentMethods.filter(
        (method) =>
          method.status === true && !excludedMethods.includes(method.id),
      );

  return (
    <Container>
      <Title>{t('payment.choosePayment')}</Title>

      <PaymentMethodsGrid>
        {availableMethods.map((method) => (
          <PaymentMethod
            src={isMobileScreen ? method.mobileImgUrl : method.imgUrl}
            alt={method.name}
            isActive={chosenPaymentMethod === method.id}
            onClick={() => setPaymentMethod(method.id)}
            key={method.id}
          />
        ))}
      </PaymentMethodsGrid>
    </Container>
  );
}

export default PaymentProviders;
