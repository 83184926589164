import { CartProduct } from 'containers/Cart/cartSlice';
import { isCustomProduct } from 'api/models/Product';

export const calculateOrderPrice = (cartProducts: CartProduct[]): number => {
  let price = 0;

  cartProducts.forEach((cartProduct) => {
    if (isCustomProduct(cartProduct.product)) {
      price += cartProduct.product.price;
    } else if (cartProduct.product.isVoucher) {
      const choosedVoucherType = cartProduct.product.voucherTypes.filter(
        (type) => type.name === cartProduct.size,
      );

      price += choosedVoucherType[0].price;
    } else {
      price +=
        (cartProduct.product.isOnSale
          ? cartProduct.product.discountPrice || cartProduct.product.price
          : cartProduct.product.price) * cartProduct.quantity;
    }
  });

  return price;
};

interface IVariable {
  [key: string]: string | boolean | number | undefined;
}

export const sendCustomGtmEvent = (name: string, variables?: IVariable) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: name, ...variables });
};
