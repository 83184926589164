import React from 'react';

interface ImageMagnifierProps {
  showMagnifier: boolean;
  position: { x: number; y: number };
  imgSize: { width: number; height: number };
  src: string;
  magnifierWidth?: number;
  magnifierHeight?: number;
  zoomLevel?: number;
}

function ImageMagnifier({
  showMagnifier,
  position,
  imgSize,
  src,
  magnifierHeight = 250,
  magnifierWidth = 250,
  zoomLevel = 2,
}: ImageMagnifierProps) {
  return (
    <div
      style={{
        display: showMagnifier ? '' : 'none',
        position: 'absolute',

        pointerEvents: 'none',

        height: `${magnifierHeight}px`,
        width: `${magnifierWidth}px`,

        borderRadius: '50%',

        top: `${position.y - magnifierHeight / 2}px`,
        left: `${position.x - magnifierWidth / 2}px`,
        opacity: '1',
        border: '1px solid lightgray',
        backgroundColor: 'white',
        backgroundImage: `url('${src}')`,
        backgroundRepeat: 'no-repeat',

        backgroundSize: `${imgSize.width * zoomLevel}px ${
          imgSize.height * zoomLevel
        }px`,

        backgroundPositionX: `${
          -position.x * zoomLevel + magnifierWidth / 2
        }px`,
        backgroundPositionY: `${
          -position.y * zoomLevel + magnifierHeight / 2
        }px`,
        zIndex: 1000,
      }}
    />
  );
}

export default ImageMagnifier;
