import { Size } from './Size';
import { VoucherType } from './VoucherType';

// eslint-disable-next-line
export enum ProductType {
  Male = 'male',
  Female = 'female',
  Kid = 'kid',
  Voucher = 'voucher',
  TshirtMale = 'tshirtMale',
  TshirtFemale = 'tshirtFemale',
}

export const getProducyType = (type: ProductType): string => {
  switch (type) {
    case ProductType.Female:
      return 'Kamizelka damska';
    case ProductType.Male:
      return 'Kamizelka męska';
    case ProductType.Kid:
      return 'Kamizelka dziecięca';
    case ProductType.Voucher:
      return 'Voucher';
    case ProductType.TshirtMale:
      return 'Koszulka męska';
    case ProductType.TshirtFemale:
      return 'Koszulka damska';
    default:
      return 'Kamizelka męska';
  }
};

export enum BackType {
  Material = 'tkanina',
  Fleece = 'podszewka',
}

export interface ProductImage {
  id: number;
  url: string;
}

export interface Product {
  id: number;
  name: string;
  shortDescription?: string;
  longDescription?: string;
  materials?: string;
  shortEnglishDescription?: string;
  longEnglishDescription?: string;
  englishMaterials?: string;
  price: number;
  discountPrice?: number;
  type?: ProductType;
  images: ProductImage[];
  isOnSale: boolean;
  isVoucher: boolean;
  sizes: Size[];
  voucherTypes: VoucherType[];
  isBestseller?: boolean;
  isOutlet?: boolean;
  created_at?: Date;
  updated_at?: Date;
}

export interface CustomProduct {
  id?: number;
  name: string;
  price: number;
  material: string;
  fleece: string;
  buttons: string;
  cut: string;
  back: BackType;
  type: ProductType;
  size: string;
}

export interface ProductToAdd {
  name: string;
  shortDescription: string;
  longDescription: string;
  materials: string;
  shortEnglishDescription: string;
  longEnglishDescription: string;
  englishMaterials: string;
  price: number;
  discountPrice?: number;
  type: ProductType;
  // sizes: { sizeName: string }[];
}

// Function that checks if object extends CustomProduct interface
export const isCustomProduct = (object: unknown): object is CustomProduct => {
  return Object.prototype.hasOwnProperty.call(object, 'material');
};
