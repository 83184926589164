import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addSize } from './redux/thunk';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { CustomButton } from 'admin/components/CustomButton';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    marginTop: '4rem',
  },
  input: {
    display: 'block',
    marginRight: '1rem',
  },
}));

export const AddSizeForm: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [newSize, setNewSize] = useState<string>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (error === '') return;

    if (newSize !== '') setError('');
  }, [error, newSize]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (newSize === '') {
      setError('Rozmiar nie może być pusty');
      return;
    }

    dispatch(addSize(newSize));
  };

  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <TextField
        variant="outlined"
        label="Nowy rozmiar"
        name="size"
        id="size"
        size="small"
        className={classes.input}
        value={newSize || ''}
        onChange={(e) => setNewSize(e.target.value)}
        error={error !== ''}
        helperText={error}
      />
      <CustomButton type="submit">Dodaj</CustomButton>
    </form>
  );
};
