import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  fetchProductById,
  selectCurrentProduct,
  selectIsLoading,
} from '../productsSlice';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { addToCart, CartProduct } from 'containers/Cart/cartSlice';

import Loader from 'components/Loader';
import SizesModal from 'components/Modal/Sizes';
import { Size as SizeModel } from 'api/models/Size';
import {
  ProductContainer,
  MainSection,
  AdditionalSection,
  ImagesContainer,
  Image,
  ArrowIcon,
  Content,
  Title,
  Subtitle,
  Description,
  Price,
  DiscountPrice,
  QuantityContainer,
  QuantityButton,
  CartButton,
  CartMessage,
  useStyles,
} from './styled';
import { Size } from 'theme/global';
import ReactPixel from 'react-facebook-pixel';
import { getProducyType, ProductType } from 'api/models/Product';
import AdditionalInfoSection from 'components/AdditionalInfoSection';
import PaymentLogos from './PaymentLogos';
import Button from '@material-ui/core/Button';
import { sendCustomGtmEvent } from 'utils/helperFunctions';
import { GTM_EVENTS } from 'types/enums';
import ImageMagnifier from 'components/ImageMagnifier';

interface ParamTypes {
  id: string;
}

const ProductInfo = () => {
  const { id } = useParams<ParamTypes>();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const isLoading = useSelector(selectIsLoading);
  const product = useSelector(selectCurrentProduct);
  const [choosedSize, setChoosedSize] = useState<SizeModel>();
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [sizesModalOpen, setSizesModalOpen] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number>(1);
  const [cartMessage, setCartMessage] = useState<boolean>(false);
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [[x, y], setXY] = useState([0, 0]);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);

  useEffect(() => {
    dispatch(fetchProductById(id));

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!product) return;

    sendCustomGtmEvent(GTM_EVENTS.ProductView, {
      product_name: product.name,
      product_id: product.id,
    });
  }, [product]);

  useEffect(() => {
    if (!product || String(product.id) !== id) return;

    ReactPixel.track('ViewContent', {
      id: product.id,
      image_link: `https://scudetto.store${product.images[0].url}`,
      title: product.name,
      price: product.isOnSale ? product.discountPrice : product.price,
      availability: 'Available',
      description: product.shortDescription,
      link: window.location.href,
      content_ids: [product.id],
      content_category: getProducyType(product.type as ProductType),
      content_name: product.name,
      content_type: 'product',
      currency: 'PLN',
      value: product.isOnSale ? product.discountPrice : product.price,
    });
  }, [product, id]);

  useEffect(() => {
    if (!cartMessage) return;

    const timeout = setTimeout(() => {
      setCartMessage(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [cartMessage]);

  const handleSizeClick = (size: SizeModel) => {
    if (!size.quantity || size.quantity <= 0 || size.quantity < quantity)
      return;

    setChoosedSize(size);
  };

  const handleImageChange = (direction: 'right' | 'left') => {
    if (!product) return;

    let newImage = currentImage;

    if (direction === 'right') {
      newImage += 1;
    } else {
      newImage -= 1;
    }

    if (newImage < 0) newImage = product.images.length - 1;
    if (product && newImage >= product.images.length) newImage = 0;

    setCurrentImage(newImage);
  };

  const increaseQuantity = () => {
    if (
      choosedSize &&
      choosedSize.quantity &&
      quantity >= choosedSize.quantity
    ) {
      alert('Wybrano maksymalną dostępną ilosć dla tego rozmiaru');
      return;
    }

    setQuantity((q) => q + 1);
  };

  const decreaseQuantity = () => {
    if (quantity === 1) return;

    setQuantity((q) => q - 1);
  };

  const handleCartButton = () => {
    if (!choosedSize || !product) {
      alert('Wybierz rozmiar');
      return;
    }

    const productToAdd: CartProduct = {
      product,
      size: choosedSize.sizeName,
      quantity,
    };

    sendCustomGtmEvent(GTM_EVENTS.AddToCart, {
      product_name: productToAdd.product.name,
      product_id: productToAdd.product.id,
      product_size: productToAdd.size,
      product_from_configurator: false,
    });

    dispatch(addToCart(productToAdd));
    setCartMessage(true);
  };

  const renderedImages =
    product &&
    product.images.map((image, idx) => (
      <Image
        key={idx}
        className={clsx({
          'is-active': currentImage === idx,
          relative: idx === 0,
        })}
        src={image.url}
        alt="product"
        onMouseEnter={(e) => {
          // update image size and turn-on magnifier
          const elem = e.currentTarget;
          const { width, height } = elem.getBoundingClientRect();
          setSize([width, height]);
          setShowMagnifier(true);
        }}
        onMouseLeave={() => {
          setShowMagnifier(false);
        }}
        onMouseMove={(e) => {
          // update cursor position
          const elem = e.currentTarget;
          const { top, left } = elem.getBoundingClientRect();

          // calculate cursor position on the image
          const x = e.pageX - left - window.pageXOffset;
          const y = e.pageY - top - window.pageYOffset;
          setXY([x, y]);
        }}
      />
    ));

  const renderedSizes = () => {
    if (!product) return;

    if (product.isVoucher) {
      return (
        <>
          {product.voucherTypes.map((type) => (
            <Size
              className={clsx({
                'is-active': choosedSize && choosedSize.sizeName === type.name,
              })}
              key={type.id}
              onClick={() =>
                setChoosedSize({
                  sizeName: type.name,
                  quantity: 1,
                } as SizeModel)
              }
            >
              {type.name}
            </Size>
          ))}
        </>
      );
    }

    return (
      <>
        {product.sizes.map((size) => (
          <Size
            className={clsx({
              'is-active':
                choosedSize && choosedSize.sizeName === size.sizeName,
              disabled:
                !size.quantity ||
                size.quantity <= 0 ||
                size.quantity < quantity,
            })}
            key={size.sizeName}
            onClick={() => handleSizeClick(size)}
          >
            {size.sizeName}
          </Size>
        ))}
      </>
    );
  };

  const renderedPrice = () => {
    if (!product) return;

    if (product.isVoucher) {
      if (choosedSize && choosedSize.sizeName !== '') {
        const choosedVoucherType = product.voucherTypes.filter(
          (type) => type.name === choosedSize.sizeName,
        );

        return <Price>{choosedVoucherType[0].price} PLN</Price>;
      }

      return <Price>0 PLN</Price>;
    }

    return (
      <>
        <Price sale={product.isOnSale}>{product.price} PLN</Price>
        {product.isOnSale === true && (
          <DiscountPrice>{product.discountPrice} PLN</DiscountPrice>
        )}
      </>
    );
  };

  return (
    <ProductContainer>
      <MainSection>
        {product && (
          <>
            <ImagesContainer>
              <ArrowIcon onClick={() => handleImageChange('left')}>
                <MdKeyboardArrowLeft />
              </ArrowIcon>

              {renderedImages}

              <ImageMagnifier
                src={product.images[currentImage].url}
                zoomLevel={2}
                showMagnifier={showMagnifier}
                position={{ x, y }}
                imgSize={{ width: imgWidth, height: imgHeight }}
              />

              <ArrowIcon onClick={() => handleImageChange('right')}>
                <MdKeyboardArrowRight />
              </ArrowIcon>
            </ImagesContainer>

            <Content>
              <>
                <Title>{product.name}</Title>
                {renderedPrice()}
                <Description>
                  {i18n.language === 'pl'
                    ? product.longDescription
                    : product.longEnglishDescription}
                </Description>

                <Subtitle>
                  {product.isVoucher
                    ? t('product.voucherType')
                    : t('product.size')}
                </Subtitle>
                <div>{renderedSizes()}</div>

                {!product.isVoucher ? (
                  <>
                    <QuantityContainer>
                      <QuantityButton onClick={decreaseQuantity}>
                        -
                      </QuantityButton>
                      <QuantityButton className="disabled">
                        {quantity}
                      </QuantityButton>
                      <QuantityButton onClick={increaseQuantity}>
                        +
                      </QuantityButton>
                    </QuantityContainer>

                    <Button
                      className={classes.sizeChartButton}
                      onClick={() => setSizesModalOpen(true)}
                    >
                      {t('product.sizesButton')}
                    </Button>

                    <CartButton onClick={handleCartButton}>
                      {t('buttons.addToCart')}
                    </CartButton>
                  </>
                ) : (
                  <>
                    <CartButton onClick={handleCartButton}>
                      {t('buttons.addToCart')}
                    </CartButton>
                  </>
                )}

                {cartMessage && (
                  <Link to="/cart">
                    <CartMessage className={clsx({ 'is-active': cartMessage })}>
                      {t('cart.addedToCart')}
                    </CartMessage>
                  </Link>
                )}

                <PaymentLogos />
              </>
            </Content>
          </>
        )}
      </MainSection>

      <AdditionalSection>
        <AdditionalInfoSection />
      </AdditionalSection>

      {isLoading && <Loader />}
      {sizesModalOpen && (
        <SizesModal
          handleModalClose={() => setSizesModalOpen(false)}
          type={product?.type}
        />
      )}
    </ProductContainer>
  );
};

export default ProductInfo;
