import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectCart, selectCoupon } from 'containers/Cart/cartSlice';
import { useHistory } from 'react-router-dom';
import {
  selectOrder,
  updateOrderStatus,
} from 'containers/Checkout/checkoutSlice';
import { useTranslation } from 'react-i18next';
import {
  registerTransaction,
  fetchPaymentMethods,
  selectIsLoading,
  selectError,
  selectPayUrl,
  selectPaymentSession,
  selectPaymentMethods,
} from './paymentSlice';

import {
  PaymentContainer,
  Summary,
  SummaryTitle,
  SummaryText,
  PayButton,
  PayPalContainer,
  CancelButton,
  Link,
  RequiredField,
} from './styled';
import { resetCheckoutState } from 'containers/App/actions';
import { OrderStatus } from 'api/models/Order';
import { Button } from 'theme/global';
import ProgressStepper from 'components/ProgressStepper';
import SummaryTable from './SummaryTable';
import Loader from 'components/Loader';
import { PayPalButton } from 'react-paypal-button-v2';
import PaymentProviders from './PaymentProviders';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { sendCustomGtmEvent } from 'utils/helperFunctions';
import { GTM_EVENTS } from 'types/enums';

function Payment() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const cart = useSelector(selectCart);
  const coupon = useSelector(selectCoupon);
  const order = useSelector(selectOrder);
  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);
  const payUrl = useSelector(selectPayUrl);
  const paymentSession = useSelector(selectPaymentSession);
  const paymentMethods = useSelector(selectPaymentMethods);

  const [paymentMethod, setPaymentMethod] = useState<number>();
  const [p24TermsChecked, setP24TermsChecked] = useState(false);
  const [buyClicked, setBuyClicked] = useState(false);

  const currentLanguage = i18n.language;

  useEffect(() => {
    if (!error) return;

    alert(`Wystąpił bląd: ${error}. Spróbuj ponownie później.`);
  }, [error]);

  useEffect(() => {
    if (!payUrl) return;

    window.location.href = payUrl;
  }, [payUrl]);

  // Fetch available payment methods based on the language
  useEffect(() => {
    const lang =
      currentLanguage === 'pl' || currentLanguage === 'en'
        ? currentLanguage
        : 'pl';

    dispatch(fetchPaymentMethods(lang));
  }, [dispatch, currentLanguage]);

  const handlePayment = () => {
    if (!order || !order.finalPrice || !paymentMethod) {
      alert('Wybierz metodę płatności!');
      return;
    }

    if (!buyClicked) setBuyClicked(true);

    if (!p24TermsChecked) return;

    sendCustomGtmEvent(GTM_EVENTS.Purchase);

    dispatch(
      registerTransaction({
        transaction: {
          amount: order.finalPrice,
          country: 'PL',
          currency: 'PLN',
          email: order.email,
          description: `Platnosc za zamowienie numer ${order.id}`,
          language: 'pl',
          method: paymentMethod,
          regulationAccept: p24TermsChecked,
        },
        paymentId: paymentSession || '',
        orderId: order.id || -1,
      }),
    );
  };

  const handleSuccessfullPayment = () => {
    if (!order || !order.id) return;

    dispatch(updateOrderStatus({ id: order.id, status: OrderStatus.Paid }));
    history.push('/summary/success');
  };

  const handleCancelOrder = () => {
    sendCustomGtmEvent(GTM_EVENTS.PurchaseCancelled);

    if (!order || !order.id) {
      dispatch(resetCheckoutState());
    } else {
      dispatch(
        updateOrderStatus({ id: order.id, status: OrderStatus.Canceled }),
      );
      dispatch(resetCheckoutState());
      history.push('/');
    }
  };

  const env = process.env.REACT_APP_ENV;
  const paypalKey =
    env === 'production' ? process.env.REACT_APP_PAYPAL_KEY : 'sb';

  return (
    <PaymentContainer>
      <ProgressStepper activeStep={2} />

      {order && (
        <SummaryTable
          rows={cart}
          coupon={coupon}
          totalPrice={order.finalPrice}
          shipping={order.shipping}
        />
      )}

      <Summary>
        <SummaryTitle>{t('payment.shippingDetails')}</SummaryTitle>
        <SummaryText>{order?.name || ''}</SummaryText>
        <SummaryText>{order?.phone_number || ''}</SummaryText>
        <SummaryText>{order?.address}</SummaryText>
        <SummaryText>{`${order?.post_code}, ${order?.city}`}</SummaryText>

        {paymentMethods && (
          <PaymentProviders
            paymentMethods={paymentMethods}
            chosenPaymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
          />
        )}

        <FormControl error component="fieldset">
          <FormControlLabel
            control={
              <Checkbox
                checked={p24TermsChecked}
                onChange={(e) => setP24TermsChecked(e.target.checked)}
                name="p24TermsChecked"
              />
            }
            label={
              <p>
                Oświadczam, że zapoznałem się z{' '}
                <Link href="https://www.przelewy24.pl/regulamin" target="blank">
                  regulaminem
                </Link>{' '}
                i{' '}
                <Link
                  href="https://www.przelewy24.pl/obowiazek-informacyjny-rodo-platnicy"
                  target="blank"
                >
                  obowiązkiem informacyjnym
                </Link>{' '}
                serwisu Przelewy24<RequiredField>*</RequiredField>
              </p>
            }
          />

          {!p24TermsChecked && buyClicked && (
            <FormHelperText>{t('auth.requiredField')}</FormHelperText>
          )}
        </FormControl>

        <PayButton>
          <Button
            className={!paymentMethod ? 'disabled' : ''}
            onClick={handlePayment}
          >
            {t('payment.payButton')}
          </Button>
        </PayButton>

        <SummaryTitle>{t('payment.payWithPaypal')}</SummaryTitle>

        <PayPalContainer>
          <PayPalButton
            amount={order?.finalPrice}
            onSuccess={handleSuccessfullPayment}
            onError={() => history.push('/summary/error')}
            options={{
              clientId: paypalKey || 'sb',
              currency: 'PLN',
            }}
          />
        </PayPalContainer>

        <CancelButton onClick={handleCancelOrder}>
          Anuluj zamówienie
        </CancelButton>
      </Summary>

      {isLoading && <Loader />}
    </PaymentContainer>
  );
}

export default Payment;
