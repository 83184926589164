import axios from 'axios';

const env = process.env.REACT_APP_ENV;
let baseURL: string;

switch (env) {
  case 'development':
    baseURL = 'http://localhost:3001/api/v1/';
    break;
  case 'staging':
    baseURL = 'http://194.5.159.158/api/v1/';
    break;
  case 'production':
    baseURL = 'https://scudetto.store/api/v1/';
    break;
  default:
    baseURL = 'https://scudetto.store/api/v1/';
    break;
}

export const axiosInstance = axios.create({
  withCredentials: true,
  baseURL,
});
