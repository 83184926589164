import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { Product, ProductType } from 'api/models/Product';
import { axiosInstance } from 'axiosConfig';

interface ProductsState {
  isLoading: boolean;
  products: Product[];
  currentProduct: Product | null;
  totalProducts: number;
  currentPage: number;
  fetchProducts: boolean;
  error: string | null | undefined;
}

const initialState: ProductsState = {
  isLoading: false,
  products: [],
  currentProduct: null,
  totalProducts: 0,
  currentPage: 1,
  fetchProducts: true,
  error: null,
};

export const fetchProducts = createAsyncThunk<
  { products: Product[]; totalNumber: number },
  { page: number; category: ProductType | undefined }
>('products/fetchProducts', async ({ page, category }) => {
  const response = await axiosInstance.get(
    `products?page=${page}&pageSize=6&category=${category}&excludeOutletProducts=true`,
  );

  const products = response.data.results as Product[];
  const totalNumber = response.data.total;

  return { products, totalNumber };
});

export const fetchProductById = createAsyncThunk(
  'products/fetchProductById',
  async (id: string) => {
    const response = await axiosInstance.get<Product>(`products/${id}`);

    return response.data;
  },
);

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
      state.fetchProducts = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchProducts.fulfilled, (state, { payload }) => {
      state.products = payload.products;
      state.totalProducts = payload.totalNumber;
      state.isLoading = false;
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchProductById.pending, (state) => {
      state.isLoading = true;
      state.currentProduct = null;
      state.error = null;
    });
    builder.addCase(fetchProductById.fulfilled, (state, { payload }) => {
      state.currentProduct = payload;
      state.isLoading = false;
    });
    builder.addCase(fetchProductById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { setCurrentPage } = productsSlice.actions;

export const selectIsLoading = (state: RootState) => state.products.isLoading;
export const selectProducts = (state: RootState) => state.products.products;
export const selectCurrentProduct = (state: RootState) =>
  state.products.currentProduct;
export const selectTotalProducts = (state: RootState) =>
  state.products.totalProducts;
export const selectCurrentPage = (state: RootState) =>
  state.products.currentPage;
export const selectFetchProducts = (state: RootState) =>
  state.products.fetchProducts;

export default productsSlice.reducer;
