import React from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';

import { ContactContainer, Title, Text, BackButton } from './styled';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      padding: '4rem',
      width: '100%',
      height: '100%',
    },
  }),
);

function Terms() {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <ContactContainer topMargin withFooter>
      <Paper className={classes.paper}>
        <Title>{t('contact.title')}</Title>

        <Text>{t('contact.text1')}</Text>
        <Text>{t('contact.text2')}</Text>
        <Text>{t('contact.text3')}</Text>
        <Text>{t('contact.text4')}</Text>

        <BackButton onClick={() => history.goBack()}>
          {t('terms.backButton')}
        </BackButton>
      </Paper>
    </ContactContainer>
  );
}

export default Terms;
