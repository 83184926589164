import styled from 'styled-components';
import { Container, OutlinedButton } from 'theme/global';

export const TermsContainer = styled(Container)`
  min-height: calc(100vh - 7.9rem - 38.2rem - 4rem - 10rem);
`;

export const Title = styled.h1`
  font-size: ${(props) => props.theme.fontSizes.m};
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  margin-bottom: 4rem;
`;

export const Text = styled.li`
  font-size: ${(props) => props.theme.fontSizes.s};
  color: ${(props) => props.theme.colors.primary};
  line-height: 2.5rem;
  margin: 2rem;
`;

export const Link = styled.a`
  font-weight: bold;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.primary};
`;

export const BackButton = styled(OutlinedButton)`
  margin-top: 4rem;
`;
