import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { User } from 'api/models/User';
import { axiosInstance } from 'axiosConfig';

interface AuthState {
  checkingAuth: boolean;
  isLoading: boolean;
  user: User | undefined;
  error: string | undefined;
}

const initialState: AuthState = {
  checkingAuth: true,
  isLoading: false,
  user: undefined,
  error: undefined,
};

interface LoginArgs {
  email: string;
  password: string;
}

interface SignUpArgs {
  name: string;
  email: string;
  phone: string;
  password: string;
}

export const login = createAsyncThunk<
  User | undefined,
  LoginArgs,
  { rejectValue: string }
>('auth/login', async ({ email, password }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post<User>(`auth/login`, {
      email,
      password,
    });

    return response.data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data.message as string);
  }
});

export const signUp = createAsyncThunk<
  User | undefined,
  SignUpArgs,
  { rejectValue: string }
>(
  'auth/signup',
  async ({ name, phone, email, password }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<User>(`auth/signup`, {
        name,
        phone,
        email,
        password,
      });

      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data.message as string);
    }
  },
);

export const checkAuthStatus = createAsyncThunk(
  'auth/checkAuthStatus',
  async () => {
    const response = await axiosInstance.get('auth/is-authenticated');
    return response.data as User;
  },
);

export const logout = createAsyncThunk('auth/logout', async () => {
  await axiosInstance.get('auth/logout');
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    builder.addCase(signUp.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(signUp.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
    });
    builder.addCase(signUp.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    builder.addCase(checkAuthStatus.pending, (state) => {
      state.checkingAuth = true;
      state.error = undefined;
    });
    builder.addCase(checkAuthStatus.fulfilled, (state, action) => {
      state.user = action.payload;
      state.checkingAuth = false;
    });
    builder.addCase(checkAuthStatus.rejected, (state) => {
      state.checkingAuth = false;
      state.user = undefined;
    });

    builder.addCase(logout.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(logout.fulfilled, (state) => {
      state.isLoading = false;
      state.user = undefined;
    });
  },
});

export const { resetError } = authSlice.actions;

export const selectIsLoading = (state: RootState) => state.auth.isLoading;
export const selectUser = (state: RootState) => state.auth.user;
export const selectError = (state: RootState) => state.auth.error;
export const selectCheckingAuth = (state: RootState) => state.auth.checkingAuth;

export default authSlice.reducer;
