import React, { useState } from 'react';

import { DialogLayout } from '../DialogLayout';
import { UploadButton } from '../DialogLayout/UploadButton';

import {
  Row,
  ThumbnailContainer,
  Thumbnail,
  Label,
} from '../DialogLayout/styled';

interface AddFleeceDialogProps {
  title: string;
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (
    iconImage: File,
    frontImage: File,
    backImage: File,
    backFemaleImage: File,
  ) => void;
}

export const AddFleeceDialog: React.FC<AddFleeceDialogProps> = ({
  title,
  isOpen,
  handleClose,
  handleSubmit,
}) => {
  const [iconImage, setIconImage] = useState<File>();
  const [iconUrl, setIconUrl] = useState<string>();
  const [frontImage, setFrontImage] = useState<File>();
  const [frontUrl, setFrontUrl] = useState<string>();
  const [backImage, setBackImage] = useState<File>();
  const [backUrl, setBackUrl] = useState<string>();
  const [backFemaleImage, setBackFemaleImage] = useState<File>();
  const [backFemaleUrl, setBackFemaleUrl] = useState<string>();

  const addFleece = () => {
    if (!iconImage || !frontImage || !backImage || !backFemaleImage) return;

    handleSubmit(iconImage, frontImage, backImage, backFemaleImage);
  };

  return (
    <DialogLayout
      isOpen={isOpen}
      title={title}
      handleClose={handleClose}
      handleSubmit={addFleece}
    >
      <Row>
        <ThumbnailContainer>
          <Thumbnail src={iconUrl} />
          <Label>Ikona</Label>
        </ThumbnailContainer>

        <UploadButton
          inputId="icon"
          setImage={setIconImage}
          setThumbnailUrl={setIconUrl}
        />
      </Row>

      <Row>
        <ThumbnailContainer>
          <Thumbnail src={frontUrl} />
          <Label>Przód</Label>
        </ThumbnailContainer>

        <UploadButton
          inputId="front"
          setImage={setFrontImage}
          setThumbnailUrl={setFrontUrl}
        />
      </Row>

      <Row>
        <ThumbnailContainer>
          <Thumbnail src={backUrl} />
          <Label>Tył</Label>
        </ThumbnailContainer>

        <UploadButton
          inputId="back"
          setImage={setBackImage}
          setThumbnailUrl={setBackUrl}
        />
      </Row>

      <Row>
        <ThumbnailContainer>
          <Thumbnail src={backFemaleUrl} />
          <Label>Tył damski</Label>
        </ThumbnailContainer>

        <UploadButton
          inputId="backFemale"
          setImage={setBackFemaleImage}
          setThumbnailUrl={setBackFemaleUrl}
        />
      </Row>
    </DialogLayout>
  );
};
