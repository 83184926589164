import styled from 'styled-components';
import { OutlinedButton } from 'theme/global';

export const Title = styled.h1`
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.fontSizes.l};
  margin-bottom: 4rem;
`;

export const Text = styled.p`
  font-size: ${(props) => props.theme.fontSizes.s};
  color: ${(props) => props.theme.colors.primary};
`;

export const BackButton = styled(OutlinedButton)`
  margin-top: 2rem;
`;
