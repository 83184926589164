import styled from 'styled-components';

import { Container } from 'theme/global';
import { Text } from 'theme/typography';
import { device } from 'theme/theme';

export const Nav = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: ${(props) => props.theme.colors.primary};
  z-index: 1000;
  transition: all 0.3s ease-in-out;
`;

export const Content = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface GuidesContentProps {
  $hidden: boolean;
}

export const GuidesContent = styled('div')<GuidesContentProps>`
  position: fixed;
  width: 100%;
  height: 200px;
  top: -200px;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.colors.primary};
  transition: all 0.3s ease-in-out;
  z-index: 1000;
  overflow-x: scroll;

  display: flex;
  justify-content: center;
  align-items: space-evenly;

  ${(props) =>
    props.$hidden &&
    `
    top: 84px;
  `}
`;

export const GuideTile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`;

export const GuideThumbnail = styled.img`
  width: 25rem;
  max-height: 15rem;
  margin-bottom: 1rem;
  object-fit: cover;
  border-radius: 1rem;
`;

export const GuideTitle = styled.p`
  color: ${(props) => props.theme.colors.light};
  font-size: ${(props) => props.theme.fontSizes.s};
`;

interface PageBlurProps {
  $hidden: boolean;
}

export const PageBlur = styled.div<PageBlurProps>`
  position: fixed;
  top: 84px;
  left: 0;
  right: 0;
  height: calc(100vh - 84px);
  width: 100vw;
  background-color: ${(props) => props.theme.colors.light};
  z-index: 1000;
  pointer-events: none;
  transition: all 0.3s ease-in-out;

  opacity: ${(props) => (props.$hidden ? '0' : '0.5')};
`;

export const Logo = styled.img`
  max-height: 7.5rem;
  padding: 1rem 0;
`;

export const Links = styled.div`
  display: flex;
  align-items: center;

  @media ${device.mobile} {
    display: none;
  }
`;

interface NavLinkProps {
  isActive?: boolean;
}

export const NavLink = styled(Text)<NavLinkProps>`
  display: block;
  margin: 0 2rem;
  padding: 3rem 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
  color: ${(props) =>
    props.isActive ? props.theme.colors.accent : props.theme.colors.light};

  &:hover {
    color: ${(props) => props.theme.colors.accent};
  }
`;

export const NavIcon = styled(NavLink)`
  font-size: 2.8rem;
  display: flex;
  align-items: center;
`;

export const FlagIcon = styled.img`
  width: 2.8rem;
  height: 2.8rem;
  margin: 0 2rem;
  cursor: pointer;
`;

export const Dropdown = styled.div`
  cursor: pointer;
`;

interface DropdownLinkProps {
  icon?: boolean;
}

export const DropdownLink = styled(NavLink)<DropdownLinkProps>`
  font-size: ${(props) => (props.icon ? '2.4rem' : '1.6rem')};
  margin: 3rem 2rem;
  padding: 0;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;

  ${Dropdown}:hover & {
    color: ${(props) => props.theme.colors.accent};
  }
`;

export const DropdownContent = styled.div`
  display: none;
  border-top: 0.2rem solid ${(props) => props.theme.colors.accent};
  position: absolute;
  width: auto;
  color: white;
  padding: 1rem 2rem;
  background-color: ${(props) => props.theme.colors.primary};
  border-bottom-left-radius: ${(props) => props.theme.borderRadius};
  border-bottom-right-radius: ${(props) => props.theme.borderRadius};
  z-index: 1;

  ${Dropdown}:hover & {
    display: block;
  }
`;

export const DropdownContentLink = styled(NavLink)`
  margin: 1.5rem 0;
  display: block;
  padding: 0;
  font-size: 1.4rem;
  font-weight: 500;
`;

export const HamburgerMenu = styled.div`
  color: ${(props) => props.theme.colors.light};
  font-size: 2.8rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${(props) => props.theme.colors.accent};
  }
`;

export const NavigationDrawer = styled.div`
  width: 30rem;
  background-color: ${(props) => props.theme.colors.primary};
  padding: 4rem;
  min-height: 100vh;
  position: absolute;
  top: 7.5rem;
  left: -30rem;
  border-top: 2px solid ${(props) => props.theme.colors.accent};
  transition: all 0.3s ease-in-out;
  display: none;

  &.is-active {
    left: 0;
  }

  @media ${device.mobile} {
    display: block;
    padding-top: 2rem;
  }
`;

export const NavigationDrawerLink = styled(Text)`
  color: ${(props) => props.theme.colors.light};
  padding: 2rem 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 600;

  &:hover {
    color: ${(props) => props.theme.colors.accent};
  }
`;

export const NavigationDrawerFlagIcon = styled(FlagIcon)`
  margin: 2rem 2rem 0 0;
`;

export const DarkOverlay = styled.div`
  position: absolute;
  top: 7.5rem;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.primary};
  opacity: 0.5;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  display: none;

  &.is-active {
    opacity: 0.5;
  }

  @media ${device.mobile} {
    display: block;
  }
`;

export const MobileIconsContainer = styled.div`
  display: flex;
  align-items: center;
  display: none;

  @media ${device.mobile} {
    display: flex;
  }
`;
