import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  margin: 2rem 0;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  border-radius: 1rem;
`;

export const PaymentLogo = styled.img`
  width: 23%;
  height: auto;
  margin: 0 1rem;
`;
