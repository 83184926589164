import styled from 'styled-components';
import {
  makeStyles,
  Theme,
  createStyles,
  lighten,
} from '@material-ui/core/styles';

export const ImagesContainer = styled.div`
  margin-top: 2rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
`;

export const Image = styled.img`
  max-width: 10rem;
  height: auto;
  border-radius: 0.5rem;
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    uploadButton: {
      backgroundColor: theme.palette.blue.main,
      color: theme.palette.light.main,
      marginTop: '2rem',

      '&:hover': {
        backgroundColor: lighten(theme.palette.blue.main, 0.2),
      },
    },
    input: {
      display: 'none',
    },
  }),
);
