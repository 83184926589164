import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { addProduct } from '../redux/thunk';
import { ProductToAdd, ProductType } from 'api/models/Product';
import { productsSelectors, setError } from '../redux/productsSlice';
import { fetchSizes } from 'admin/containers/SizesContainer/redux/thunk';
import { fetchVoucherTypes } from 'admin/containers/VoucherTypesContainer/redux/thunk';
import { selectSizesState } from 'admin/containers/SizesContainer/redux/sizesSlice';
import { selectVoucherTypesState } from 'admin/containers/VoucherTypesContainer/redux/slice';
import { Size } from 'api/models/Size';
import { VoucherType } from 'api/models/VoucherType';
import clsx from 'clsx';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { ImageUploader } from 'admin/components/ImageUploader';
import { DashboardContainer } from 'theme/global';
import { Title, useStyles } from './styled';
import { CustomButton } from 'admin/components/CustomButton';
import { LoadingDialog } from 'admin/components/LoadingDialog';

interface FormValues {
  type: ProductType;
  name: string;
  shortDescription: string;
  shortEnglishDescription: string;
  price: string;
  discountPrice?: string;
  longDescription: string;
  longEnglishDescription: string;
  materials: string;
  englishMaterials: string;
}

export const AddProduct: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [images, setImages] = useState<FileList>();
  const [selectedSizes, setSelectedSizes] = useState<Size[]>([]);
  const [selectedVoucherTypes, setSelectedVoucherTypes] = useState<
    VoucherType[]
  >([]);
  const { sizes } = useSelector(selectSizesState);
  const { voucherTypes } = useSelector(selectVoucherTypesState);
  const sizesLoading = useSelector(selectSizesState).isLoading;
  const { uploadPercentage, error, isUploading } =
    useSelector(productsSelectors);

  useEffect(() => {
    dispatch(fetchSizes(null));
    dispatch(fetchVoucherTypes());
  }, [dispatch]);

  useEffect(() => {
    if (uploadPercentage < 100) return;

    formik.resetForm();
    setImages(undefined);
    setSelectedSizes([]);
    history.push('/dashboard/products');

    // eslint-disable-next-line
  }, [uploadPercentage]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('To pole jest wymagane'),
    shortDescription: Yup.string().max(255).required('To pole jest wymagane'),
    shortEnglishDescription: Yup.string()
      .max(255)
      .required('To pole jest wymagane'),
    price: Yup.number().positive().moreThan(0),
    discountPrice: Yup.number().positive().moreThan(0),
    longDescription: Yup.string().required('To pole jest wymagane'),
    longEnglishDescription: Yup.string().required('To pole jest wymagane'),
    materials: Yup.string().max(255),
    englishMaterials: Yup.string().max(255),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      type: ProductType.Male,
      name: '',
      shortDescription: '',
      shortEnglishDescription: '',
      price: '',
      discountPrice: '',
      longDescription: '',
      longEnglishDescription: '',
      materials: '',
      englishMaterials: '',
    },
    validationSchema,
    onSubmit: (values) => {
      let error: string | undefined;
      let productToAdd: ProductToAdd;

      if (values.type !== ProductType.Voucher) {
        if (!images || images.length < 2) {
          error = 'Dodaj co najmniej 2 zdjęcia';
        } else if (selectedSizes.length === 0) {
          error = 'Dodaj co najmniej 1 rozmiar';
        }

        selectedSizes.forEach((size) => {
          if (!size.quantity || size.quantity <= 0) {
            error = 'Uzupełnij ilość dla każdego wybranego rozmiaru';
          }
        });

        dispatch(setError(error));
        if (error) return;

        productToAdd = {
          ...values,
          price: Number(values.price),
          discountPrice: values.discountPrice
            ? Number(values.discountPrice)
            : 0,
        };

        dispatch(
          addProduct({
            product: productToAdd,
            // eslint-disable-next-line
            images: images!,
            sizes: selectedSizes,
          }),
        );
      } else {
        if (!images || images.length < 2) {
          error = 'Dodaj co najmniej 2 zdjęcia';
        } else if (selectedVoucherTypes.length === 0) {
          error = 'Dodaj co najmniej 1 rodzaj voucheru';
        }

        dispatch(setError(error));
        if (error) return;

        productToAdd = {
          ...values,
          price: 0,
          discountPrice: 0,
          materials: '',
          englishMaterials: '',
        };

        dispatch(
          addProduct({
            product: productToAdd,
            // eslint-disable-next-line
            images: images!,
            voucherTypes: selectedVoucherTypes,
          }),
        );
      }
    },
  });

  const handleSizeChange = (size: Size) => {
    if (
      selectedSizes.findIndex((selectedSize) => selectedSize.id === size.id) !==
      -1
    ) {
      const newSelectedSizes = selectedSizes.filter(
        (selectedSize) => selectedSize.id !== size.id,
      );
      setSelectedSizes(newSelectedSizes);
    } else {
      setSelectedSizes((oldSizes) => [...oldSizes, size]);
    }
  };

  const handleVoucherTypeChange = (type: VoucherType) => {
    if (
      selectedVoucherTypes.findIndex(
        (selectedType) => selectedType.id === type.id,
      ) !== -1
    ) {
      const newSelectedVouchers = selectedVoucherTypes.filter(
        (selectedType) => selectedType.id !== type.id,
      );
      setSelectedVoucherTypes(newSelectedVouchers);
    } else {
      setSelectedVoucherTypes((oldTypes) => [...oldTypes, type]);
    }
  };

  const handleQuantityChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    size: Size,
  ) => {
    const { value } = e.target;

    const newSizes = [...selectedSizes];
    const sizeIdx = newSizes.findIndex((oldSize) => oldSize.id === size.id);
    newSizes[sizeIdx] = { ...selectedSizes[sizeIdx], quantity: Number(value) };

    setSelectedSizes(newSizes);
  };

  const quantityInputVisible = (size: Size) => {
    return (
      selectedSizes.findIndex((selectedSize) => selectedSize.id === size.id) !==
      -1
    );
  };

  const isVoucher = formik.values.type === ProductType.Voucher;

  return (
    <DashboardContainer>
      <Paper className={classes.paper}>
        <Typography variant="h6">Dodaj nowy produkt</Typography>

        <ImageUploader setImages={setImages} title="Zdjęcia" />

        <Title>Informacje o produkcie</Title>

        <form onSubmit={formik.handleSubmit}>
          <div>
            <TextField
              variant="outlined"
              id="name"
              name="name"
              className={`${classes.textField} ${classes.halfTextField}`}
              label="Nazwa"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <FormControl
              variant="outlined"
              className={`${classes.textField} ${classes.halfTextField}`}
            >
              <InputLabel id="product-type-label">Rodzaj produktu</InputLabel>

              <Select
                labelId="product-type-label"
                id="type"
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                label="Rodzaj produktu"
              >
                <MenuItem value="male">Kamizelka męska</MenuItem>
                <MenuItem value="female">Kamizelka damska</MenuItem>
                <MenuItem value="kid">Kamizelka dziecięca</MenuItem>
                <MenuItem value="tshirtMale">Koszulka męska</MenuItem>
                <MenuItem value="tshirtFemale">Koszulka damska</MenuItem>
                <MenuItem value="voucher">Voucher</MenuItem>
              </Select>
            </FormControl>
          </div>

          <TextField
            fullWidth
            variant="outlined"
            id="shortDescription"
            name="shortDescription"
            className={classes.textField}
            label="Krótki opis"
            multiline
            rows={3}
            value={formik.values.shortDescription}
            onChange={formik.handleChange}
            error={
              formik.touched.shortDescription &&
              Boolean(formik.errors.shortDescription)
            }
            helperText={
              formik.touched.shortDescription && formik.errors.shortDescription
            }
          />

          <TextField
            fullWidth
            variant="outlined"
            id="shortEnglishDescription"
            name="shortEnglishDescription"
            className={classes.textField}
            label="Krótki opis (angielski)"
            multiline
            rows={3}
            value={formik.values.shortEnglishDescription}
            onChange={formik.handleChange}
            error={
              formik.touched.shortEnglishDescription &&
              Boolean(formik.errors.shortEnglishDescription)
            }
            helperText={
              formik.touched.shortEnglishDescription &&
              formik.errors.shortEnglishDescription
            }
          />

          {!isVoucher && (
            <div>
              <TextField
                variant="outlined"
                type="number"
                id="price"
                name="price"
                className={`${classes.textField} ${classes.halfTextField}`}
                label="Cena"
                value={formik.values.price}
                onChange={formik.handleChange}
                error={formik.touched.price && Boolean(formik.errors.price)}
                helperText={formik.touched.price && formik.errors.price}
              />
              <TextField
                variant="outlined"
                type="number"
                id="discountPrice"
                name="discountPrice"
                className={`${classes.textField} ${classes.halfTextField}`}
                label="Cena promocyjna"
                value={formik.values.discountPrice}
                onChange={formik.handleChange}
                error={
                  formik.touched.discountPrice &&
                  Boolean(formik.errors.discountPrice)
                }
                helperText={
                  formik.touched.discountPrice && formik.errors.discountPrice
                }
              />
            </div>
          )}

          <TextField
            fullWidth
            variant="outlined"
            id="longDescription"
            name="longDescription"
            className={classes.textField}
            label="Długi opis"
            multiline
            rows={5}
            value={formik.values.longDescription}
            onChange={formik.handleChange}
            error={
              formik.touched.longDescription &&
              Boolean(formik.errors.longDescription)
            }
            helperText={
              formik.touched.longDescription && formik.errors.longDescription
            }
          />

          <TextField
            fullWidth
            variant="outlined"
            id="longEnglishDescription"
            name="longEnglishDescription"
            className={classes.textField}
            label="Długi opis (angielski)"
            multiline
            rows={5}
            value={formik.values.longEnglishDescription}
            onChange={formik.handleChange}
            error={
              formik.touched.longEnglishDescription &&
              Boolean(formik.errors.longEnglishDescription)
            }
            helperText={
              formik.touched.longEnglishDescription &&
              formik.errors.longEnglishDescription
            }
          />

          {!isVoucher && (
            <>
              <TextField
                fullWidth
                variant="outlined"
                id="materials"
                name="materials"
                className={classes.textField}
                label="Materiały"
                multiline
                rows={3}
                value={formik.values.materials}
                onChange={formik.handleChange}
                error={
                  formik.touched.materials && Boolean(formik.errors.materials)
                }
                helperText={formik.touched.materials && formik.errors.materials}
              />

              <TextField
                fullWidth
                variant="outlined"
                id="englishMaterials"
                name="englishMaterials"
                className={classes.textField}
                label="Materiały (angielski)"
                multiline
                rows={3}
                value={formik.values.englishMaterials}
                onChange={formik.handleChange}
                error={
                  formik.touched.englishMaterials &&
                  Boolean(formik.errors.englishMaterials)
                }
                helperText={
                  formik.touched.shortEnglishDescription &&
                  formik.errors.englishMaterials
                }
              />
            </>
          )}

          <Title>
            {isVoucher ? 'Rodzaje voucherów' : 'Rozmiary i stan magazynowy'}
          </Title>

          {sizesLoading && <CircularProgress className={classes.spinner} />}

          {isVoucher ? (
            <>
              {voucherTypes.map((type) => (
                <FormGroup row key={type.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          selectedVoucherTypes.findIndex(
                            (selectedType) => selectedType.id === type.id,
                          ) !== -1
                        }
                        onChange={() => handleVoucherTypeChange(type)}
                        name={type.name}
                        className={classes.checkbox}
                      />
                    }
                    label={type.name}
                  />
                </FormGroup>
              ))}
            </>
          ) : (
            <>
              {sizes.map((size) => (
                <FormGroup row key={size.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          selectedSizes.findIndex(
                            (selectedSize) => selectedSize.id === size.id,
                          ) !== -1
                        }
                        onChange={() => handleSizeChange(size)}
                        name={size.sizeName}
                        className={classes.checkbox}
                      />
                    }
                    label={size.sizeName}
                  />

                  <TextField
                    fullWidth
                    variant="outlined"
                    type="number"
                    name={size.sizeName}
                    label="Ilość"
                    value={size.quantity}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleQuantityChange(e, size)
                    }
                    className={clsx(classes.textField, {
                      hidden: !quantityInputVisible(size),
                    })}
                  />
                </FormGroup>
              ))}
            </>
          )}

          <CustomButton type="submit" topMargin="2rem">
            Dodaj produkt
          </CustomButton>
        </form>
      </Paper>

      <LoadingDialog
        title="Dodawanie nowego produktu..."
        isOpen={isUploading}
        progress={uploadPercentage}
      />

      <Snackbar
        open={Boolean(error)}
        autoHideDuration={10000}
        onClose={() => dispatch(setError(undefined))}
      >
        <Alert onClose={() => dispatch(setError(undefined))} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </DashboardContainer>
  );
};
