import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { Product, CustomProduct } from 'api/models/Product';
import { Coupon } from 'api/models/Coupon';
import { axiosInstance } from 'axiosConfig';
import { resetCheckoutState } from 'containers/App/actions';

export interface CartProduct {
  product: Product | CustomProduct;
  size: string;
  quantity: number;
}

interface CartState {
  isLoading: boolean;
  cart: CartProduct[];
  coupon: Coupon | null;
  error: string | null | undefined;
}

const initialState: CartState = {
  isLoading: false,
  cart: [],
  coupon: null,
  error: null,
};

export const checkCouponCode = createAsyncThunk(
  'cart/checkCouponCode',
  async (couponName: string) => {
    const response = await axiosInstance.get(`coupons/${couponName}`);
    return response.data[0] as Coupon;
  },
);

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<CartProduct>) => {
      state.cart.push(action.payload);
    },
    removeFromCart: (state, action: PayloadAction<number>) => {
      state.cart = state.cart.filter(
        (cartItem) => cartItem.product.id !== action.payload,
      );
    },
    removeCouponCode: (state) => {
      state.coupon = null;
    },
    clearCart: (state) => {
      state.cart = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkCouponCode.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(checkCouponCode.fulfilled, (state, { payload }) => {
      state.coupon = payload;
      state.isLoading = false;
      state.error = payload ? null : 'Wrong code';
    });
    builder.addCase(checkCouponCode.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    // Reset cart state
    builder.addCase(resetCheckoutState, (state) => {
      state.cart = initialState.cart;
      state.isLoading = initialState.isLoading;
      state.coupon = initialState.coupon;
      state.error = initialState.error;
    });
  },
});

export const { addToCart, removeFromCart, removeCouponCode, clearCart } =
  cartSlice.actions;

export const selectCart = (state: RootState) => state.cart.cart;
export const selectIsLoading = (state: RootState) => state.cart.isLoading;
export const selectError = (state: RootState) => state.cart.error;
export const selectCoupon = (state: RootState) => state.cart.coupon;

export default cartSlice.reducer;
