import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

export const useStyles = makeStyles(() =>
  createStyles({
    loaderContainer: {
      margin: '2rem',
    },
  }),
);

export const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.lightGray.main,
    },
    bar: {
      backgroundColor: theme.palette.blue.main,
    },
  }),
)(LinearProgress);
