import styled from 'styled-components';
import { Container } from 'theme/global';
import { device } from 'theme/theme';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

export const MainContainer = styled(Container)`
  max-width: 150rem;
`;

export const SectionTitle = styled.p`
  text-transform: uppercase;
  text-align: center;
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.fontSizes.s};
  font-weight: 400;
  margin: 4rem 0;
`;

export const SlidesSection = styled.section`
  width: 100%;
  height: 40rem;
  border-radius: 1rem;

  @media ${device.mobile} {
    height: 30rem;
  }
`;

interface ShopSectionProps {
  $fromStart?: boolean;
}

export const ShopSection = styled.section<ShopSectionProps>`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.$fromStart ? 'flex-start' : 'space-between'};
  gap: 0 6rem;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const ShopTile = styled(Link)`
  width: 30%;
  height: 40rem;
  border-radius: 1rem;
  background-color: #000;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.9;
  }

  @media ${device.mobile} {
    width: 100%;
    height: 20rem;
    margin: 2rem 0;
  }
`;

export const ShopTileImage = styled.img`
  width: 100%;
  height: 40rem;
  border-radius: 1rem;
  object-fit: cover;
  object-position: top center;
  opacity: 0.8;

  @media ${device.mobile} {
    height: 20rem;
  }
`;

export const ShopTileText = styled.p`
  position: absolute;
  bottom: 6rem;
  left: 0;
  right: 0;
  font-weight: 600;
  color: #fff;
  font-size: ${(props) => props.theme.fontSizes.l};
  text-align: center;
`;

export const GuideTile = styled(ShopTile)`
  height: 50rem;

  @media ${device.mobile} {
    height: 20rem;
  }
`;

export const GuideTileImage = styled(ShopTileImage)`
  height: 50rem;

  @media ${device.mobile} {
    height: 20rem;
  }
`;

export const GuideTileText = styled(ShopTileText)`
  font-size: ${(props) => props.theme.fontSizes.m};
`;

export const GuideButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;

export const BestsellersSection = styled.section`
  display: flex;
  align-items: center;
  gap: 4rem;
  justify-content: center;

  @media ${device.mobile} {
    flex-wrap: wrap;
    gap: 0;
    justify-content: space-between;
  }
`;

export const BestsellerTile = styled.div`
  border-radius: 1rem;
  width: 23%;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.9;
  }

  @media ${device.mobile} {
    width: 45%;
    margin: 1rem 0.5rem;
  }
`;

interface BestsellerImageProps {
  isLoading: boolean;
}

export const BestsellerImage = styled.img<BestsellerImageProps>`
  width: 100%;
  height: 35rem !important;
  object-fit: cover;
  border-radius: 1rem;
  display: ${(props) => (props.isLoading ? 'none' : '')};

  @media ${device.mobile} {
    height: 25rem !important;
  }
`;

export const BestsellerName = styled.p`
  margin-top: 1rem;
  font-size: ${(props) => props.theme.fontSizes.xs};
`;

interface BestsellerPriceProps {
  isOnSale?: boolean;
}

export const BestsellerPrice = styled.p<BestsellerPriceProps>`
  margin-top: 0.5rem;
  font-size: ${(props) => props.theme.fontSizes.xs};
  font-weight: 600;
  display: inline;

  ${(props) =>
    props.isOnSale &&
    `
    font-weight: 400;
    text-decoration: line-through;
    margin-right: 0.5rem;    
  `}
`;

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const AdditionalSectionContainer = styled.div`
  margin: 4rem 0 2rem 0;
`;

export const useStyles = makeStyles(() => ({
  skeleton: {
    width: '100%',
    height: '35rem',
    borderRadius: '1rem',
  },
}));
