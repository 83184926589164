import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { LoaderContainer } from './styled';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loader: {
      color: theme.palette.blue.main,
    },
  }),
);

export const Loader: React.FC = () => {
  const classes = useStyles();

  return (
    <LoaderContainer>
      <CircularProgress className={classes.loader} />
    </LoaderContainer>
  );
};
