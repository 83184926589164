import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  login,
  selectUser,
  selectIsLoading,
  selectError,
  resetError,
} from '../authSlice';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { AuthContainer, Logo } from '../styled';
import {
  Form,
  InputContainer,
  OutlinedButton,
  StyledInput,
  HelperText,
  HelperTextLink,
  ErrorContainer,
  ErrorMessage,
} from 'theme/global';
import LogoImg from 'assets/logo/logo.png';

interface FormValues {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);
  const user = useSelector(selectUser);

  useEffect(() => {
    dispatch(resetError());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!user) return;

    formik.resetForm();
    if (user.isAdmin) {
      history.push('/dashboard/products');
    } else {
      history.push('/account');
    }
    // eslint-disable-next-line
  }, [user]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('auth.invalidEmail'))
      .required(t('auth.emptyEmail')),
    password: Yup.string().required(t('auth.passwordEmpty')),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values: FormValues) => {
      dispatch(login(values));
    },
  });

  return (
    <AuthContainer>
      <Link to="/">
        <Logo src={LogoImg} />
      </Link>

      <Form center onSubmit={formik.handleSubmit}>
        <InputContainer>
          <StyledInput
            id="email"
            name="email"
            type="email"
            placeholder={t('auth.emailPlaceholder')}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.errors.email !== undefined && formik.touched.email}
          />
          {formik.errors.email && formik.touched.email && (
            <HelperText error>{formik.errors.email}</HelperText>
          )}
        </InputContainer>

        <InputContainer>
          <StyledInput
            id="password"
            name="password"
            type="password"
            placeholder={t('auth.emailPlaceholder')}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={
              formik.errors.password !== undefined && formik.touched.password
            }
          />
          {formik.errors.password && formik.touched.password && (
            <HelperText error>{formik.errors.password}</HelperText>
          )}
        </InputContainer>

        <HelperText>
          {t('auth.noAccount')}
          <HelperTextLink to="/signup">{t('auth.signUpLink')}</HelperTextLink>
        </HelperText>

        {error && (
          <ErrorContainer>
            <ErrorMessage>{error}</ErrorMessage>
          </ErrorContainer>
        )}

        <OutlinedButton type="submit" className={clsx({ disabled: isLoading })}>
          {t('auth.login')}
        </OutlinedButton>
      </Form>
    </AuthContainer>
  );
};

export default Login;
