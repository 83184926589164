import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperWithMargin: {
      marginBottom: '4rem',
    },
    title: {
      padding: theme.spacing(2),
      flex: '1 1 100%',
    },
  }),
);
