import styled from 'styled-components';
import { Text } from 'theme/typography';
import { device } from 'theme/theme';

export const FooterContainer = styled.footer`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.light};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0;
  position: relative;
  margin-top: 2rem;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    margin: 0 4rem;

    @media ${device.mobile} {
      margin: 1rem 0;
    }
  }

  @media ${device.mobile} {
    display: block;
  }
`;

export const Divider = styled.div`
  width: 35%;
  height: 2px;
  background-color: ${(props) => props.theme.colors.light};
  margin: 4rem auto;
`;

export const SocialIcon = styled.a`
  display: inline;
  font-size: 4rem;
  padding: 0 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: ${(props) => props.theme.colors.light};

  &:hover {
    opacity: 0.8;
  }
`;

export const FooterText = styled(Text)`
  font-weight: bold;
  line-height: 2.5rem;
  font-style: normal;
`;

export const FooterLink = styled.a`
  font-size: ${(props) => props.theme.fontSizes.s};
  color: ${(props) => props.theme.colors.light};
  line-height: 2.5rem;
  display: inline;
  margin: 0 4rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media ${device.mobile} {
    display: block;
  }
`;

export const BottomText = styled(FooterText)`
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
  font-weight: normal;
  text-align: center;
`;

export const MailLink = styled.a`
  font-size: ${(props) => props.theme.fontSizes.s};
  font-weight: bold;
  line-height: 2.5rem;
  color: ${(props) => props.theme.colors.light};
  font-style: normal;
`;
