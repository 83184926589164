import React, { useEffect } from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkAuthStatus,
  selectCheckingAuth,
  selectIsLoading,
} from 'containers/Auth/authSlice';
import { fetchGuides } from 'containers/Guides/redux/thunk';
import { animateScroll } from 'react-scroll';

import Navbar from 'components/Navbar';
import Landing from 'containers/Landing';
import About from 'containers/About';
import Products from 'containers/Products';
import ProductInfo from 'containers/Products/ProductInfo';
import Cart from 'containers/Cart';
import Configurator from 'containers/Configurator';
import Footer from 'components/Footer';
import Login from 'containers/Auth/Login';
import SignUp from 'containers/Auth/SignUp';
import Checkout from 'containers/Checkout';
import Payment from 'containers/Payment';
import Summary from 'containers/Summary';
import Terms from 'containers/Terms';
import Contact from 'containers/Contact';
import UserAccount from 'containers/UserAccount';
import Guides from 'containers/Guides';
import Guide from 'containers/Guides/Guide';
import Outlet from 'containers/Outlet';

import { Layout as AdminLayout } from 'admin/components/Layout';
import { ProductsContainer } from 'admin/containers/ProductsContainer';
import { AddProduct } from 'admin/containers/ProductsContainer/AddProduct';
import { EditProduct } from 'admin/containers/ProductsContainer/EditProduct';
import { SizesContainer } from 'admin/containers/SizesContainer';
import { CouponsContainer } from 'admin/containers/CouponsContainer';
import { OrdersContainer } from 'admin/containers/OrdersContainer';
import { OrderDetails } from 'admin/containers/OrdersContainer/OrderDetails';
import { GalleryContainer } from 'admin/containers/GalleryContainer';
import { VoucherTypesContainer } from 'admin/containers/VoucherTypesContainer';
import { CreatorContainer } from 'admin/containers/CreatorContainer';

import UserRoute from 'utils/routes/UserRoute';
import AdminRoute from 'utils/routes/AdminRoute';
import { GlobalStyle } from 'theme/globalStyles';
import Loader from 'components/Loader';
import { CookiePolicy } from 'components/CookiePolicy';

import { Layout } from './styled';

import { builder } from '@builder.io/react';

builder.init(process.env.REACT_APP_BUILDER_API_KEY || '');

export const PublicRoutes = () => {
  return (
    <Layout>
      <div>
        <Navbar />
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/about" component={About} />
          <Route exact path="/products/:category" component={Products} />
          <Route exact path="/product/:id" component={ProductInfo} />
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/configurator/:gender" component={Configurator} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/checkout" component={Checkout} />
          <Route exact path="/payment" component={Payment} />
          <Route exact path="/summary/:status" component={Summary} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/guides" component={Guides} />
          <Route exact path="/guides/:handle" component={Guide} />
          <Route exact path="/outlet" component={Outlet} />

          {/* Registerd user routes */}
          <UserRoute exact path="/account">
            <UserAccount />
          </UserRoute>
        </Switch>
      </div>

      <Footer />
    </Layout>
  );
};

export const AdminRoutes = () => {
  return (
    <>
      <AdminLayout>
        <Switch>
          <AdminRoute exact path="/dashboard/products">
            <ProductsContainer />
          </AdminRoute>

          <AdminRoute exact path="/dashboard/products/add">
            <AddProduct />
          </AdminRoute>

          <AdminRoute exact path="/dashboard/products/edit/:id">
            <EditProduct />
          </AdminRoute>

          <AdminRoute exact path="/dashboard/sizes">
            <SizesContainer />
          </AdminRoute>

          <AdminRoute exact path="/dashboard/coupons">
            <CouponsContainer />
          </AdminRoute>

          <AdminRoute exact path="/dashboard/orders">
            <OrdersContainer />
          </AdminRoute>

          <AdminRoute exact path="/dashboard/orders/:id">
            <OrderDetails />
          </AdminRoute>

          <AdminRoute exact path="/dashboard/gallery">
            <GalleryContainer />
          </AdminRoute>

          <AdminRoute exact path="/dashboard/voucher-types">
            <VoucherTypesContainer />
          </AdminRoute>

          <AdminRoute exact path="/dashboard/creator">
            <CreatorContainer />
          </AdminRoute>

          <AdminRoute exact path="*">
            <Redirect to="/dashboard/products" />
          </AdminRoute>
        </Switch>
      </AdminLayout>
    </>
  );
};

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  const checkingAuth = useSelector(selectCheckingAuth);
  const isLoading = useSelector(selectIsLoading);

  // Scroll to top of every page
  useEffect(() => {
    if (location.pathname === '/') return;

    animateScroll.scrollToTop();
  }, [location]);

  useEffect(() => {
    dispatch(checkAuthStatus());
    dispatch(fetchGuides(builder));

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <GlobalStyle />

      <CookiePolicy />

      {checkingAuth ? (
        <Loader plainBackground />
      ) : (
        <>
          <Switch>
            <Route path="/dashboard" component={AdminRoutes} />
            <Route path="/" component={PublicRoutes} />
          </Switch>

          {isLoading && <Loader />}
        </>
      )}
    </>
  );
}

export default App;
