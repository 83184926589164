import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import {
  selectError,
  selectConfiguratorContent,
  selectIsLoading,
  fetchContent,
} from './configuratorSlice';
import { CartProduct, addToCart } from 'containers/Cart/cartSlice';
import { ProductType, CustomProduct, BackType } from 'api/models/Product';

import {
  ConfiguratorContainer,
  Title,
  ThumbnailsContainer,
  Thumbnail,
  ButtonsContainer,
  ImagesContainer,
  Image,
  HelperText,
  Price,
  StyledRadio,
  CutSection,
  CutsContainer,
  Cut,
  MaterialSection,
  FleeceSection,
  MainSection,
  BottomSection,
  SizesContainer,
  RadioContainer,
  Label,
} from './styled';
import { Size, OutlinedButton } from 'theme/global';
import Loader from 'components/Loader';
import SizesModal from 'components/Modal/Sizes';
import { sendCustomGtmEvent } from 'utils/helperFunctions';
import { GTM_EVENTS } from 'types/enums';

interface ParamTypes {
  gender: string;
}

const Configurator = () => {
  const { gender } = useParams<ParamTypes>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  // const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);
  const isLoading = useSelector(selectIsLoading);
  const { cuts, materials, fleeces, buttons, sizes } = useSelector(
    selectConfiguratorContent,
  );
  const [chosenSize, setChosenSize] = useState<string>('');
  const [sizesModalOpen, setSizeModalOpen] = useState<boolean>(false);
  const [activeMaterial, setActiveMaterial] = useState<number>(0);
  const [activeFleece, setActiveFleece] = useState<number>(0);
  const [activeCut, setActiveCut] = useState<number>(0);
  const [activeButtons, setActiveButtons] = useState<number>(0);
  const [materialBack, setMaterialBack] = useState<boolean>(true);
  const [materialImageUrl, setMaterialImageUrl] = useState<string>('');
  const [buttonsImageUrl, setButtonsImageUrl] = useState<string>('');
  const [price, setPrice] = useState<number>(440);
  const [previousCutType, setPreviousCutType] = useState<
    'oversize' | 'regular'
  >('regular');

  const regularButtons = buttons.filter(
    (button) => button.oversize === 'false',
  );
  const oversizeButtons = buttons.filter(
    (button) => button.oversize === 'true',
  );
  const oversizeMaterials = materials.filter(
    (material) => material.oversize_back_image_url,
  );

  // Handle errors
  useEffect(() => {
    if (!error) return;

    alert(error);
  }, [error]);

  // Reset state and fetch content
  useEffect(() => {
    if (!gender) return;

    setActiveCut(0);
    setActiveButtons(0);
    setActiveFleece(0);
    setActiveMaterial(0);
    setMaterialBack(true);

    dispatch(fetchContent(gender));
  }, [dispatch, gender]);

  // Generate material and buttons image urls
  useEffect(() => {
    if (isLoading) return;

    const materialUrl = `/images/creator${
      gender === 'female' ? '/female' : ''
    }/${cuts[activeCut].name}/${materials[activeMaterial].name}.png`;

    const isOversize = buttons[activeButtons].oversize === 'true' ? 'ov' : '';
    const buttonsUrl = `/images/creator${
      gender === 'female' ? '/female' : ''
    }/${cuts[activeCut].name}/${buttons[activeButtons].name}${isOversize}.png`;

    setMaterialImageUrl(materialUrl);
    setButtonsImageUrl(buttonsUrl);
  }, [
    isLoading,
    gender,
    cuts,
    buttons,
    activeCut,
    activeButtons,
    activeMaterial,
    materials,
  ]);

  // after changing cut type (oversize), set active buttons and materials to the first applicable
  useEffect(() => {
    if (!cuts || cuts.length === 0) return;

    const isOversize = cuts[activeCut].oversize === 'true';
    setPreviousCutType(isOversize ? 'oversize' : 'regular');

    if (
      (isOversize && previousCutType === 'oversize') ||
      (!isOversize && previousCutType === 'regular')
    )
      return;

    if (isOversize) {
      const firstOversizeButton = buttons.findIndex(
        (button) => button.id === oversizeButtons[0].id,
      );
      const firstOversizeMaterial = materials.findIndex(
        (material) => material.id === oversizeMaterials[0].id,
      );

      setActiveButtons(firstOversizeButton);
      setActiveMaterial(firstOversizeMaterial);
      setMaterialBack(true);
    } else {
      setActiveButtons(0);
      setActiveMaterial(0);
    }
    // eslint-disable-next-line
  }, [activeCut, cuts]);

  // Calculate price
  useEffect(() => {
    if (isLoading) return;

    const isFemale = gender === 'female';
    let newPrice = isFemale ? 430 : 490;

    if (cuts[activeCut].has_collar === 'true') {
      newPrice += 20;
    }
    if (materialBack) {
      newPrice += isFemale ? 20 : 30;
    }

    if (cuts[activeCut].oversize === 'true') {
      newPrice = 690;
    }

    setPrice(newPrice);
  }, [isLoading, gender, cuts, activeCut, materialBack]);

  const renderedCuts =
    cuts.length > 0 &&
    cuts.map((cut, idx) => {
      let url: string;

      if (gender === 'male') {
        url = '/images/creator';
      } else {
        url = '/images/creator/female';
      }

      return (
        <Cut
          key={cut.id}
          src={`${url}/${cut.name}.png`}
          alt="Vest cut"
          onClick={() => setActiveCut(idx)}
        />
      );
    });

  const renderedMaterials = () => {
    if (!materials || materials.length === 0) return <></>;

    const handleMaterialClick = (materialId: number | undefined) => {
      const materialIdx = materials.findIndex(
        (material) => material.id === materialId,
      );
      setActiveMaterial(materialIdx);
    };

    if (cuts[activeCut].oversize === 'true') {
      return oversizeMaterials.map((material) => (
        <Thumbnail
          key={material.id}
          src={material.icon_url}
          alt="Material thumbnail"
          onClick={() =>
            material.quantity !== 0 && handleMaterialClick(material.id)
          }
          disabled={material.quantity === 0}
        />
      ));
    }

    return materials.map((material) => (
      <Thumbnail
        key={material.id}
        src={material.icon_url}
        alt="Material thumbnail"
        onClick={() =>
          material.quantity !== 0 && handleMaterialClick(material.id)
        }
        disabled={material.quantity === 0}
      />
    ));
  };

  const renderedFleeces =
    fleeces.length > 0 &&
    fleeces.map((fleece, idx) => (
      <Thumbnail
        key={fleece.id}
        src={fleece.icon_url}
        alt="Fleece thumbnail"
        onClick={() => fleece.quantity !== 0 && setActiveFleece(idx)}
        disabled={fleece.quantity === 0}
      />
    ));

  const renderedButtons = () => {
    if (buttons.length === 0) return <></>;
    const oversize = cuts[activeCut].oversize === 'true';

    const handleButtonClick = (buttonId: number | undefined) => {
      const buttonIdx = buttons.findIndex((item) => item.id === buttonId);
      setActiveButtons(buttonIdx);
    };

    if (oversize) {
      return oversizeButtons.map((button) => (
        <Thumbnail
          key={button.id}
          src={button.icon_url}
          withMargin
          alt="Button thumbnail"
          onClick={() => handleButtonClick(button.id)}
        />
      ));
    }

    return regularButtons.map((button) => (
      <Thumbnail
        key={button.id}
        src={button.icon_url}
        withMargin
        alt="Button thumbnail"
        onClick={() => handleButtonClick(button.id)}
      />
    ));
  };

  const renderedSizes =
    sizes.length > 0 &&
    sizes.map((size) => (
      <Size
        key={size.id}
        onClick={() => setChosenSize(size.sizeName)}
        className={clsx({ 'is-active': chosenSize === size.sizeName })}
      >
        {size.sizeName}
      </Size>
    ));

  const handleAddToCart = () => {
    if (chosenSize === '') return alert('Wybierz rozmiar');

    const product: CustomProduct = {
      id: Math.floor(Math.random() * 1000),
      name: t('configurator.customProductName'),
      price,
      material: materials[activeMaterial].name,
      fleece: fleeces[activeFleece].name,
      cut: cuts[activeCut].name,
      buttons: buttons[activeButtons].name,
      back: materialBack ? BackType.Material : BackType.Fleece,
      type: gender === 'female' ? ProductType.Female : ProductType.Male,
      size: chosenSize,
    };

    const cartProduct: CartProduct = {
      product,
      size: chosenSize,
      quantity: 1,
    };

    sendCustomGtmEvent(GTM_EVENTS.AddToCart, {
      product_name: cartProduct.product.name,
      product_id: cartProduct.product.id,
      product_size: cartProduct.size,
      product_from_configurator: true,
    });

    dispatch(addToCart(cartProduct));
    history.push('/cart');
  };

  const renderedAddons = () => {
    if (cuts[activeCut].has_collar === 'true') {
      return (
        <Image
          src={
            cuts[activeCut].oversize === 'true'
              ? materials[activeMaterial].oversize_collar_image_url!
              : materials[activeMaterial].addon_with_collar_url
          }
        />
      );
    }

    if (cuts[activeCut].oversize === 'false') {
      return <Image src={materials[activeMaterial].single_addon_url} />;
    }

    return <></>;
  };

  return (
    <ConfiguratorContainer>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <CutSection>
            <Title>{t('configurator.cut')}</Title>

            <CutsContainer>{renderedCuts}</CutsContainer>

            <SizesContainer>
              <Title>{t('configurator.sizeTitle')}</Title>

              {renderedSizes}
            </SizesContainer>

            <OutlinedButton onClick={() => setSizeModalOpen(true)}>
              {t('buttons.sizeChart')}
            </OutlinedButton>

            <Title>
              {t('configurator.priceTitle')}: <Price>{price} zł</Price>
            </Title>
          </CutSection>

          <MaterialSection>
            <Title>{t('configurator.meterial')}</Title>

            <ThumbnailsContainer>{renderedMaterials()}</ThumbnailsContainer>
          </MaterialSection>

          <MainSection>
            <ImagesContainer>
              <Image
                src={`/images/creator/${fleeces[activeFleece].name}.png`}
              />
              <Image src={materialImageUrl} className="is-relative" />
              <Image src={buttonsImageUrl} />
            </ImagesContainer>

            <ImagesContainer>
              {materialBack ? (
                <Image
                  src={
                    cuts[activeCut].oversize === 'true'
                      ? `${materials[activeMaterial].oversize_back_image_url}`
                      : `${materials[activeMaterial].back_image_url}`
                  }
                  className="is-relative"
                />
              ) : (
                <Image
                  className="is-relative"
                  src={
                    gender === 'female'
                      ? fleeces[activeFleece].back_female_image_url
                      : fleeces[activeFleece].back_image_url
                  }
                />
              )}

              {renderedAddons()}
            </ImagesContainer>
          </MainSection>

          <FleeceSection>
            <Title>{t('configurator.fleeceTitle')}</Title>

            <ThumbnailsContainer>{renderedFleeces}</ThumbnailsContainer>
          </FleeceSection>

          <BottomSection>
            {cuts[activeCut].oversize === 'false' && (
              <>
                <Title>{t('configurator.back')}</Title>

                <RadioContainer>
                  <StyledRadio
                    type="radio"
                    value="material"
                    id="material-option"
                    checked={materialBack}
                    onChange={(e) => setMaterialBack(e.target.checked)}
                  />
                  <Label htmlFor="material-option">
                    {t('configurator.meterial')}
                  </Label>

                  <StyledRadio
                    type="radio"
                    value="fleece"
                    id="fleece-option"
                    checked={!materialBack}
                    onChange={(e) => setMaterialBack(!e.target.checked)}
                  />
                  <Label htmlFor="fleece-option">
                    {t('configurator.fleeceTitle')}
                  </Label>
                </RadioContainer>
              </>
            )}

            <Title>{t('configurator.buttonsTitle')}</Title>

            <ButtonsContainer>{renderedButtons()}</ButtonsContainer>

            <OutlinedButton onClick={handleAddToCart}>
              {t('buttons.addToCart')}
            </OutlinedButton>
            <HelperText>{t('configurator.disclaimer')}</HelperText>
          </BottomSection>

          {sizesModalOpen && (
            <SizesModal
              handleModalClose={() => setSizeModalOpen(false)}
              type={gender as ProductType}
            />
          )}
        </>
      )}
    </ConfiguratorContainer>
  );
};

export default Configurator;
