import styled from 'styled-components';

export const Text = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
`;

export const Title = styled.h1`
  font-size: 4rem;
  font-weight: 400;
  margin: 1rem 0;
  color: ${(props) => props.theme.colors.text};
`;
